import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

import { getMessages } from '../dataProcessing'
import TextInput from '../TextInput/index'
import CleaveField from '../CleaveFields'
import AddressAutosuggest from '../AddressAutosuggest/SearchLocationInput'
import SelectDropdown from '../SelectDropdown/index'
import state from '../../assets/data/state.json'
import ReactRef from 'components/FormHighOrder/types/ReactRef'
import InputFieldContainer from '../Commons/InputFieldContainer'
import colors from '../color-css/color'
import HorizontalLine from '../HorizontalLine/index'
import { checkAgeUnder18 } from './helper'

import { useSelector } from 'react-redux'
import { RootState } from 'store/'

import {
    YOUR_NEXT_OF_KIN_DATA,
    KIN_FIRST_NAME, KIN_LAST_NAME, KIN_PHONE_NUMBER, KIN_RELATION, KIN_ADDRESS, KIN_UNIT_NUMBER, KIN_CITY, KIN_STATE_OR_TERITORY, KIN_POSTCODE,
    GUARDIAN_FIRST_NAME, GUARDIAN_LAST_NAME, GUARDIAN_PHONE_NUMBER, GUARDIAN_EMAIL
} from '../FormHighOrder/types/AboutYouField'
import { STATE_FROM_SUBURB } from '../FormHighOrder/types/YourWorkField'

export type Props = {
    newcastleUserAge: string
    onNextOfKinValidated?: (wasSuccessful: boolean) => void
    onNextOfKintData?: (data: any) => void
    onGuardianValidated?: (wasSuccessful: boolean) => void
    onGuardianData?: (data: any) => void
}

interface NewcastleUserWrapped {
    isNewcastleUser: boolean
}

const NewcastleUserWrapped = styled.div<NewcastleUserWrapped>`
    display: ${props => props.isNewcastleUser ? 'block' : 'none'}
`

const NextOfKin = styled.div`

`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  @media (max-width: 690px) {
    display: block;
    width: 100%;
    margin-bottom: 2.1875rem;
  }
`

interface Guardian {
    isNewcastleUserUnder18: boolean
}

const Guardian = styled.div<Guardian>`
    display: ${props => props.isNewcastleUserUnder18 ? 'block' : 'none'}
`

const FormHeader = styled.h2`
  font-family: museo-sans, sans-serif;
  font-size: 1.5rem;
  line-height: 2.1875rem;
  letter-spacing: -0.07px;
  font-weight: 700;
  color: ${colors.blue_17_percent};
  margin: 0;
`

const StateWrapper = styled.div`
  margin-top: 2.1875rem;
  @media (max-width: 1024px) {
    margin-top: 2.1875rem;
  }
`

const Hr = styled(HorizontalLine)`
  margin: 4.65625rem 0 ;
`

const NewcastleUserCondition: React.FC<Props> = ({
    newcastleUserAge,
    onNextOfKinValidated,
    onNextOfKintData
}) => {
    const [firstNameValidation, setFirstNameValidation] = useState(false)
    const [lastNameValidation, setLastNameValidation] = useState(false)
    const [phoneNumberValidation, setPhoneNumberValidation] = useState(false)
    const [phoneNumberValue, setPhoneNumberValue] = useState('')
    const [relationValidation, setRelationValidation] = useState(false)
    const [addressValidation, setAddressValidation] = useState(false)
    const [unitNumberValidation, setUnitNumberValidation] = useState(false)
    const [cityValidation, setCityValidation] = useState(false)
    const [cityValue, setCityValue] = useState('')
    const [stateOrTerritoryValidation, setStateOrTerritoryValidation] = useState(false)
    const [stateOrTerritoryValue, setStateOrTerritoryValue] = useState('')
    const [postcodeValidation, setPostcodeValidation] = useState(false)
    const [postcodeValue, setPostcodeValue] = useState('')

    const [guardianFirstNameValidation, setGuardianFirstNameValidation] = useState(false)
    const [guardianLastNameValidation, setGuardianLastNameValidation] = useState(false)
    const [guardianEmailValidation, setGuardianEmailValidation] = useState(false)
    const [guardianPhoneNumberValidation, setGuardianPhoneNumberValidation] = useState(false)
    const [guardianPhoneNumberValue, setGuardianPhoneNumberValue] = useState('')

    const [hasInputValueChange, setInputValueChange] = useState(false)

    const [isNewcastleUser, setIsNewcastleUser] = useState(false)
    const [isNewcastleUserUnder18, setIsNewcastleUserUnder18] = useState(false)


    const Refs: ReactRef = {
        [KIN_FIRST_NAME]: useRef<HTMLInputElement>(null),
        [KIN_LAST_NAME]: useRef<HTMLInputElement>(null),
        [KIN_PHONE_NUMBER]: useRef<HTMLInputElement>(null),
        [KIN_RELATION]: useRef<HTMLInputElement>(null),
        [KIN_ADDRESS]: useRef<HTMLInputElement>(null),
        [KIN_UNIT_NUMBER]: useRef<HTMLInputElement>(null),
        [KIN_CITY]: useRef<HTMLInputElement>(null),
        [KIN_STATE_OR_TERITORY]: useRef<HTMLInputElement>(null),
        [KIN_POSTCODE]: useRef<HTMLInputElement>(null),

        [GUARDIAN_FIRST_NAME]: useRef<HTMLInputElement>(null),
        [GUARDIAN_LAST_NAME]: useRef<HTMLInputElement>(null),
        [GUARDIAN_PHONE_NUMBER]: useRef<HTMLInputElement>(null),
        [GUARDIAN_EMAIL]: useRef<HTMLInputElement>(null)
    }

    const getPrefetchData =
        useSelector((state: RootState) => state.FormHighOrder.pageData[1].data[YOUR_NEXT_OF_KIN_DATA])

    const getStateFromForm1 = useSelector((state: RootState) =>
        state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

    useEffect(() => {
        if (getStateFromForm1 === 'Newcastle') {
            setIsNewcastleUser(true)
        } else setIsNewcastleUser(false)
    }, [getStateFromForm1])

    useEffect(() => {
        if (newcastleUserAge !== undefined && newcastleUserAge !== '') {
            let checkAge = checkAgeUnder18(newcastleUserAge)
            if (checkAge === true) setIsNewcastleUserUnder18(false)
            else setIsNewcastleUserUnder18(true)
        }
    }, [newcastleUserAge])

    useEffect(() => {
        if (isNewcastleUserUnder18 === true) {
            const isValidated = firstNameValidation && lastNameValidation && phoneNumberValidation &&
                relationValidation && addressValidation &&
                cityValidation && stateOrTerritoryValidation && postcodeValidation
                && guardianFirstNameValidation && guardianLastNameValidation
                && guardianEmailValidation && guardianPhoneNumberValidation

            onNextOfKinValidated ? onNextOfKinValidated(isValidated) : {}
        }
        else {
            const isValidated = firstNameValidation && lastNameValidation && phoneNumberValidation &&
                relationValidation && addressValidation &&
                cityValidation && stateOrTerritoryValidation && postcodeValidation

            onNextOfKinValidated ? onNextOfKinValidated(isValidated) : {}
        }

        onNextOfKintData ? onNextOfKintData({
            [KIN_FIRST_NAME]: {
                isValidated: firstNameValidation,
                value: Refs[KIN_FIRST_NAME].current?.value,
            },
            [KIN_LAST_NAME]: {
                isValidated: lastNameValidation,
                value: Refs[KIN_LAST_NAME].current?.value,
            },
            [KIN_PHONE_NUMBER]: {
                isValidated: phoneNumberValidation,
                value: phoneNumberValue,
            },
            [KIN_RELATION]: {
                isValidated: relationValidation,
                value: Refs[KIN_RELATION].current?.value,
            },
            [KIN_ADDRESS]: {
                isValidated: addressValidation,
                value: Refs[KIN_ADDRESS].current?.value,
            },
            [KIN_UNIT_NUMBER]: {
                isValidated: unitNumberValidation,
                value: Refs[KIN_UNIT_NUMBER].current?.value,
            },
            [KIN_CITY]: {
                isValidated: cityValidation,
                value: Refs[KIN_CITY].current?.value,
            },
            [KIN_STATE_OR_TERITORY]: {
                isValidated: stateOrTerritoryValidation,
                value: Refs[KIN_STATE_OR_TERITORY].current?.value,
            },
            [KIN_POSTCODE]: {
                isValidated: postcodeValidation,
                value: Refs[KIN_POSTCODE].current?.value,
            },
            [GUARDIAN_FIRST_NAME]: {
                isValidated: guardianFirstNameValidation,
                value: Refs[GUARDIAN_FIRST_NAME].current?.value,
            },
            [GUARDIAN_LAST_NAME]: {
                isValidated: guardianLastNameValidation,
                value: Refs[GUARDIAN_LAST_NAME].current?.value,
            },
            [GUARDIAN_EMAIL]: {
                isValidated: guardianEmailValidation,
                value: Refs[GUARDIAN_EMAIL].current?.value,
            },
            [GUARDIAN_PHONE_NUMBER]: {
                isValidated: guardianPhoneNumberValidation,
                value: guardianPhoneNumberValue,
            },
        }) : {}
    }, [firstNameValidation, lastNameValidation, phoneNumberValidation,
        relationValidation, addressValidation, unitNumberValidation,
        cityValidation, stateOrTerritoryValidation, postcodeValidation,
        guardianFirstNameValidation, guardianLastNameValidation, guardianEmailValidation,
        guardianPhoneNumberValidation, hasInputValueChange
    ])
    const inputFieldFactory = (children: React.ReactNode) => {
        return (
            <InputFieldContainer>
                {children}
            </InputFieldContainer>)
    }

    const checkFetchData = (nameParam: string) => {
        return getPrefetchData !== undefined && getPrefetchData[nameParam]?.value !== ""
    }

    const setValidation = (nameParam: string, wasAnInputSuccess: boolean) => {
        switch (nameParam) {
            case KIN_FIRST_NAME:
                setFirstNameValidation(wasAnInputSuccess)
                return;
            case KIN_LAST_NAME:
                setLastNameValidation(wasAnInputSuccess)
                return;
            case KIN_PHONE_NUMBER:
                setPhoneNumberValidation(wasAnInputSuccess)
                return;
            case KIN_RELATION:
                setRelationValidation(wasAnInputSuccess)
                return;
            case KIN_ADDRESS:
                setAddressValidation(wasAnInputSuccess)
                return;
            case KIN_UNIT_NUMBER:
                setUnitNumberValidation(wasAnInputSuccess)
                return;
            case KIN_CITY:
                setCityValidation(wasAnInputSuccess)
                return;
            case KIN_STATE_OR_TERITORY:
                setStateOrTerritoryValidation(wasAnInputSuccess)
                return;
            case KIN_POSTCODE:
                setPostcodeValidation(wasAnInputSuccess)
                return;
            case GUARDIAN_FIRST_NAME:
                setGuardianFirstNameValidation(wasAnInputSuccess)
                return;
            case GUARDIAN_LAST_NAME:
                setGuardianLastNameValidation(wasAnInputSuccess)
                return;
            case GUARDIAN_PHONE_NUMBER:
                setGuardianPhoneNumberValidation(wasAnInputSuccess)
                return;
            case GUARDIAN_EMAIL:
                setGuardianEmailValidation(wasAnInputSuccess)
                return;
        }
    }

    const createInputField = (
        label: string,
        placeHolderParam: string,
        nameParam: string,
        maxLength?: number,
        labelNotRequire?: string,
        getCityFromAddress?: string,
        getPostCodeFromAddress?: string,
        aRef?: React.RefObject<HTMLInputElement>,
        type?: string,
        hideValidate?: any,
        onType?: (val: any) => void): React.ReactNode => {
        return (
            <TextInput
                placeholder={placeHolderParam}
                name={nameParam}
                type={type || "text"}
                maxLength={maxLength}
                {...{ label, message: getMessages, labelNotRequire }}
                onInputSucessCallback={wasAnInputSuccess => {
                    setValidation(nameParam, wasAnInputSuccess)
                }}
                ref={aRef}
                inputFromProps={checkFetchData(nameParam) ?
                    getPrefetchData[nameParam]?.value : undefined}
                isValidated={getPrefetchData[nameParam]?.isValidated}
                getCityFromAddress={getCityFromAddress}
                getPostCodeFromAddress={getPostCodeFromAddress}
                hasValueChange={(isChange) => {
                    setInputValueChange(isChange)
                }}
                hideValidate={hideValidate}
            />
        )
    }
    return (
        <NewcastleUserWrapped isNewcastleUser={isNewcastleUser}>
            <Hr />
            <NextOfKin>
                <FormHeader>Your next of kin</FormHeader>
                <Wrapper>
                    {inputFieldFactory(
                        createInputField(
                            "Your next of kin’s first name",
                            '',
                            KIN_FIRST_NAME,
                            undefined,
                            undefined, undefined, undefined,
                            Refs[KIN_FIRST_NAME])
                    )}

                    {inputFieldFactory(
                        createInputField(
                            "Your next of kin’s last name",
                            '',
                            KIN_LAST_NAME,
                            undefined,
                            undefined, undefined, undefined,
                            Refs[KIN_LAST_NAME])
                    )}

                    {inputFieldFactory(
                        <CleaveField
                            name={KIN_PHONE_NUMBER}
                            placeholder=''
                            label='Your next of kin’s phone number'
                            option={{
                                phone: true,
                                phoneRegionCode: 'AU',
                                numericOnly: true
                            }}
                            onInputSuccess={(wasAnInputSuccess: boolean) => {
                                setValidation(KIN_PHONE_NUMBER, wasAnInputSuccess)
                            }}
                            handleData={(value, validated) => {
                                setPhoneNumberValue(value)
                            }}
                            inputFromProps={checkFetchData(KIN_PHONE_NUMBER) ?
                                getPrefetchData[KIN_PHONE_NUMBER]?.value : undefined}
                            isValidated={getPrefetchData[KIN_PHONE_NUMBER]?.isValidated}
                        />
                    )}

                    {inputFieldFactory(
                        createInputField(
                            "Your next of kin’s relation to you",
                            '',
                            KIN_RELATION,
                            undefined,
                            undefined, undefined, undefined,
                            Refs[KIN_RELATION])
                    )}
                </Wrapper>
                <AddressAutosuggest
                    label='Your next of kin’s home address'
                    placeholder=''
                    ref={Refs[KIN_ADDRESS]}
                    onSelectedItemProp={(wasAnInputSuccess) => {
                        setValidation(KIN_ADDRESS, wasAnInputSuccess)
                    }}
                    inputFromProps={checkFetchData(KIN_ADDRESS) ?
                        getPrefetchData[KIN_ADDRESS]?.value : undefined}
                    isValidated={getPrefetchData[KIN_ADDRESS]?.isValidated}
                    handleAddressInfo={(city, state, postcode) => {
                        setCityValue(city)
                        setStateOrTerritoryValue(state)
                        setPostcodeValue(postcode)
                    }}
                />
                <Wrapper>
                    {inputFieldFactory(
                        createInputField(
                            "",
                            '',
                            KIN_UNIT_NUMBER,
                            undefined,
                            "Unit number (if applicable)", undefined, undefined,
                            Refs[KIN_UNIT_NUMBER])
                    )}

                    {inputFieldFactory(
                        createInputField(
                            "City",
                            '',
                            KIN_CITY,
                            undefined,
                            undefined, cityValue, undefined,
                            Refs[KIN_CITY])
                    )}

                    <StateWrapper>
                        <SelectDropdown
                            label="State / territory"
                            name={KIN_STATE_OR_TERITORY}
                            contentDefault="Select a state"
                            data={state}
                            onSelectedItemProp={wasSelected => {
                                setStateOrTerritoryValidation(wasSelected)
                            }}
                            ref={Refs[KIN_STATE_OR_TERITORY]}
                            inputFromProps={checkFetchData(KIN_STATE_OR_TERITORY) ?
                                getPrefetchData[KIN_STATE_OR_TERITORY]?.value : undefined}
                            isValidated={getPrefetchData[KIN_STATE_OR_TERITORY]?.isValidated}
                            getStateFromAddress={stateOrTerritoryValue}
                        />
                    </StateWrapper>

                    {inputFieldFactory(
                        createInputField(
                            "Postcode",
                            '',
                            KIN_POSTCODE,
                            4,
                            undefined, undefined, postcodeValue,
                            Refs[KIN_POSTCODE],
                            'tel')
                    )}
                </Wrapper>
            </NextOfKin>
            <Guardian isNewcastleUserUnder18={isNewcastleUserUnder18}>
                <Hr />
                <FormHeader>Parent or Guardian</FormHeader>
                <Wrapper>
                    {inputFieldFactory(
                        createInputField(
                            "Parent/guardian's first name",
                            '',
                            GUARDIAN_FIRST_NAME,
                            undefined,
                            undefined, undefined, undefined,
                            Refs[GUARDIAN_FIRST_NAME])
                    )}

                    {inputFieldFactory(
                        createInputField(
                            "Parent/guardian's last name",
                            '',
                            GUARDIAN_LAST_NAME,
                            undefined,
                            undefined, undefined, undefined,
                            Refs[GUARDIAN_LAST_NAME])
                    )}

                    {inputFieldFactory(
                        createInputField(
                            "Parent/guardian's email address",
                            '',
                            GUARDIAN_EMAIL,
                            undefined,
                            undefined, undefined, undefined,
                            Refs[GUARDIAN_EMAIL], 'email')
                    )}

                    {inputFieldFactory(
                        <CleaveField
                            name={GUARDIAN_PHONE_NUMBER}
                            placeholder=''
                            label="Parent/guardian's phone number"
                            option={{
                                phone: true,
                                phoneRegionCode: 'AU',
                                numericOnly: true
                            }}
                            onInputSuccess={(wasAnInputSuccess: boolean) => {
                                setValidation(GUARDIAN_PHONE_NUMBER, wasAnInputSuccess)
                            }}
                            handleData={(value, validated) => {
                                setGuardianPhoneNumberValue(value)
                            }}
                            inputFromProps={checkFetchData(GUARDIAN_PHONE_NUMBER) ?
                                getPrefetchData[GUARDIAN_PHONE_NUMBER]?.value : undefined}
                            isValidated={getPrefetchData[GUARDIAN_PHONE_NUMBER]?.isValidated}
                        />
                    )}
                </Wrapper>
            </Guardian>
        </NewcastleUserWrapped>
    );
}

export default NewcastleUserCondition