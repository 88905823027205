import React from "react"
import styled from "styled-components"

const Container = styled.div`padding-top: 2.5rem`

export const ContactOption: React.FC = () => {
    return (
        <Container>
            <div>If you'd like to pay via Credit Card or Direct Debit, the SDA will give you a call to verify your payment details.</div>
        </Container>
    )
}

