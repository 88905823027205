import { CITY } from 'components/FormHighOrder/types/AboutYouField'
import {
  BANKACCOUNT_PAYMENT_ACCOUNT_NAME,
  BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER,
  BANKACCOUNT_PAYMENT_BSB,
  BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION,
  BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION,
  CREDITCARD_PAYMENT_DATE_OF_DEDUCTION,
  CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION,
  CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME,
  CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME,
  CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER,
  CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE,
  CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV,
  BILLING_FIRST_NAME,
  BILLING_LAST_NAME,
  BILLING_ADDRESS,
  BILLING_UNIT_NUMBER,
  BILLING_CITY,
  BILLING_STATE_OR_TERITORY,
  BILLING_POSTCODE,
  PAY_DEDUCTION_PAYMENT_NUMBER

} from '../../FormHighOrder/types/YourContributionField'

export const NORMAL = 1
export const EMAIL = 2
export const PHONE_NUMBER = 3
export const POST_CODE = 4
export const CARD_NUMBER = 5
export const CARD_EXPIRY_DATE = 6
export const CARD_CVC_CCC = 7
export const CARD_BSB = 8
export const DATE_ON_DESKTOP = 9
export const CARD_ACCOUNT_NUMBER = 10

export const onSubmitStrategy = (name: string) => {
  if (name === 'firstName' ||
    name === 'kinFirstName' ||
    name === 'guardianFirstName' ||
    name === CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME ||
    name === BILLING_FIRST_NAME ||
    name === 'lastName' ||
    name === 'kinLastName' ||
    name === 'guardianLastName' ||
    name === CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME ||
    name === BILLING_LAST_NAME ||
    name === 'kinRelation' ||
    name === 'address' ||
    name === 'kinAddress' ||
    name === 'workSuburb' ||
    name === 'employer' ||
    name === 'dateOnMobile' ||
    name === BANKACCOUNT_PAYMENT_ACCOUNT_NAME ||
    name === CITY ||
    name === 'kinCity' ||
    name === 'kinStateOrTerritory' ||
    name === BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION ||
    name === BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION ||
    name === CREDITCARD_PAYMENT_DATE_OF_DEDUCTION ||
    name === CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION ||
    name === BILLING_CITY ||
    name === BILLING_STATE_OR_TERITORY ||
    name === BILLING_POSTCODE ||
    name === BILLING_ADDRESS ||
    name === PAY_DEDUCTION_PAYMENT_NUMBER ||
    name === 'unitNumber' ||
    name === 'kinUnitNumber' ||
    name === BILLING_UNIT_NUMBER) return NORMAL

  else if (name === 'email' || name === 'guardianEmail') return EMAIL

  else if (
    name === 'phoneNumber' ||
    name === 'kinPhoneNumber' ||
    name === 'guardianPhoneNumber') return PHONE_NUMBER

  else if (
    name === 'postcode'
    || name === 'kinPostcode'
    || name === BILLING_POSTCODE) return POST_CODE

  else if (
    name === 'creditCard' ||
    name === CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER) return CARD_NUMBER

  else if (
    name === 'expiryDate' ||
    name === CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE) return CARD_EXPIRY_DATE

  else if (
    name === 'cvc' ||
    name === CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV) return CARD_CVC_CCC

  else if (name === BANKACCOUNT_PAYMENT_BSB) return CARD_BSB
  else if (name === 'dateOnDesktop') return DATE_ON_DESKTOP
  else if (name === BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER) return CARD_ACCOUNT_NUMBER

}
