import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { writeValidateData } from './features/index'
import { ActionPageDataInput } from './types/ActionPageDataInput'
export type Props = {
  children?: React.ReactNode
}

const FormHighOrder: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch()

  return (<div style={{ display: `block` }}>
    {children}
  </div>)
}

export default FormHighOrder