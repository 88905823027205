import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import color from '../color-css/color'
import { Spinner } from './Spinner'
import { navigate } from 'gatsby-link'
import { Promise } from 'es6-promise'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { EMAIL, STATE_FROM_SUBURB } from '../FormHighOrder/types/YourWorkField'
import { checkDuplicates, JoinFormSubmissionInlineValidationTarget, PromiseResult } from '../../utils/submitData'
import { useDispatch } from 'react-redux'
import {
  formSubmitted
} from '../FormHighOrder/features/index'

export enum ButtonStates {
  disabled = 'disabled',
  enabled = 'enabled',
  progressing = 'progressing'
}

export type Props = {
  title: string
  buttonStates: ButtonStates
  stepNumber: number
  onClickCallbackProp: () => void
  onClickExecFunc?: (stepNumber: number) => Promise<PromiseResult>
  onValidationError?: (field: string) => void;
  widthSize?: string
}

/**
 * enabled:
 * disabled:
 * progressing:
 */
const handleButtonStates = (getCurrentState: () => ButtonStates) => {

  switch (getCurrentState()) {
    case ButtonStates.enabled:
    default:
      return 'background: #FF6820;'
    case ButtonStates.progressing:
      return 'background: #FF6820;\n'
        + 'cursor: not-allowed;\n'
        + 'pointer-events: none;'
    case ButtonStates.disabled:
      return 'background: #C9C9C9;\n'
        + 'cursor: not-allowed;\n'
        + 'pointer-events: none;'
  }
}

interface ProgressButton {
  currentState: () => ButtonStates
  onFocusButtonStates?: ButtonStates
  tabIndex?: any
}
export const StyledProgressButton = styled.button<ProgressButton>`
  outline: none;
  border: none;
  height: 3.125rem;
  width: 100%;
  padding: 0;
  display: block;
  text-align: center;
  line-height: 3.125rem;
  background: ${color.grey} 0% 0% no-repeat padding-box;
  border-radius: 6.25rem;
  opacity: 1;
  font-family: museo-sans, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: .8px;
  color: ${color.white};
  text-decoration: none;
  ${({ currentState }) => handleButtonStates(currentState)};
  cursor: pointer;
  &:focus {
    background: #f2560c;
  }
  @media (max-width: 768px) {
    font-size: 0.875rem;
    height: 2.8125rem;
    line-height: 2.8125rem;
  }
`

interface HoverProgressButton {
  hoverButtonStates?: ButtonStates,
  widthSize?: string,
  title: string
}

const HoverProgressButton = styled.div<HoverProgressButton>`
  width: ${props => props.hoverButtonStates === ButtonStates.progressing ? '13.875rem' : props.widthSize};
  border-radius: 6.25rem;
  position: relative;
  &:hover ${StyledProgressButton} {
    background: ${props =>
    props.hoverButtonStates === ButtonStates.enabled || props.hoverButtonStates === ButtonStates.progressing
      ? '#f2560c' : '#C9C9C9'} ;
  }
  @media (max-width: 768px) {
    width: ${props => props.title === 'Finish & submit' ? '11.875rem' : '10.8125rem'};
  }
`

interface ButtonSpinner {
  isShow: boolean
}
const ButtonSpinner = styled(Spinner) <ButtonSpinner>`
  display: ${props => props.isShow === true ? 'block' : 'none'};
  position: absolute;
  right: 0.85rem;
  margin-top: -2.1875rem;
`

const ProcessingTitle = styled.p`
  margin-top: 0.875rem;
  font: italic 1rem museo-sans, sans-serif;
  line-height: 1.75rem;
  letter-spacing: -0.05px;
  color: ${color.grey_29_percent};
  @media (max-width: 768px) {
    font: italic 1rem museo-sans, sans-serif;
  }
`

const ProgressButton: React.FC<Props> = ({
  title,
  buttonStates,
  stepNumber,
  onClickCallbackProp,
  onClickExecFunc,
  onValidationError,
  widthSize,
}) => {
  const handleRedirectExistingUser = (duplicatesMap) => {
    const branch = getStateFromForm1

    navigate(`/existing-user/`, {
      state: {
        branch,
      }
    })
  }

  const handleExistingUserParseErrors = (errors) => {
    if (typeof errors === "string") {
      if (errors.includes('A member was found with this')) {
        checkDuplicates().then(handleRedirectExistingUser)
      }
    }
    else if (errors?.find((error: any) => {
      if (typeof error === 'string') {
        return error?.includes('A member was found with this')
      } else if (error?.message) {
        return error?.message?.includes('A member was found with this')
      }
      return false
    })) {
      checkDuplicates().then(handleRedirectExistingUser)
    } else {
      navigate(`/error/`, {
        state: {
          errors: errors
        }
      })
    }
  }

  const [buttonState, updateButtonState] = useState(buttonStates)
  const [awaitingPromise, updateAwaitingPromise] = useState(false)
  const [once, setOnce] = useState(-1)
  const dispatch = useDispatch()

  const getStateFromForm1 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

  useEffect(() => {
    updateButtonState(buttonStates)
  }, [buttonStates])

  let canProceed = false

  return (
    <>
      <HoverProgressButton
        hoverButtonStates={buttonStates}
        widthSize={widthSize}
        title={title}>
        <StyledProgressButton
          tabIndex={buttonState === 'enabled' ? "0" : "-1"}
          currentState={() => {
            if (awaitingPromise) {
              return ButtonStates.progressing
            } else {
              return buttonState
            }
          }}
          onClick={event => {
            event.preventDefault()
            updateAwaitingPromise(true)
            if (typeof window !== 'undefined' &&
              onClickExecFunc !== undefined) {
              onClickExecFunc(stepNumber).then((resultObj) => {
                canProceed = resultObj?.canProceed
                if (resultObj?.isComplete && canProceed) {
                  dispatch(formSubmitted())
                  navigate(`/join-success/`, {
                    state: {
                      branch: getStateFromForm1
                    }
                  })
                }

                if(resultObj?.inlineErrors?.find((inlineError) => inlineError.type === JoinFormSubmissionInlineValidationTarget.INVALID_EMAIL) && onValidationError) {
                  onValidationError(EMAIL);
                }
              })
              .catch(handleExistingUserParseErrors)
              .finally(() => {
                if (canProceed) {
                  updateAwaitingPromise(false)
                  onClickCallbackProp()
                } else {
                  updateAwaitingPromise(false)
                  updateButtonState(ButtonStates.enabled)
                }
              })
            } else {
              if (buttonState === 'enabled') {
                onClickCallbackProp()
                updateAwaitingPromise(false)
              }
            }
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              updateAwaitingPromise(true)
              if (typeof window !== 'undefined' &&
                onClickExecFunc !== undefined) {
                onClickExecFunc(stepNumber).then((resultObj) => {
                  canProceed = resultObj?.canProceed
                  if (resultObj?.isComplete && canProceed) {
                    navigate(`/join-success/`, {
                      state: {
                        branch: getStateFromForm1
                      }
                    })
                  }
                })
                .catch(handleExistingUserParseErrors)
                .finally(() => {
                  if (canProceed) {
                    updateAwaitingPromise(false)
                    onClickCallbackProp()
                  } else {
                    updateAwaitingPromise(false)
                    updateButtonState(ButtonStates.enabled)
                  }
                })
              } else {
                if (buttonState === 'enabled') {
                  onClickCallbackProp()
                  updateAwaitingPromise(false)
                }
              }
            }
          }}
        >
          {title}
        </StyledProgressButton>
        {
          buttonStates === 'progressing' || awaitingPromise
            ? (
              <ButtonSpinner
                isShow={true}
                className="" />
            )
            : (
              <ButtonSpinner
                isShow={false}
                className="" />
            )
        }
      </HoverProgressButton>
      {
        buttonStates === 'progressing' || awaitingPromise && (
          <ProcessingTitle>
            Don't close your browser
            <span style={{ color: 'orange' }}>*</span>
          </ProcessingTitle>
        )}
    </>
  )
}

export default ProgressButton
