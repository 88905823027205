import React from 'react'
import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { rem } from '../../styles/Mixins'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck, faTrashAlt, faPencil } from '@fortawesome/pro-regular-svg-icons'
import * as htmlToImage from 'html-to-image'
import SignatureCanvas from 'react-signature-canvas'
import ButtonItem from '../RadioButtonGroup/ItemButton'
import ValidationIndicator from "../ValidationIndicator";
import { InputField, HoveringWrapper } from '../TextInput/styles/InputField'
import Label from '../Label'
import LabelIcon from '../Label/LabelIcon'
import color from '../color-css/color'
import img from '../../assets/images/hand-point-up-1.png'

const PopUpContainer = styled.div`
  border-radius: ${rem(10)};
  background: #F3F3F3;
  box-shadow: 0 ${rem(20)} ${rem(50)} #012F571A;
  padding: ${rem(30)};
  width: calc(100% - ${rem(100)});
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: 350ms;

  body.show-modal-content & {
    opacity: 1;
    z-index: 111;
  }

  ${media.md`
    width: calc(100% - ${rem(30)});
    max-width: ${rem(1075)};
    padding: 0;
  `}
`

const ModalWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  pointer-events: none;
  * {
    pointer-events: all;
  }
`

const H3 = styled.h3`
  color: #012F57;
  font-weight: 900;
  font-size: ${rem(20)};
  margin-bottom: ${rem(30)};

  ${media.md`
    font-size: ${rem(26)};
    margin-bottom: ${rem(40)};
  `}
`

const GeneratedSign = styled.div`
  #generated-sign-png {
    font-family: MrsSaintDelafield, sans-serif;
    font-size: ${rem(60)};
    background: #FFFFFF;
    border-radius: ${rem(10)};
    padding: ${rem(20)};
    height: ${rem(90)};
    display: flex;
    justify-content: left;
    align-items: center;
    color: #012F57;

    ${media.md`
      background: #F3F3F3;
    `}
  }
`

const GeneratedDrawCanvas = styled.div`
  display: flex;

  canvas {
    background: #FFFFFF;
    border-radius: ${rem(10)};
    width: 100%;
    height: ${rem(130)};

    ${media.md`
      background: #F3F3F3;
    `}
  }
`

const ButtonClose = styled.button`
  color: #FF6820;
  font-size: ${rem(26)};
  position: absolute;
  top: ${rem(30)};
  right: ${rem(20)};
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  display: none;

  ${media.md`
    right: ${rem(30)};
    display: block;
  `}

  ${media.lg`
    right: ${rem(40)};
    top: ${rem(55)};
  `}

  &:hover {
    color: #F2560B;
  }
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(35)};
  position: relative;

  ${(props) => props.disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `};

  &.field-signature-pad {
    margin-bottom: ${rem(40)};

    ${media.md`
      margin-bottom: ${rem(30)};
    `}

    img {
      margin-bottom: 0;
    }
  }
`

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: ${rem(13)};

    &:last-child {
      margin-bottom: 0;
    }

    > a {
      border: solid 1px transparent;
      width: 100%;
      height: 2.625rem;

      ${media.md`
        width: 11.125rem;
        height: 3.375rem;
      `}
    }
  }

  ${media.md`
    flex-direction: row;
    justify-content: flex-start;

    > div {
      margin-bottom: 0;

      &:last-child {
        > a {
          margin-right: 0;
        }
      }

      > a {
        width: ${rem(140)};
        margin-right: ${rem(20)};
      }
    }
  `}

  ${media.lg`
    justify-content: flex-start;
  `}
`

const FormGroupLabel = styled.label`
  font-weight: 500;
  font-size: ${rem(17)};
  line-height: ${rem(24)};
  color: #012F57;
  margin-bottom: ${rem(10)};

  ${media.md`
    width: 100%;
  `}

  ${media.lg`
    font-size: ${rem(18)};
    margin-bottom: ${rem(15)};
  `}
`

const FormGroupInput = styled.input`
  border-radius: 10px;
  background: #ffffff;
  height: ${rem(44)};
  border: none;
  margin-bottom: 10px;
  color: #757575;
  outline: none;
  padding: ${rem(10)} ${rem(15)};
  font-size: ${rem(15)};
  font-weight: 500;

  &.complete {
    background: #E2F1F8;
    color: #012F57;
  }

  ${media.lg`
    font-size: ${rem(16)};
  `}
`

const FormGroupLabelAsterik = styled.span`
  font-weight: 500;
  font-size: ${rem(17)};
  line-height: ${rem(24)};
  color: #00AFD9;
`

const PreviewOptions = styled.div`
  position: absolute;
  right: 0;

  ${media.lg`
    top: ${rem(50)};
    display: flex;

    &.hide {
      #btn-clear {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  `}
`

const ButtonClean = styled.button`
  font-size: ${rem(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  background: transparent;
  outline: none;
  border: none;

  &:hover {
    color: #F2560B;

    ${media.lg`
      color: #012F57;

      span {
        opacity: 1;
        color: #012F57;
        font-size: ${rem(10)};
        line-height: ${rem(26)};
      }
    `}
  }

  svg {
    font-size: ${rem(16)};
    margin-right: ${rem(10)};
    padding: 0;
  }

  ${media.lg`
    svg {
      margin-right: ${rem(20)};
      color: #012F57;
    }

    span {
      transition: all 0.15s ease-in-out;
      position: absolute;
      left: 22px;
      top: 13px;
      opacity: 0;
      font-size: ${rem(10)};
      line-height: ${rem(26)};
    }
  `}

  ${media.xs`
    span {
      display: none;
    }
  `}
`

const ButtonCancel = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: ${rem(14)};
  line-height: ${rem(30)};
  font-weight: 500;
  text-decoration: underline;
  color: #FF6820;
  margin-left: ${rem(30)};
  background: transparent;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:hover {
    color: #F2560B;
  }

  ${media.lg`
    text-decoration: none;
    font-size: ${rem(16)};
  `}
`

const ButtonPencil = styled.div`
  display: none;
  color: #F2560B;
  font-size: ${rem(16)};
  margin-right: ${rem(10)};

  ${media.lg`
    display: block;
  `}

  ${media.xs`
    span {
      display: none;
    }
  `}
`

const FormContainer = styled.div`
  margin-bottom: 0;
  max-height: 90vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 2px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.md`
    display: flex;
  `}
`

const LeftPane = styled.div`
  ${media.md`
    width: 50%;
    padding: ${rem(30)};
  `}

  ${media.lg`
    width: 50%;
    padding: ${rem(55)} ${rem(40)} ${rem(30)};
    min-height: calc(${rem(430)} - ${rem(85)});
  `}
`

const RightPane = styled.div`
  ${media.md`
    width: 50%;
    padding: ${rem(30)};
    padding-top: ${rem(92)};
    background: #ffffff;
    border-top-right-radius: ${rem(10)};
    border-bottom-right-radius: ${rem(10)};
  `}

  ${media.lg`
    width: 50%;
    padding: ${rem(117)} ${rem(40)} ${rem(30)};
    min-height: calc(${rem(430)} - ${rem(147)});
  `}
`

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-start;
`

const StyledInputField = styled(InputField)`
  width: 100%;
  border: solid 1px transparent;
  color: #012F57;

  && {
    background-color: ${props => props.inputSuccessAsBackgroundColor === true ? color.blue : color.white};
  }
`

const StyledHoveringWrapper = styled(HoveringWrapper)`
  @media screen and (max-width: 1024px) {
    margin-top: ${rem(10)};
  }
`

const ReStyledProgressButton = styled.button`
  outline: none;
  border: none;
  height: ${rem(45)};
  width: ${rem(173)};
  padding: 0;
  display: block;
  text-align: center;
  line-height: 3.125rem;
  background: ${props => props.disabled ? '#C9C9C9' : '#FF6820'} 0% 0% no-repeat padding-box;
  border-radius: 6.25rem;
  opacity: 1;
  font-family: museo-sans, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: .8px;
  color: ${color.white};
  text-decoration: none;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
  pointer-events: ${props => props.disabled ? 'none' : 'unset'};

  &:focus,
  &:hover {
    background: ${props => props.disabled ? '#C9C9C9' : '#f2560c'};
  }

  ${media.md`
    font-size: ${rem(16)};
    height: 2.8125rem;
    line-height: 2.8125rem;
  `}

  ${media.lg`
    width: ${rem(222)};
    height: ${rem(50)};
  `}
`

const PIXEL_RATIO = (function () {
  const ctx = typeof document !== 'undefined' ? document.createElement('canvas').getContext('2d') : {},
    dpr = typeof window !== 'undefined' ? window.devicePixelRatio : 1,
    bsr = ctx.webkitBackingStorePixelRatio ||
      ctx.mozBackingStorePixelRatio ||
      ctx.msBackingStorePixelRatio ||
      ctx.oBackingStorePixelRatio ||
      ctx.backingStorePixelRatio || 1

  return dpr / bsr
})()

const createHiDPICanvas = function (width, height, ratio) {
  if (!ratio) { ratio = PIXEL_RATIO }
  if (typeof document === 'undefined') return
  const canvas = document.createElement('canvas')
  canvas.width = width * ratio
  canvas.height = height * ratio
  canvas.style.width = `${width}px`
  canvas.style.height = `${height}px`
  canvas.getContext('2d').scale(ratio, ratio)
  return canvas
}

function ModalContainer(props) {
  return (
    <ModalWrapper>
      <PopUpContainer className="form-modal">
        {props.children}
      </PopUpContainer>
    </ModalWrapper>
  )
}

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      signMethod: null,
      signName: null,
      trimSignPadURL: null,
      signPadComplete: false,
      showContentOnLoad: false,
    }
  }

  // Defining signature pad object
  signPadCanvas = {}

  // Set sign method state
  setSignMethod(state) {
    if (this.state.signPadComplete) {
      this.setState({
        signMethod: state,
        trimSignPadURL: this.state.trimSignPadURL ? this.state.trimSignPadURL : this.signPadCanvas,
      }, () => {
        this.updateSignURL()
      })
    } else {
      this.setState({
        signMethod: state,
      })
    }
  }

  setSignPadComplete(state) {
    this.setState({
      signPadComplete: state,
      trimSignPadURL: this.signPadCanvas,
    })
  }

  // Set sign name state
  setSignName(state) {
    this.setState({
      signName: state,
    })
  }

  // Clear signature pad
  clear = () => {
    this.setState(
      {
        trimSignPadURL: null,
        signPadComplete: false,
      }, () => {
        this.signPadCanvas.clear()
      }
    )
  }

  // Form submit
  submitForm = () => {
    if (this.state.signMethod === 'draw') {
      this.setState(
        {
          trimSignPadURL: this.signPadCanvas.getTrimmedCanvas().toDataURL('image/png', 0.1)
        }, () => {
          this.updateSignURL()
          this.closePopup()
        }
      )
    } else {
      this.generateSignature(470, 116)
    }
  }

  generateSignature(width, height) {
    const canvas = createHiDPICanvas(width, height)
    canvas.setAttribute('style', 'font-smooth: never')
    const ctx = canvas.getContext('2d')

    const image = ctx.getImageData(0, 0, width, height);
    // get the image data values
    const imageData = image.data
    // set every fourth value to 50
    for (let i = 3; i < imageData.length; i += 4) {
      imageData[i] = 0
    }
    ctx.putImageData(image, 0, 0);
    // FIXME: REM units don't seem to render correctly on IOS
    ctx.font = `60px MrsSaintDelafield`
    ctx.fillStyle = '#012F57'
    ctx.fillText(this.state.signName, 8, 0.55 * height)

    this.setState({
      trimSignPadURL: canvas.toDataURL('image/png', 0.1),
    }, () => {
      this.updateSignURL()
      this.closePopup()
    })
  }

  updateSignURL() {
    this.props.getSignURL(
      this.state.trimSignPadURL,
      this.state.signName,
    )
  }

  closePopup() {
    this.props.onClose()
  }

  componentDidUpdate() {
    if (this.state.trimSignPadURL && this.signPadCanvas && typeof this.state.trimSignPadURL === 'object') {
      this.signPadCanvas.fromData(this.state.trimSignPadURL.toData())
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showContentOnLoad: true,
      })

      document.body.classList.add('show-modal-content')
      document.documentElement.classList.add('show-modal-content')
    }, 100)
  }

  componentWillUnmount() {
    document.body.classList.remove('show-modal-content')
    document.documentElement.classList.remove('show-modal-content')
  }

  render() {
    const { onClose, isOpen } = this.props
    const { signMethod, signName, signPadComplete, showContentOnLoad } = this.state

    return (
      <ModalContainer isOpen={isOpen}>
        <ButtonClose type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </ButtonClose>

        <FormContainer>
          <LeftPane>
            <H3>Electronic signature</H3>
            <FormGroup className="form-field field-method">
              <FormGroupLabel>Select method <FormGroupLabelAsterik className="required">*</FormGroupLabelAsterik></FormGroupLabel>
              <ButtonGroupContainer>
                <ButtonItem signatureGenerator={true} id="btn-draw" active={signMethod && signMethod === 'draw'} type="button" onPress={() => this.setSignMethod('draw')} title="Draw" />
                <ButtonItem signatureGenerator={true} id="btn-generate" type="button" active={signMethod && signMethod === 'generate'} onPress={() => this.setSignMethod('generate')} title="Generate" />
              </ButtonGroupContainer>
              <ValidationIndicator visibility={signMethod && signMethod === 'draw' || signMethod === 'generate'} isCorrect={true} />
            </FormGroup>
            <FormGroup disabled={signMethod ? false : true} className="form-field field-name">
              <Label>{"Confirm your name for signature"}
                <LabelIcon iconStates={true}>{'  '}*</LabelIcon>
              </Label>
              <StyledHoveringWrapper
                hoverIcon={img}>
                <StyledInputField
                  placeholder="Your name or intials here"
                  maxLength="25"
                  onChange={(e) => this.setSignName(e.target.value)}
                  name={"signName"}
                  type="text"
                  inputSuccessAsBackgroundColor={signName && signName.length >= 2}
                />
              </StyledHoveringWrapper>
              {signName && signName.length >= 2 ? (
                <ValidationIndicator visibility={true} isCorrect={true} />
              ) : (
                signMethod && (<ValidationIndicator visibility={true} isCorrect={false} />)
              )}
              {/* <ValidationIndicator visibility={signName && signName.length >= 2} isCorrect={true}/> */}
            </FormGroup>
          </LeftPane>

          <RightPane>
            <FormGroup disabled={(signName && signName.length >= 2) ? false : true} className="form-field field-signature-pad">
              {signMethod === 'draw' ? (
                <>
                  <FormGroupLabel>Draw your signature <FormGroupLabelAsterik className="required">*</FormGroupLabelAsterik></FormGroupLabel>
                  <PreviewOptions className={`${signPadComplete ? 'show' : 'hide'}`}>
                    <ButtonPencil><FontAwesomeIcon icon={faPencil} /></ButtonPencil>
                    <ButtonClean id="btn-clear" type="button" onClick={this.clear}><FontAwesomeIcon icon={faTrashAlt} /> <span>Delete</span></ButtonClean>
                  </PreviewOptions>
                  <GeneratedDrawCanvas>
                    <SignatureCanvas
                      ref={(ref) => { this.signPadCanvas = ref }}
                      onEnd={() => this.setSignPadComplete(true)}
                      penColor="#012F57"
                      clearOnResize={false}
                    />
                  </GeneratedDrawCanvas>
                </>
              ) : (
                <>
                  <FormGroupLabel>Signature preview <FormGroupLabelAsterik className="required">*</FormGroupLabelAsterik></FormGroupLabel>
                  <GeneratedSign><div id="generated-sign-png">{(signName && signName.length >= 2) ? signName : null}</div></GeneratedSign>
                </>
              )}
            </FormGroup>
            <FormFooter>
              <ReStyledProgressButton
                onClick={this.submitForm}
                disabled={
                  ((signMethod && signMethod === 'draw' && signPadComplete && signName && signName.length >= 2) ||
                    (signMethod && signMethod === 'generate' && signName && signName.length >= 2))
                    ? false : true
                }
              >
                Adopt &amp; sign
              </ReStyledProgressButton>
              <ButtonCancel id="btn-cancel" type="button" onClick={onClose}>Cancel</ButtonCancel>
            </FormFooter>
          </RightPane>
        </FormContainer>
      </ModalContainer>
    )
  }
}

export default Form
