import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from 'react-device-detect'
import color from '../color-css/color'
interface TooltipIconContainerProps {
    expandVisibility?: any
}
const TooltipIconContainer = styled.div<TooltipIconContainerProps>`
    width: 1.23rem;
    height: 1.23rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:${props => !props.expandVisibility ? color.grey_darker : color.tooltip_icon};
    transition: background 0.5s ease-in-out;
    border-radius: 50%;
    margin-left: 0.125rem;
    outline: none;
    &:focus {
        background-color: ${color.tooltip_icon};
        transition: background 0.5s ease-in-out;
    }
`

const StyledIcon = styled(FontAwesomeIcon)`
    color: ${color.white};
    width: 0.75rem !important;
    height: 0.625rem;
`
interface TooltipExpandableProps {
    isVisible?: boolean
    isCVC?: boolean
}

const TooltipExpandable = styled.div<TooltipExpandableProps>`
    opacity: ${props => props.isVisible ? '1' : '0'};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    transform: ${props => props.isVisible ? 'translateX(0)' : 'translateX(-0.625rem);'};
    min-width: 13.625rem;
    position: relative;
    -moz-border-radius: 0.625rem;
    -webkit-border-radius: 0.625rem;
    border-radius: 0.625rem;
    background: ${color.white} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3.125rem rgba(1,47,87,0.1);
    top: 1.25rem;
    right: 1.25rem;
    z-index: 1;
    transition: .3s;
    &:before {
        content: "";
        position: absolute;
        left: 1.25rem;
        top: -2rem;
        width: 0;
        height: 0;
        border-top: 1.375rem solid transparent;
        border-right: 0.75rem solid transparent;
        border-bottom: 0.8125rem solid ${color.white};
        border-left: 0.75rem solid transparent;
        @media screen and (max-width: 768px) {
            display: flex;
            position: relative;
            margin: auto;
            margin-bottom: -0.9375rem;
            left: 0;
        }
    }
    @media screen and (max-width: 768px) {
        right: 0;
        left: ${props => props.isCVC ? '-4.375rem' : '0'};
        transform: translateX(0);
    }
`

interface TooltipContainer {
    tooltipName: string,
    isVisible: boolean,
    widthSize?: string,
    expandVisibility?: boolean
}
// TooltipContainer
const TooltipContainer = styled.div<TooltipContainer>`
    display: ${props => props.isVisible === true ? "none" : "flex"};
    flex-wrap: wrap;
    min-width: 13.625rem;
    max-width: ${props => props.widthSize ? props.widthSize : "18.1875rem"};
    height: 1.25rem;
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;
    margin-top: 0.6875rem;
    outline: none;
    &:hover ${TooltipExpandable} {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        transition: all .3s ease;
    }
    &:hover ${TooltipIconContainer} {
        background-color: ${color.tooltip_icon};
        transition: background 0.5s ease-in-out;
    }
    @media screen and (max-width: 768px) {
        max-width: 100%;
        min-width: 100%
    }
`

const TooltipExpandableContent = styled.div`
    font-family: museo-sans, sans-serif;
    padding: 0.9375rem 1.1325rem 0.875rem 0.9375rem;
    text-align: left;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: -0.0025rem;
    color: ${color.grey_29_percent};
    @media screen and (max-width: 768px) {
        padding: 0 1.875rem 1.3125rem 1.875rem;
        letter-spacing: 0;
    }

`

interface TooltipMessage {
    tooltipName: string
}
const TooltipMessage = styled.span<TooltipMessage> `
    font-family: museo-sans, sans-serif;
    font-style: italic;
    font-weight: 500;
    margin-left: 0.625rem;
    font-size: 1rem;
    margin-left: 0.4rem;
    cursor: pointer;
    color: ${color.grey_darker};
`

const Tooltip = (props: any) => {
    const [expandVisibility, setExpandVisibility] = useState(false);
    useEffect(() => {
        const closeExpand = () => {
            setExpandVisibility(false);
        };

        document.body.addEventListener('click', closeExpand);
        return () => { document.body.removeEventListener('click', closeExpand) }
    }, []);

    if (isMobile) {
        return (
            <div>
                <TooltipContainer
                    isVisible={props.visibility}
                    tooltipName={props.tooltipName}
                >
                    <TooltipIconContainer>
                        <StyledIcon icon={faInfo} />
                    </TooltipIconContainer>
                    <TooltipMessage tooltipName={props.tooltipName}>
                        {props.tooltipTitle}
                    </TooltipMessage>
                    <TooltipExpandable
                        isVisible={expandVisibility}
                        isCVC={props.tooltipName === 'cvc'}
                    >
                        <TooltipExpandableContent dangerouslySetInnerHTML={{
                            __html: props.tooltip_message
                        }}>
                        </TooltipExpandableContent>
                    </TooltipExpandable>
                </TooltipContainer>
            </div>
        )
    }

    return (
        <div>
            <TooltipContainer
                onClick={() => setExpandVisibility(true)}
                isVisible={props.visibility}
                tooltipName={props.tooltipName}
                widthSize={props.widthSize}>
                <TooltipIconContainer
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setExpandVisibility(true)
                        }
                    }}
                    expandVisibility={expandVisibility}
                >
                    <StyledIcon icon={faInfo} />
                </TooltipIconContainer>
                <TooltipMessage tooltipName={props.tooltipName}>
                    {props.tooltipTitle}
                </TooltipMessage>
                <TooltipExpandable isVisible={expandVisibility}>
                    <TooltipExpandableContent dangerouslySetInnerHTML={{
                        __html: props.tooltip_message
                    }}>
                    </TooltipExpandableContent>
                </TooltipExpandable>
            </TooltipContainer>
        </div>
    )
}

export default Tooltip;
