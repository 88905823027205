import React from 'react'
import Portal from './portal'
import Form from './form'
import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { rem } from '../../styles/Mixins'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { Container, RadioButtonContainerWrapper, StyledTitle } from '../RadioButtonGroup/ItemButton'
import jump from 'jump.js'

const StyledRadioButtonContainerWrapper = styled(RadioButtonContainerWrapper)`
  && {
    width: 100%;
  }
`

const StyledButtonContainer = styled(Container)`
  && {
    width: 100%;
    ${media.md`
      max-width: ${rem(300)};
    `}
  }
`

const OldModalButton = styled.button`
  background: #EFF0F0;
  width: 100%;
  height: ${rem(45)};
  border-radius: ${rem(10)};
  font-size: ${rem(15)};
  line-height: 1.625rem;
  color: #757575;
  border: none;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
  padding: 0 ${rem(15)};
  text-align: left;
  box-sizing: border-box;
  margin-bottom: ${rem(40)};

  ${media.md`
    height: ${rem(54)};
    font-size: ${rem(16)};
    text-align: center;
    max-width: ${rem(300)};
  `}

  &:hover {
    color: #012F57;
  }

  svg {
    margin-right: ${rem(17)};
  }

  &.has-sign {
    background: #E2F1F8;
    color: #012F57;

    &:hover {
      background: #EFF0F0;
      color: #757575;
    }
  }
`

const SignedImage = styled.div`
  font-size: ${rem(17)};
  line-height: ${rem(26)};
  color: #012F57;
  flex-basis: 100%;
  margin-top: 1.25rem;

  p {
    margin-bottom: ${rem(20)};
  }
`

const SignedImageInner = styled.div`
  background: #F3F3F3;
  border-radius: ${rem(10)};
  height: ${rem(120)};
  padding: ${rem(20)};
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${rem(10)};

  ${media.md`
    max-width: ${rem(470)};
  `}

  img {
    margin-bottom: 0;
    max-height: 100%;
    max-width: 100%;
    align-self: center;
  }
`

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(1, 47, 87, 0.9);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.165, 0.84, 0.44, 1);

  body.show-modal-content & {
    opacity: 1;
    z-index: 11;
  }
`

const FormValidationIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: ${rem(10)};
  display: none;

  svg {
    font-size: ${rem(10)};
    color: #ffffff;
  }

  ${(props) => props.success && css`
    background: #299B1A;
  `};

  ${(props) => props.show && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.wrapperRef = React.createRef()
    this.handleContainerClick = this.handleContainerClick.bind(this)
    this.closeModal = this.closeModal.bind(this)

    this.state = {
      showModal: false,
      signURL: null,
      signName: null,
    }

    this.jumpRef = React.createRef();
  }

  handleContainerClick(event) {
    event.stopPropagation()
    if(this.wrapperRef && event.target === this.wrapperRef.current) {
      this.toggleModal(!this.state.showModal)
    }
  }

  toggleModal(state) {
    this.setState({
      showModal: state,
    })
  }

  closeModal() {
    this.setState({
      showModal: false,
    })
    if (this.jumpRef.current) {
      jump(this.jumpRef.current, {
        offset: -100,
      })
    }
  }

  getSignedInfo(sURL, sName) {
    if (typeof sURL === 'string') {
      this.setState({
        signURL: sURL,
        signName: sName,
      }, () => {
        if (this.state.signURL && this.props.onComplete) {
          this.props.onComplete(this.state.signURL)
        }
      })
    }
  }

  render() {
    const { showModal, signURL, signName } = this.state

    return (
      <>
        <StyledRadioButtonContainerWrapper
          ref={this.jumpRef}
        >
          <StyledButtonContainer
            tabIndex="0"
            active={signURL}
            signatureGenerator={false}
            onClick={() => { this.toggleModal(!showModal) }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.toggleModal(!showModal)
              }
            }}
          >
            <StyledTitle
              active={signURL}
            >
              <FontAwesomeIcon icon={faEdit} /><i className="fas fa-edit"></i> {signURL ? (`Edit signature`) : (`Sign electronically`)} *
              </StyledTitle>
          </StyledButtonContainer>
        </StyledRadioButtonContainerWrapper>
        {signURL && signName && (
          <SignedImage>
            <p>Your signature</p>
            <SignedImageInner>
              <img src={signURL} alt={signName} />
            </SignedImageInner>
            <FormValidationIcon show={true} success={true}><FontAwesomeIcon icon={faCheck} /></FormValidationIcon>
          </SignedImage>
        )}
        {
          showModal ? (
            <Portal>
              <ModalContainer className="modal-overlay" ref={this.wrapperRef} onClick={this.handleContainerClick}>
                <Form getSignURL={this.getSignedInfo.bind(this)} isOpen={showModal} onClose={this.closeModal} />
              </ModalContainer>
            </Portal>
          ) : null
        }
      </>
    )
  }
}

export default Modal
