import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export type Props = {
    title: string
    des: string
    first?: any
}
export interface ContainerProps {
    first: boolean
}
const Container = styled.div<ContainerProps>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${props=>props.first?'0px':'1.875rem'}
`
const Icon = styled(FontAwesomeIcon)`
    width: 1.125rem;
    height: 1.125rem;
    color: #00AFD9;
    font-size: 1.125rem;
`
const ContainerRight = styled.div`
    display: flex;
    flex:1 ;
    margin-left: 0.5rem;
    flex-direction: column
`
const StyledText = styled.span`
  text-align: left;
  font-weight: 700;
  font-size: 1.5rem;
  font-family: museo-sans, sans-serif;
  letter-spacing: -0.07px;
  color: #012F57;
  opacity: 1;
`
const StyledTextTitle = styled(StyledText)`
  font-weight: 900;
  font-size: 0.9375rem;
  color: #012F57;
  letter-spacing: 0px;
`
const StyledTextDes = styled(StyledText)`
  font-weight: 500;
  font-size: 0.9375rem;
  color: #012F57;
  letter-spacing: 0px;
  margin-top: 0.625rem
`
export const ItemMember: React.FC<Props> = (props) => {
  return(
    <Container first={props.first}>
      <Icon icon={faCheck}/>
      <ContainerRight>
        <StyledTextTitle>
          {props.title}
        </StyledTextTitle>
        <StyledTextDes>
          {props.des}
        </StyledTextDes>
      </ContainerRight>
    </Container>
  )
}
ItemMember.defaultProps = {
  first: false
}
export default ItemMember
