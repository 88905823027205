import React from 'react'
import styled from 'styled-components'
import color from '../color-css/color'

export type Props = {
  className?: string
}

export const Elem = styled.td`
    border: 1px solid ${color.grey_92_percent};
    padding-left: 1.25rem !important;
    padding-top: 0.623rem !important;
    padding-bottom: 0.623rem !important;
    font-size: 0.9375rem;
    @media (max-width: 1024px) {
        width: 50%;
        padding-right: 0 !important;
    }
`

const TD: React.FC<Props> = ({ className, children }) => (
  <Elem className={className} >
    {children}
  </Elem>
)

export default TD
