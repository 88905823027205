import React from 'react'
import ReactDOM from 'react-dom'

const portalRoot = typeof document !== 'undefined' ? document.getElementById('portal') : null

class Portal extends React.Component {
  constructor() {
    super()
    this.el = typeof document !== 'undefined' ? document.createElement('div') : null
  }

  componentDidMount() {    
    portalRoot.appendChild(this.el)
    document.body.classList.add('modal-open')
    document.documentElement.classList.add('modal-open')
  }

  componentWillUnmount() {
    portalRoot.removeChild(this.el)
    document.body.classList.remove('modal-open')
    document.documentElement.classList.remove('modal-open')
  }

  render() {
    const { children } = this.props

    if (this.el) {
      return ReactDOM.createPortal(children, this.el)
    } else {
      return null
    }
  }
}

export default Portal