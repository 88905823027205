import React, { useState, useEffect } from 'react';
import CheckboxField from '../CheckboxField';
import ProgressButton, { ButtonStates } from '../ProgressButton'
import styled from 'styled-components';
import H3 from '../H3'
import ProgressButtonPositioningDiv from '../ProgressButton/ProgressButtonPositioningDiv'
import { handlePageSubmission } from '../../utils/submitData'

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/'
import { setUnsuccessfullyPosted, writeMembershipData, writeValidateData } from '../FormHighOrder/features/index'

import { PRE_EXISTING_ISSUES, MEMBERSHIP_AGREEMENT }
    from '../FormHighOrder/types/MembershipsField'
import { STATE_FROM_SUBURB } from '../FormHighOrder/types/YourWorkField'
export type Props = {
    onClickOnContinueButtonProp: () => void
}

import branchSpecific from '../../utils/branchSpecificCopy'

const FormStep3Wrapper = styled.div`
    width: 40.9375rem;
    position: relative;
    animation-name: flickity;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    @keyframes flickity {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
    };
    @media (max-width: 1024px) {
        margin: 0 auto;
    };
    @media screen and (max-width: 690px) {
        width: 100%;
        max-width: 100%;
    }
`

const CheckboxFieldWrapper = styled.div``

const FixedWidth = styled.div`
    width: 40.9375rem;
    height: 0;
    @media screen and (max-width: 690px) {
        display: none;
    }
`

const FormStep3: React.FC<Props> = ({ onClickOnContinueButtonProp }) => {
    const dispatch = useDispatch()

    const [preExistingIssuesCheck, setPreExistingIssueIsChecked] = useState(false)
    const [membershipAgreementCheck, setMembershipAgreementIsChecked] = useState(false)
    const [proceedingButtonState, setProceedingButtonState] = useState(ButtonStates.disabled)
    const [membershipTitlte, setMembershipTitlte] = useState('')

    const getValidate = useSelector((state: RootState) => {
        const { data } = state.FormHighOrder.pageData[2]
        return data[PRE_EXISTING_ISSUES].isValidated && data[MEMBERSHIP_AGREEMENT].isValidated
    })
    const getPrefetchData = useSelector((state: RootState) =>
        state.FormHighOrder.pageData[2].data)

    const getStateFromForm1 = useSelector((state: RootState) =>
        state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

    useEffect(() => {
        setValidationField(PRE_EXISTING_ISSUES, preExistingIssuesCheck)
        setValidationField(MEMBERSHIP_AGREEMENT, membershipAgreementCheck)

        if (getValidate
            && preExistingIssuesCheck
            && membershipAgreementCheck) {
            setPageValidation(2, true)
            setProceedingButtonState(ButtonStates.enabled)
        } else {
            setPageValidation(2, false)
            setProceedingButtonState(ButtonStates.disabled)
        }

    }, [getValidate, preExistingIssuesCheck, membershipAgreementCheck])

    useEffect(() => {
        setField(PRE_EXISTING_ISSUES, preExistingIssuesCheck ? 'true' : 'false')
        setField(MEMBERSHIP_AGREEMENT, membershipAgreementCheck ? 'true' : 'false')
    }, [preExistingIssuesCheck, membershipAgreementCheck])

    useEffect(() => {
        if (getStateFromForm1 !== undefined) {
            if (getStateFromForm1 === 'NT' || getStateFromForm1 === 'Broken Hill'
                || getStateFromForm1 === 'SA') {
                setMembershipTitlte('SA, NT & Broken Hill' + ' ' + 'Membership agreement')
            }
            else setMembershipTitlte(getStateFromForm1 + ' ' + 'Membership agreement')
        }
    }, [getStateFromForm1])

    const setValidationField = (nameParam: string, wasAnInputSuccess: boolean) => {
        dispatch(writeMembershipData({
            key: nameParam,
            value: checkFetchData(nameParam) ? getPrefetchData[nameParam]?.value : undefined,
            isValidated: wasAnInputSuccess
        }
        ))
    }

    const setField = (nameParam: string, inputValue?: string) => {
        dispatch(writeMembershipData({
            key: nameParam,
            value: inputValue ? inputValue : "",
            isValidated: true
        }
        ))
    }

    const setPageValidation = (pageNum: number, isValid: boolean) => {
        dispatch(writeValidateData({
            pageId: pageNum,
            isValidated: isValid,
            data: undefined
        }))
    }

    const checkFetchData = (nameParam: string) => {
        return getPrefetchData !== undefined && getPrefetchData[nameParam]?.value !== ""
    }

    return (
        <FormStep3Wrapper>
            <H3>
                Our membership agreement
            </H3>
            <CheckboxFieldWrapper>
                <CheckboxField
                    longtext={false}
                    description={branchSpecific(getStateFromForm1, 'preExistingIssues')}
                    title='Pre-existing issues'
                    checkboxContent='I understand'
                    tooltip={true}
                    validateMessage="Thanks for understanding"
                    onCheckedValue={isChecked => {
                        setPreExistingIssueIsChecked(isChecked)
                    }}
                    inputFromProps={checkFetchData(PRE_EXISTING_ISSUES) ?
                        getPrefetchData[PRE_EXISTING_ISSUES]?.value === "true" : false}
                />
                <div style={{ marginTop: '2.5rem' }}>
                    <CheckboxField
                        longtext={true}
                        description={branchSpecific(getStateFromForm1, 'membershipAgreement')}
                        title={membershipTitlte}
                        checkboxContent='I agree to the membership agreement'
                        tooltip={false}
                        widthSize="19.9375rem"
                        padding="0.875rem 4.9375rem 0.875rem 1.25rem"
                        validateMessage=" "
                        onCheckedValue={isChecked => {
                            setMembershipAgreementIsChecked(isChecked)
                        }}
                        inputFromProps={checkFetchData(MEMBERSHIP_AGREEMENT) ?
                            getPrefetchData[MEMBERSHIP_AGREEMENT]?.value === "true" : false}
                    />
                </div>
            </CheckboxFieldWrapper>
            <FixedWidth></FixedWidth>
            <ProgressButtonPositioningDiv>
                <ProgressButton
                    widthSize="11.125rem"
                    title="Continue"
                    stepNumber={2}
                    onClickExecFunc={handlePageSubmission}
                    buttonStates={proceedingButtonState}
                    onClickCallbackProp={() => {
                        onClickOnContinueButtonProp()
                    }}
                />
            </ProgressButtonPositioningDiv>
        </FormStep3Wrapper>
    );
}

export default FormStep3;
