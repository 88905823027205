import styled from 'styled-components'
import visa from '../../../assets/images/visa.png'
import masterCard from '../../../assets/images/mastercard.png'
import amex from '../../../assets/images/amex.png'

const CardIcon = styled.div<{
  identifyCard?: number
}>`
display: none;
position: absolute;
margin-top: 0.5rem;
left: 15.25rem;
width: 2.625rem;
height: 1.6875rem;
background-repeat: no-repeat;
background-size: cover;
${props => props.identifyCard === 1 && `
  background-image: url(${visa});
  display: block;`}
${props => props.identifyCard === 2 && `
  background-image: url(${masterCard});
  display: block;`}
${props => props.identifyCard === 3 && `
  background-image: url(${amex});
  display: block;`}
`

export default CardIcon
