import React from "react";
import styled from 'styled-components'
import color from '../color-css/color'


export const DropdownFooter = styled.div`
    position:absolute;
    bottom:0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    height: 3.75rem;
    margin: 0 2.3125rem 0 1.75rem;
    border-right-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 0px;
    border-top: 1px;
    border-style: solid;
    border-color: rgba(201, 201, 201, 0.8);
    @media screen and (max-width: 768px) {
        margin: 0 1.1875rem;
        height: 2.5rem;
    }
`

export const DropdownFooterContent = styled.p`
    font-family: museo-sans, sans-serif;
    font-weight: 500;
    font-style: italic;
    font-size: 1rem;
    color: ${color.grey_darker};
    margin: auto;
    margin-left: 0;
    margin-right: 0;
    @media screen and (max-width: 768px) {
        font-size: 0.875rem;
        letter-spacing: -0.04px;
      }
`