import React from 'react'
import styled from 'styled-components'
import ButtonItem from './ItemButton'
import Label from '../Label'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ValidationIndicator from '../ValidationIndicator/index'
import color from '../color-css/color'

const Container = styled.div`
  @media screen and (max-width: 690px) {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 0.625rem;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 0.6875rem;
`

interface RadioButtonContaiter {
  className?: string
  visibility?: boolean | string
}

const RadioButtonContaiter = styled.div<RadioButtonContaiter>`
  display: ${props => (props.visibility === true || props.visibility === 'true') ? 'block' : 'none'};
  animation-name: flickity;
  animation-duration: 1s;
  @keyframes flickity {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  };
  @media (max-width: 690px) {
    margin-top: 1.875rem;
  }
`

const LabelIcon = styled.span`
  color: ${color.blue_darker};
`

interface PayrollCondition {
  isButtonAvailable?: boolean
}

const PayrollCondition = styled.div<PayrollCondition>`
  opacity: ${props => props.isButtonAvailable ? "1" : "0.3"};
  pointer-events: ${props => props.isButtonAvailable ? "auto" : "none"};
`

export type Props = {
  className?: string,
  onSelectTabProp?: (tabNumber: number) => void,
  label: string,
  title1: string,
  title2: string,
  title3?: string,
  optionFourId?: string,
  visibility?: boolean | string,
  onSelectedValue?: (value: string) => void,
  inputFromProps?: string
  onChangeActiveID?: (change: boolean) => void
  isPayrollDeductionAvailable?: boolean
  hasContactOption?: boolean
}

export const RadioButtonGroup: React.FC<Props> = ({
  className, onSelectTabProp, label, title1, title2, title3, optionFourId, visibility,
  onSelectedValue, inputFromProps, onChangeActiveID, isPayrollDeductionAvailable, 
  hasContactOption
}) => {
  const [activeId, setActiveId] = React.useState(3)
  const [validatorMsg, setValidatorMsg] = React.useState("")
  const [isAvailable, setIsAvailable] = React.useState<any>(true)

  React.useEffect(() => {
    if (inputFromProps !== undefined) {
      onSelectedValue ? onSelectedValue(inputFromProps) : {}
      if (inputFromProps === 'Bank account' || inputFromProps === '0-9 hours') {
        setActiveId(0)
        setValidatorMsg(' ')
      } else if (inputFromProps === 'Credit card' || inputFromProps === '10-19 hours') {
        setActiveId(1)
        setValidatorMsg(' ')
      }
      else if (title3 !== '' && (inputFromProps === 'Payroll deduction' || inputFromProps === '20+ hours')) {
        setActiveId(2)
        setValidatorMsg(' ')
      }
    }
    else if (title3 === '' && inputFromProps === 'Payroll deduction') {
      setActiveId(3)
    }
    else {
      setActiveId(3)
    }
  }, [inputFromProps, title3])

  React.useEffect(() => {
    if (title3 === 'Payroll deduction') {
      setIsAvailable(isPayrollDeductionAvailable)
    }
    else setIsAvailable(true)
  }, [title3])

  React.useEffect(() => {
    if ((title1 === '0-9 hours' || title2 === '10-19 hours'
      || title3 === '20+ hours') && activeId !== 3) {
      setValidatorMsg('')
    }
  }, [title1, title2, title3, activeId])

  React.useEffect(() => {
    if (activeId !== 3) {
      onChangeActiveID ? onChangeActiveID(true) : {}
    } else
      onChangeActiveID ? onChangeActiveID(false) : {}
  }, [activeId])

  return (
    <RadioButtonContaiter
      className={className}
      visibility={(visibility === true || visibility === 'true') ? 'true' : 'false'}

    >
      <Label>{label}
        {' '}
        <LabelIcon>*</LabelIcon>
      </Label>
      <Container>
        {!hasContactOption && 
        <ButtonItem
          active={activeId == 0}
          title={title1}
          onPress={() => {
            setActiveId(0)
            onSelectTabProp ? onSelectTabProp(0) : {}
            onSelectedValue ? onSelectedValue(title1) : {}
          }} />
          }
        {!hasContactOption && 
        <ButtonItem
          active={activeId == 1}
          title={title2}
          onPress={() => {
            setActiveId(1)
            onSelectTabProp ? onSelectTabProp(1) : {}
            onSelectedValue ? onSelectedValue(title2) : {}
          }} />
          }
        {title3 &&
          <PayrollCondition
            isButtonAvailable={isAvailable}
          >
            <ButtonItem
              active={activeId == 2}
              title={title3}
              onPress={() => {
                setActiveId(2)
                onSelectTabProp ? onSelectTabProp(2) : {}
                onSelectedValue ? onSelectedValue(title3) : {}
              }} />
          </PayrollCondition>
        }
        {
          (hasContactOption && optionFourId) && 
          <ButtonItem
              active={activeId == 3}
              title={optionFourId}
              onPress={() => {
                setActiveId(3)
                onSelectTabProp ? onSelectTabProp(3) : {}
                onSelectedValue ? onSelectedValue(optionFourId) : {}
              }} />
        }

      </Container>
      <ValidationIndicator message={validatorMsg} isCorrect={true} visibility={activeId == 3 ? false : true} />

    </RadioButtonContaiter>
  )
}
export default RadioButtonGroup
