import React from 'react'
import styled from 'styled-components'

const ProgressButtonPositioningDiv = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 7.5rem;
  @media (max-width: 768px) {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
`

export default ProgressButtonPositioningDiv