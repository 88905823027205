import React from 'react'
import Modal from './modal'
import { rem } from '../../styles/Mixins'
import { media } from 'styled-bootstrap-grid'
import { HoveringWrapper } from '../TextInput/styles/InputField'
import img from '../../assets/images/hand-point-up-1.png'
import styled from 'styled-components'
import Label from '../Label'

const SignLabel = styled.div`
  margin: 0;
  padding: 0;
  color: #012F57;
  font-size: ${rem(17)};
  line-height: ${rem(26)};
  margin-bottom: ${rem(20)};
  margin-top: ${rem(20)};
  span {
    color: #00AFD9;
  }

  ${media.md`
    margin-bottom: ${rem(30)};
    font-size: ${rem(16)};
    margin-top: 2.5rem;
  `}
`

const ReStyledHoveringWrapper = styled(HoveringWrapper)`
  display: block;
  margin: 0;
  padding: 0;
  color: #012F57;
  font-size: ${rem(17)};
  line-height: ${rem(26)};
  margin-bottom: ${rem(20)};
  margin-top: ${rem(20)};
  span {
    color: #00AFD9;
  }

  ${media.md`
    margin-bottom: ${rem(30)};
    font-size: ${rem(16)};
    margin-top: 2.5rem;
  `}
`

const ReStyledLabel = styled(Label)`
  margin-bottom: 1.875rem;
  color: #012F57;
`

const ESignature = (props) => {
  return (
    <ReStyledHoveringWrapper
      hoverIcon={img}
    >
      <div style={{ marginBottom: '30px' }}>
        <ReStyledLabel>{props.terms}
          <span style={{ color: '#00AFD9', fontSize: '1.25rem' }}>*</span>
        </ReStyledLabel>
      </div>
      <Modal
        onComplete={props.onComplete}
      />
    </ReStyledHoveringWrapper>
  )
}

export default ESignature
