import React from 'react'
import styled from 'styled-components'
import color from '../color-css/color'

const HorizontalLine = styled.hr
`
  border-top: 1px solid #012F5733;
  border-bottom: 0px;
  width: 40.9375rem;
  height: 0px;
  opacity: 0.4;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

export default HorizontalLine
