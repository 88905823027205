import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import color from '../color-css/color'

const ButtonContainer = styled.div<PropsActive>`
  background: ${props => props.active ? '#E2F1F8' : '#F3F3F3'};
  border-radius: 0.625rem;
  opacity: 1;
  width: ${props => props.widthSize ? props.widthSize : '13.125rem'};
  padding: ${props => props.padding ? props.padding : '1.0625rem 4.375rem 1.0625rem 1.25rem'};
  border: 1px solid ${color.white};
  margin-right: 0.625rem;
  line-height: 1.25rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  outline: none;
  &:focus {
    transition: border 0.5s ease-in-out;
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
    cursor: pointer;	
  };
  @media screen and (max-width: 690px) {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0.6875rem 0;
  };
`

const ButtonContainerHover = styled.div`
  outline: none;
  &:hover ${ButtonContainer} {
    transition: border 0.5s ease-in-out;
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
    cursor: pointer;
  };
  @media screen and (max-width: 690px) {
    width: 100%;
  };
`

const StyledTitle = styled.div<PropsActive>`
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -0.05px;
  color:${props => props.active ? '#052948' : '#757575'};
`
const StyledIcon = styled(({ active, ...rest }) => <FontAwesomeIcon {...rest}/>)<PropsActive>`
  color: ${props => props.active ? '#ff6820' : '#757575'};
  font-size: 1.25rem;
  text-align: center;
  margin-right: 1.25rem;
  @media screen and (max-width: 690px) {
    margin-left: 0.9375rem;
    font-size: 1rem;
  }
`

interface Props {
  active: boolean
  onPress: any
  title: string
  padding?: string
  widthSize?: string
}
interface PropsActive {
  visibility?: boolean
  active: boolean
  padding?: string
  widthSize?: string
  tabIndex?: any
}
export const ButtonItem: React.FC<Props> = (props) => {
  const { padding, widthSize } = props
  return (
    <ButtonContainerHover >
      <ButtonContainer tabIndex="0"
        padding = {padding}
        widthSize = {widthSize}
        active={props.active}
        onKeyDown={(e)=>{
          if (e.key === 'Enter') {
            props.onPress()
          }
        }}
        onClick={() => { props.onPress() }}>
        <StyledIcon
          active={props.active}
          icon={props.active ? faCheck : faSquare}
        />
        <StyledTitle active={props.active}>
          {props.title}
        </StyledTitle>
      </ButtonContainer>
    </ButtonContainerHover>
  )
}
export default ButtonItem
