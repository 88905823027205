import styled from 'styled-components'
import color from '../color-css/color'
import React, {useEffect} from 'react'

export type Props = {
  iconStates?: boolean,
}

const handleButtonStates = (iconState?: boolean) => {
  return iconState && iconState === true ? color.blue_darker : color.red
}

interface LabelIcon {
  iconState?: boolean
}

const LabelIconStyle = styled.span<LabelIcon>`
  color: ${({ iconState }) => handleButtonStates(iconState)};
`
const LabelIcon: React.FC<Props> = (props:any) => {
  const {iconStates} = props
  const [validation, setValidation] = React.useState(iconStates)

  useEffect(() => {
    setValidation(iconStates)
   }, [iconStates, validation])

  return (
    <>
      <LabelIconStyle iconState={iconStates}>
        {props.children}
      </LabelIconStyle>
    </>
  )
}

export default LabelIcon
