import { renderPhoneNumberStrat1, renderPhoneNumberStrat2 } from '../phoneNumberRendererStrats'
import { renderCreditCard, renderBSB, renderAccountNumber, renderExpiryDate } from '../CardRenderer'


import {
  unionPayRegex, amexRegex, dinersClubRegex,
  jcbRegex, mastercardRegex, visaRegex, visaMastercardRegex,
  discoverRegex, maestroRegex
} from './cardRegexes'

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateEmail = (input: string) => emailRegex.test(input)

export const validatePostcode = (input: string) => input.length === 4

export const validateExpiryDate = (input: string) => {
  let check = false
  let month = input.substring(0, 2)
  let testMonth = +month
  if (testMonth > 12) return check
  else check = true
  return input.length === 5 && check === true
}

const branchSupportsCreditCard = (cardnumber: string, branch: string): boolean => {
  // EziDebit
  if (branch === 'SA' || branch === 'NT' || branch === 'Broken Hill' || branch === 'TAS') {
    return mastercardRegex.test(cardnumber) || visaRegex.test(cardnumber)
      || visaMastercardRegex.test(cardnumber) || amexRegex.test(cardnumber)
  }

  // PayWay
  // https://www.payway.com.au/docs/net.html#things-you-should-know
  // (1) If you want to accept American Express, Diners Club or JCB cards you will need to enter 
  // into separate agreements with American Express, Diners Club International or JCB International.
  if (branch === 'Newcastle') {
    return mastercardRegex.test(cardnumber) || visaRegex.test(cardnumber)
      || visaMastercardRegex.test(cardnumber) || unionPayRegex.test(cardnumber)
  }

  // Fat Zebra
  // https://www.about-payments.com/knowledge-base/provider/fat-zebra#methods
  if (branch === 'WA') {
    return mastercardRegex.test(cardnumber) || visaRegex.test(cardnumber)
      || visaMastercardRegex.test(cardnumber) || unionPayRegex.test(cardnumber) || maestroRegex.test(cardnumber)
  }

  return false
}

export const validateCreditCard = (input: string, branch: string) => {
  // Deny validation if branch doesn't support card vendor
  if (!branchSupportsCreditCard(input, branch)) {
    return false
  }
  else return true
}

export const validateBSB = (input: string) => {
  return input.length === 6
}

export const validateAccountNumber = (input: string) => {
  return input.length === 8
}

export const handleCreditCardNumber = (inputGetter: string, event: any) => {
  let result: any = renderCreditCard(inputGetter)
  if (inputGetter.length === 5 && inputGetter[4] !== ' ') {
    event.target.value = result
  }
  if (inputGetter.length === 10 && inputGetter[9] !== ' ') {
    event.target.value = result
  }
  if (inputGetter.length === 15 && inputGetter[14] !== ' ') {
    event.target.value = result
  }
  if (inputGetter.length === 18) {
    event.target.value = result
  }
  return event.target.value
}

export const handleExpiryDate = (inputGetter: string, event: any) => {
  let result = renderExpiryDate(inputGetter)
  if (inputGetter.length === 3 && inputGetter[2] !== ' ') {
    event.target.value = result
  }
  if (inputGetter.length === 4 && inputGetter.slice(-1) !== '/') {
    event.target.value = result
  }
  if (inputGetter.length === 5 && inputGetter[4] !== ' ') {
    event.target.value = result
  }
  if (inputGetter.length === 6) {
    event.target.value = result
  }
  return event.target.value
}