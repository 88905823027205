import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SummaryTable } from './SummaryTable'
import ValidationIndicator from '../ValidationIndicator'
import { NoteOfPayment } from './NoteOfPayment'
import CheckboxField from '../CheckboxField/index'
import HorizontalLine from '../HorizontalLine'
import color from '../color-css/color'
export type Props = {
  summaryTableDataProp?: TableData[]
  paymentAuthorisationContent?: string
  paymentAgreementProp?: string
  noteOnPaymentProp: string[]
  onSelectAgreeTermAndCondition?: (wasSelected: boolean) => void
  inputFromProps?: string
  isValidated?: boolean
  hasTermCheckbox?: boolean
}

const SummarySectionContainer = styled.div`
    margin-top: 3.75rem;
    max-width: 40.9375rem;
    font-family: museo-sans, sans-serif;
`

const SummaryTitle = styled.h6`
    text-align: left;
    font: 700 1.25rem museo-sans, sans-serif;
    letter-spacing: -0.06px;
    color: ${color.blue_17_percent};
    opacity: 1;
    margin-top: 3.75rem;
`

const HorizontalLineSummarySection = styled(HorizontalLine)`
    margin-top: 3.5rem;
`

interface ContainerCheckboxProps {
  isCheck?: boolean,
  check: boolean,
  tabIndex?: any
}
const ContainerCheckbox = styled.a<ContainerCheckboxProps>`
    max-width: 36.5625rem;
    background-color:${props => props.check ? '#E2F1F8' : '#EFF0F0'} ;
    transition: background,border 0.5s ease-in-out;
    border: 1px solid ${color.white};
    border-radius: 0.625rem;
    opacity: 1;
    display: flex;
    flex-direction: row;
    margin-top: 2.5rem;
    padding: 1.25rem 1.8125rem 1.25rem 1.25rem;
    cursor: pointer;
`

const ContainerCheckboxHover = styled.div`
    margin-bottom: 0.625rem;
    &:hover ${ContainerCheckbox} {
        border: 1px solid ${color.blue_darker};
        border-radius: 0.625rem;
        cursor: pointer;
    }
`
const ContainerIcon = styled.div`
  width: 1.15rem;
  margin-top: 0.30rem;
  @media screen and (max-width: 1024px) {
    margin-left: 0.9375rem;
    width: 0.875rem;
  }
`
const StyledIcon = styled(FontAwesomeIcon) <ContainerCheckboxProps>`
  font-size: 1.3125rem;
  animation-name: flickity;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  @keyframes flickity {
      0% {
          opacity:0;
      }
      100% {
          opacity:1;
      }
  };
  display: ${props => props.isCheck ? 'block' : 'none'};
  color: ${props => props.check ? '#ff6820' : '#757575'};
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }
`
const StyledTitle = styled.span<ContainerCheckboxProps>`
    max-width: 34.0625rem;
    text-align: left;
    font: 500 1rem museo-sans, sans-serif;
    letter-spacing: -0.05px;
    color: ${props => props.check ? color.blue_17_percent : '#757575'};
    opacity: 1;
    margin-left: 1.35rem;
    line-height: 1.625rem;
    @media screen and (max-width: 1024px) {
      font-size: 0.9375rem;
      line-height: 1.375rem;
    }
`

const SummarySection: React.FC<Props> = ({
  summaryTableDataProp,
  paymentAuthorisationContent,
  paymentAgreementProp,
  noteOnPaymentProp,
  onSelectAgreeTermAndCondition,
  inputFromProps,
  hasTermCheckbox
}) => {
  const [check, changeCheck] = React.useState<boolean>(false)

  React.useEffect(() => {
    onSelectAgreeTermAndCondition ? onSelectAgreeTermAndCondition(check) : {}
  }, [check])

  return (
    <SummarySectionContainer>
      <HorizontalLineSummarySection />
      <SummaryTitle>Summary</SummaryTitle>
      {
        summaryTableDataProp
        && summaryTableDataProp.length > 0
        && <SummaryTable tableData={summaryTableDataProp} />}

      {noteOnPaymentProp
        && noteOnPaymentProp.length > 0
        && <NoteOfPayment noteOnPayment={noteOnPaymentProp} />}

      { hasTermCheckbox === true &&
        <div style={{ marginBottom: '3.75rem' }}>
          <HorizontalLineSummarySection />
        </div>
      }

      { hasTermCheckbox === true &&
        <CheckboxField
          variant_2={true}
          longtext={typeof paymentAuthorisationContent !== 'undefined' && paymentAuthorisationContent.length > 500}
          longtext_2={typeof paymentAgreementProp !== 'undefined' && paymentAgreementProp.length > 500}
          title='Payment authorisation'
          title_2='Payment agreement'
          widthSize='36.5625rem'
          padding='0.9375rem 1.8125rem 0.9375rem 1.25rem'
          checkboxContent='I agree to the Terms and Conditions of the Direct Debit Request and Service Agreement *'
          description={paymentAuthorisationContent}
          description_2={paymentAgreementProp}
          onCheckedValue={(isCheck) => {
            changeCheck(isCheck)
          }}
          inputFromProps={inputFromProps === 'true' ? true : false}
        />
      }

      {
        <ValidationIndicator
          isCorrect={check}
          message=""
          visibility={check} />
      }
    </SummarySectionContainer>
  )
}

export default SummarySection
