import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { animateScroll as scroll } from 'react-scroll'
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect'

import { getMessages } from '../dataProcessing'

import TextInput from '../TextInput/index'
import CleaveField from '../CleaveFields'
import SuburbAutosuggestField from '../SuburbAutosuggestField'
import WorkPostcode from '../WorkPostcode'
import EmployerAutosuggestField from '../EmployerAutosuggestField'
import ProgressButton, { ButtonStates } from '../ProgressButton'
import ProgressButtonPositioningDiv from '../ProgressButton/ProgressButtonPositioningDiv'
import H3 from '../H3'
import FormDataType from 'components/types/FormDataType'
import InputFieldContainer from '../Commons/InputFieldContainer'
import { handlePageSubmission } from '../../utils/submitData'

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/'
import {
  writeYourWorkData, initialState, writeData, writeValidateData, resetAllState, setUnsuccessfullyPosted
} from '../FormHighOrder/features/index'
import {
  reset as resetIsMultiBranch
} from '../../features/duplicates'
import ReactRef from '../FormHighOrder/types/ReactRef'
import color from '../color-css/color'

import {
  FIRST_NAME, LAST_NAME, PHONE_NUMBER,
  EMAIL, SUBURB_WORK, EMPLOYER, WORK_PLACE_POSTCODE,
  CANT_FIND_SUBURB_SELECTED, STATE_FROM_SUBURB, EMPLOYER_SELECTED_OR_TYPED,
  PAYROLL_NUMBER_REQUIRED, PAYROLL_DEDUCTION_REQUIRED
} from '../FormHighOrder/types/YourWorkField'
import { isEmpty } from 'lodash'
import getRedirectLink from '../../utils/redirectLink'
import { handleStateByPostcode } from '../SuburbAutosuggestField/SuggestSource'

export type Props = {
  onClickOnContinueButtonProp: () => void
  data?: FormDataType
  onHandleFormValidated?: (isValidate: boolean) => void
  onHandleResetIndex?: (isValidate: boolean) => void
  isSkipToContentPressed?: boolean
}

const FormStep1Container = styled.div`
  width: 40.9375rem;
  @media (max-width: 1024px) {
    margin:auto;
    margin-top: 0;
  };
  @media (max-width: 690px) {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  };
`

const FormStep1Form = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 690px) {
    display: block;
    width: 100%;
  }
`

const PositioningH3 = styled(H3)`
  margin-bottom: 0 !important;
`

interface EmployerOpacity {
  displayField?: boolean
  inputOpacity?: number
  stateDisable?: string
}

const EmployerOpacity = styled.div<EmployerOpacity>`
  display: ${props => props.displayField ? 'block' : 'none'};
  opacity: ${props => props.inputOpacity ? props.inputOpacity : 1};
  pointer-events: ${props => props.stateDisable ? props.stateDisable : "none"};
  transition: opacity 0.5s ease-in-out
`

interface MessageForSpecialState {
  state: string
}

const MessageForSpecialState = styled.div<MessageForSpecialState>`
  margin-top: ${props => props.state === 'NSW' || props.state === 'Newcastle' ? '2.1875rem' : '0'};
  margin-bottom: 0.625rem;
  text-align: left;
  font: italic normal normal 1rem museo-sans, sans-serif;
  line-height: 1.75rem;
  letter-spacing: -0.05px;
  color: ${color.grey_29_percent};
  opacity: 1;
`

const PhoneFieldContainer = styled(InputFieldContainer)`
  @media screen and (min-width: 691px) {
    width: 46.1%;
  }
  // IE11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: 691px) {
    width: 49.6%;
  }
`


const FormStep1YourWork: React.FC<Props> = (props: any) => {
  const { onClickOnContinueButtonProp, onHandleFormValidated,
    onHandleResetIndex, isSkipToContentPressed } = props

  const [focusPostCode, setFocusPostCode] = useState(false)
  const [selectCantFindSuburb, setSelectCantFindSuburb] = useState(false)
  const [firstNameValidation, setFirstNameValidation] = useState(false)
  const [lastNameValidation, setLastNameValidation] = useState(false)
  const [phoneNumberValidation, setPhoneNumberValidation] = useState(false)
  const [emailValidation, setEmailValidation] = useState(false)
  const [suburbValidation, setSuburbValidation] = useState(false)
  const [employerValidation, setEmployerValidation] = useState(false)
  const [suburValue, setSuburbValue] = useState('')
  const [workPostCodeValidation, setWorkPostCodeValidation] = useState(false)
  const [workPostCodeValue, setworkPostCodeValue] = useState('')
  const [stateFromSuburbOrPostcode, setStateFromSuburbOrPostcode] = useState('')
  const [employerInputShow, setEmployerInputShow] = useState(true)
  const [employerInputOpacity, setEmployerInputOpacity] = useState(1)
  const [employerInputDisable, setEmployerInputDisable] = useState('')
  const [hasInputValueChange, setInputValueChange] = useState(false)
  const [isSuburValueChange, setIsSuburValueChange] = useState(false)
  const [isPostcodeValueChange, setIsPostcodeValueChange] = useState(false)
  const [isEmployerValueChanged, setIsEmployerValueChanged] = useState(false)
  const [isSuburbScrolled, setIsSuburbScrolled] = useState(false)
  const [isEmployerScrolled, setIsEmployerScrolled] = useState(false)
  const [postcodeFromSuburb, setPostcodeFromSuburb] = useState<any>(undefined)

  const scrollToSuburbField = () => {
    if (isMobile && isSuburbScrolled === false) {
      setIsSuburbScrolled(true)
      scroll.scrollTo(750);
    }
    else return
  };

  const scrollToEmployerField = () => {
    if (isMobile && isEmployerScrolled === false) {
      setIsEmployerScrolled(true)
      scroll.scrollTo(900);
    }
    else return
  };

  const [proceedingButtonState, setProceedingButtonState] = useState(ButtonStates.disabled)
  const dispatch = useDispatch()

  const Refs: ReactRef = {
    [FIRST_NAME]: useRef<HTMLInputElement>(null),
    [LAST_NAME]: useRef<HTMLInputElement>(null),
    [PHONE_NUMBER]: useRef<HTMLInputElement>(null),
    [EMAIL]: useRef<HTMLInputElement>(null),
    [SUBURB_WORK]: useRef<HTMLInputElement>(null),
    [EMPLOYER]: useRef<HTMLInputElement>(null)
  }

  // get data from redux of form 2 3 4 for clearing data when changes suburb or work postcode
  let dataFormAboutYou = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[1])
  let dataFormMembership = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[2])
  let dataFormContribution = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[3])

  const getValidate =
    useSelector((state: RootState) => {
      const { data } = state.FormHighOrder.pageData[0]
      if (stateFromSuburbOrPostcode === 'VIC') {
        return data[FIRST_NAME].isValidated
          && data[LAST_NAME].isValidated
          && data[PHONE_NUMBER].isValidated
          && data[EMAIL].isValidated
          && data[SUBURB_WORK].isValidated
      } else {
        if (selectCantFindSuburb === true) {
          return data[FIRST_NAME].isValidated
            && data[LAST_NAME].isValidated
            && data[PHONE_NUMBER].isValidated
            && data[EMAIL].isValidated
            && data[WORK_PLACE_POSTCODE].isValidated
            && data[EMPLOYER].isValidated
        } else {
          return data[FIRST_NAME].isValidated
            && data[LAST_NAME].isValidated
            && data[PHONE_NUMBER].isValidated
            && data[EMAIL].isValidated
            && data[SUBURB_WORK].isValidated
            && data[EMPLOYER].isValidated
        }
      }
    })

  useEffect(() => {
    if (suburbValidation === false && workPostCodeValidation === false) {
      setEmployerInputOpacity(0.3)
      setEmployerInputDisable('none')
    }
    else if (suburbValidation === true || workPostCodeValidation === true) {
      setEmployerInputOpacity(1)
      setEmployerInputDisable('auto')
    }
  }, [suburbValidation, workPostCodeValidation])

  const getPrefetchConfig =
    useSelector((state: RootState) => state.FormHighOrder.pageData[0])
  const getPrefetchData =
    useSelector((state: RootState) => state.FormHighOrder.pageData[0].data)

  useEffect(() => {
    firstNameValidation     && setValidationField(FIRST_NAME, getPrefetchData[FIRST_NAME].isValidated)
    lastNameValidation      && setValidationField(LAST_NAME, getPrefetchData[LAST_NAME].isValidated)
    phoneNumberValidation   && setValidationField(PHONE_NUMBER, getPrefetchData[PHONE_NUMBER].isValidated)
    emailValidation         && setValidationField(EMAIL, getPrefetchData[EMAIL].isValidated)
    suburbValidation        && setValidationField(SUBURB_WORK, getPrefetchData[SUBURB_WORK].isValidated)
    employerValidation      && setValidationField(EMPLOYER, getPrefetchData[EMPLOYER].isValidated)
    workPostCodeValidation  && setValidationField(WORK_PLACE_POSTCODE, getPrefetchData[WORK_PLACE_POSTCODE].isValidated)

    if (stateFromSuburbOrPostcode === 'VIC') {
      getValidate &&
        (firstNameValidation
          && lastNameValidation
          && emailValidation
          && phoneNumberValidation
          && (suburbValidation || workPostCodeValidation)) ?
        setProceedingButtonState(ButtonStates.enabled) :
        setProceedingButtonState(ButtonStates.disabled)
    } else {
      if (selectCantFindSuburb === true) {
        setSuburbValidation(false)
        const newState = (firstNameValidation
          && lastNameValidation
          && emailValidation
          && phoneNumberValidation
          && workPostCodeValidation
          && employerValidation)
        if (getValidate && newState) {
          setProceedingButtonState(ButtonStates.enabled)
        } else {
          setProceedingButtonState(ButtonStates.disabled)

        }
      } else {
        const newState = (firstNameValidation
          && lastNameValidation
          && emailValidation
          && phoneNumberValidation
          && suburbValidation
          && employerValidation)
        if (getValidate && newState) {
          setProceedingButtonState(ButtonStates.enabled)
        } else {
          setProceedingButtonState(ButtonStates.disabled)
        }
      }
    }

  }, [
    getValidate,
    firstNameValidation, lastNameValidation,
    phoneNumberValidation, emailValidation,
    suburbValidation, employerValidation, selectCantFindSuburb,
    workPostCodeValidation, stateFromSuburbOrPostcode
  ])

  const restoreSnapshot = () => {
    dataFormAboutYou = { ...dataFormAboutYou, ...initialState.pageData[1] }
    dataFormMembership = { ...dataFormMembership, ...initialState.pageData[2] }
    dataFormContribution = { ...dataFormContribution, ...initialState.pageData[3] }
    reWriteReduxData(1, dataFormAboutYou.isValidated, dataFormAboutYou.data)
    reWriteReduxData(2, dataFormMembership.isValidated, dataFormMembership.data)
    reWriteReduxData(3, dataFormContribution.isValidated, dataFormContribution.data)
  }

  useEffect(() => {
    if (getPrefetchData[WORK_PLACE_POSTCODE].value !== undefined
      && isPostcodeValueChange === true) {
      restoreSnapshot()

      resetData(EMPLOYER)
    }

  }, [workPostCodeValue, isPostcodeValueChange])

  useEffect(() => {
    if (postcodeFromSuburb !== undefined || isSuburValueChange === true || selectCantFindSuburb == true) {
      restoreSnapshot()

      resetData(EMPLOYER)
      resetData(WORK_PLACE_POSTCODE)
    }
  }, [postcodeFromSuburb, isSuburValueChange, selectCantFindSuburb])

  useEffect(() => {
    if (isEmployerValueChanged === true) {
      restoreSnapshot()
      setIsEmployerValueChanged(false)
    }
  }, [isEmployerValueChanged])

  useEffect(() => {
    const cond = proceedingButtonState !== "disabled"
    setPageValidation(0, cond)
    onHandleFormValidated ? onHandleFormValidated(cond) : {}
  }, [proceedingButtonState])

  useEffect(() => {
    if (getPrefetchData[SUBURB_WORK].value !== suburValue
      && getPrefetchData[SUBURB_WORK].value !== undefined
      && suburValue !== '') {
      onHandleResetIndex ? onHandleResetIndex(true) : {}
    }
  }, [suburValue])

  const setPageValidation = (pageNum: number, isValid: boolean) => {
    dispatch(writeValidateData({
      pageId: pageNum,
      isValidated: isValid,
      data: undefined
    }))
  }

  useEffect(() => {
    if (selectCantFindSuburb === true) {
      setEmployerInputShow(true)
      setStateFromSuburbOrPostcode('')
      setField(STATE_FROM_SUBURB, '', true)
    }
  }, [selectCantFindSuburb])

  useEffect(() => {
    if (isSkipToContentPressed === true) {
      //@ts-ignore
      Refs[FIRST_NAME] && Refs[FIRST_NAME]?.current && Refs[FIRST_NAME]?.current?.focus()
    }
  }, [isSkipToContentPressed])

  useEffect(() => {
    dispatch(resetIsMultiBranch())
  }, [dispatch])

  const setValidationField = (nameParam: string, wasAnInputSuccess: boolean) => {
    if (!wasAnInputSuccess) {
      dispatch(setUnsuccessfullyPosted(0))
      dispatch(setUnsuccessfullyPosted(1))
      dispatch(setUnsuccessfullyPosted(2))
    }

    dispatch(writeYourWorkData({
      key: nameParam,
      value: checkFetchData(nameParam) ? getPrefetchData[nameParam]?.value : undefined,
      isValidated: wasAnInputSuccess
    }
    ))
  }

  const resetData = (nameParam: string, inputValue?: string) => {
    dispatch(writeYourWorkData({
      key: nameParam,
      value: inputValue ? inputValue : "",
      isValidated: false
    }
    ))
  }

  const setField = (nameParam: string, inputValue: string, isValidated: boolean) => {
    dispatch(writeYourWorkData({
      key: nameParam,
      value: inputValue ? inputValue : "",
      isValidated: isValidated
    }
    ))
  }

  const reWriteReduxData = (number: any, validated: any, data: any) => {
    dispatch(writeData({
      pageId: number,
      isValidated: validated,
      data: data
    }))
  }

  const checkFetchData = (nameParam: string) => {
    return getPrefetchData !== undefined && getPrefetchData[nameParam]?.value !== ""
  }

  const inputFieldFactory = (children: React.ReactNode) => {
    return (
      <InputFieldContainer>
        {children}
      </InputFieldContainer>)
  }

  const setValidation = (nameParam: string, wasAnInputSuccess: boolean) => {
    switch (nameParam) {
      case FIRST_NAME:
        setFirstNameValidation(wasAnInputSuccess)
        return;
      case LAST_NAME:
        setLastNameValidation(wasAnInputSuccess)
        return;
      case PHONE_NUMBER:
        setPhoneNumberValidation(wasAnInputSuccess)
        return;
      case EMAIL:
        setEmailValidation(wasAnInputSuccess)
        return;
    }
  }

  const createInputField = (
    label: string,
    placeHolderParam: string,
    nameParam: string,
    maxLength?: number,
    type?: string,
    aRef?: React.RefObject<HTMLInputElement>): React.ReactNode => {

    return (
      <TextInput
        placeholder={placeHolderParam}
        name={nameParam}
        type={type}
        maxLength={maxLength}
        {...{ label, message: getMessages }}
        onInputSucessCallback={wasAnInputSuccess => {
          setValidation(nameParam, wasAnInputSuccess)
        }}
        onInputValueCallback={(value, isValidated) => {
          setField(nameParam, value, isValidated)
        }}
        ref={aRef}
        inputFromProps={checkFetchData(nameParam) ?
          getPrefetchData[nameParam]?.value : undefined}
        isValidated={getPrefetchData[nameParam]?.isValidated}
        hasValueChange={(isChange) => {
          setInputValueChange(isChange)
        }}
      />
    )
  }

  const renderMessageForUserComeFromDifferentState = (state: string) => {
    if (state === 'VIC' || state === 'NSW' || state === 'Newcastle') {
      return (
        <MessageForSpecialState state={state}>
          It’s great to meet you! We want to make sure you get to the right place. Please click on the button below to be taken to the SDA {state} website where you can complete your member registration
        </MessageForSpecialState>
      )
    }
  }

  return (
    <FormStep1Container>
      <PositioningH3>
        Let’s get started
      </PositioningH3>
      <FormStep1Form>

        {inputFieldFactory(
          createInputField("Your first name", '', FIRST_NAME,
            undefined, 'text', Refs[FIRST_NAME])
        )}

        {inputFieldFactory(
          createInputField("Your last name", '', LAST_NAME,
            undefined, 'text', Refs[LAST_NAME])
        )}

        {inputFieldFactory(
          createInputField("Your email address", '', EMAIL,
            undefined, 'email', Refs[EMAIL])
        )}

        {<PhoneFieldContainer>
          <CleaveField
            name='phoneNumber'
            placeholder=''
            label='Your phone number'
            option={{
              phone: true,
              phoneRegionCode: 'AU',
            }}
            onInputSuccess={(wasAnInputSuccess: boolean) => {
              setValidation(PHONE_NUMBER, wasAnInputSuccess)
            }}
            handleData={(value, validated) => {
              setField(PHONE_NUMBER, value, validated)
            }}
            inputFromProps={checkFetchData(PHONE_NUMBER) ?
              getPrefetchData[PHONE_NUMBER]?.value : undefined}
            isValidated={getPrefetchData[PHONE_NUMBER]?.isValidated}
            validatorMsgFromProps={'Valid formats for this field are 04XX XXX XXX (for mobile) and 0X XXXX XXXX for landline'}
          />
        </PhoneFieldContainer>}

        {inputFieldFactory(
          <React.Fragment>
            <div onClick={() => {
              scrollToSuburbField()
            }}>
              <SuburbAutosuggestField
                onSelectedItemProp={wasSelected => {
                  setSuburbValidation(wasSelected)
                }}
                handleSuburbData={selectValue => {
                  const suburb = typeof selectValue !== 'string' ? selectValue.suburb : selectValue
                  const postcode = typeof selectValue !== 'string' ? selectValue.postcode : undefined
                  resetData(CANT_FIND_SUBURB_SELECTED)
                  resetData(WORK_PLACE_POSTCODE)
                  setPostcodeFromSuburb(postcode)
                  setSuburbValidation(suburb !== undefined || suburb !== "" || checkFetchData(SUBURB_WORK))
                  const cantFindSuburb = (suburb === `Can't find suburb`)
                  setSelectCantFindSuburb(cantFindSuburb)
                  cantFindSuburb === true ? setFocusPostCode(true) : setFocusPostCode(false)
                  setField(SUBURB_WORK, suburb, suburb !== undefined)
                  if (cantFindSuburb === false) {
                    resetData(CANT_FIND_SUBURB_SELECTED)
                  }
                  else {
                    setField(CANT_FIND_SUBURB_SELECTED, '', true)
                  }
                }}
                getStateValueFromSuburb={(state) => {
                  if (state !== '') {
                    if (state !== stateFromSuburbOrPostcode) {
                      // state has changed from the previous selected value
                      resetData(EMPLOYER_SELECTED_OR_TYPED)
                      resetData(EMPLOYER)
                    }
                    setField(STATE_FROM_SUBURB, state, true)
                    setStateFromSuburbOrPostcode(state)
                    if (state === 'VIC') setEmployerInputShow(false)
                    else setEmployerInputShow(true)
                  }
                }}
                hasSuburbValueChange={(isChange) => {
                  setIsSuburValueChange(isChange)
                }}
                inputFromProps={checkFetchData(SUBURB_WORK) ?
                  getPrefetchData[SUBURB_WORK]?.value : undefined}
                isValidatedFromProp={checkFetchData(SUBURB_WORK)
                  ? getPrefetchData[SUBURB_WORK]?.isValidated : false}
              />
            </div>
            <WorkPostcode
              isShow={selectCantFindSuburb || getPrefetchData[CANT_FIND_SUBURB_SELECTED]?.isValidated}
              isFocused={focusPostCode}
              onValidateWorkPostcode={success => {
                setWorkPostCodeValidation(success)
              }}
              onHandlePostcodeValue={inputValue => {
                if (typeof inputValue !== 'undefined' &&
                  inputValue !== getPrefetchData[WORK_PLACE_POSTCODE]?.value) {
                  const isValid = inputValue !== undefined && inputValue.length === 4
                  setField(WORK_PLACE_POSTCODE, isValid ? inputValue : '', isValid)
                }
              }}
              onHandleStateByPostcode={state => {
                if (state !== '') {
                  setField(STATE_FROM_SUBURB, state, true)
                  setStateFromSuburbOrPostcode(state)
                  if (state === 'VIC') setEmployerInputShow(false)
                  else setEmployerInputShow(true)
                }
              }}
              hasPostcodeValueChange={isChange => {
                setIsPostcodeValueChange(isChange)
              }}
              inputFromProps={checkFetchData(WORK_PLACE_POSTCODE) ?
                getPrefetchData[WORK_PLACE_POSTCODE]?.value : undefined}
              isValidatedFromProp={
                checkFetchData(WORK_PLACE_POSTCODE) ?
                  getPrefetchData[WORK_PLACE_POSTCODE]?.isValidated : false
              }
            />
          </React.Fragment>
        )}
        {inputFieldFactory(
          <React.Fragment>
            <div onClick={() => {
              scrollToEmployerField()
            }}>
              <EmployerOpacity
                inputOpacity={employerInputOpacity}
                stateDisable={employerInputDisable}
                displayField={employerInputShow}>
                <EmployerAutosuggestField
                  isAvailableToInteract={employerInputDisable === 'auto' ? true : false}
                  onSelectedItemProp={wasSelected => {
                    setEmployerValidation(wasSelected)
                  }}
                  suggestionSelectedOrTyped={isSelected => {
                    isSelected && setField(EMPLOYER_SELECTED_OR_TYPED, isSelected, true)
                  }}
                  ref={Refs[EMPLOYER]}
                  handleData={(value, isValid) => {
                    setIsEmployerValueChanged(value !== getPrefetchData[EMPLOYER]?.value)
                    setField(EMPLOYER, value, isValid)
                  }}
                  handlePayrollNumberRequire={isRequired => {
                    if (isRequired === true) {
                      setField(PAYROLL_NUMBER_REQUIRED, 'true', true)
                    } else resetData(PAYROLL_NUMBER_REQUIRED, 'false')
                  }}
                  handlePayrollDeductionRequire={isRequired => {
                    if (isRequired === true) {
                      setField(PAYROLL_DEDUCTION_REQUIRED, 'true', true)
                    } else resetData(PAYROLL_DEDUCTION_REQUIRED, 'false')
                  }}
                  inputFromProps={checkFetchData(EMPLOYER) ? getPrefetchData[EMPLOYER]?.value : undefined}
                  isValidatedFromProp={checkFetchData(EMPLOYER)
                    ? getPrefetchData[EMPLOYER]?.isValidated : false}
                />
              </EmployerOpacity>
            </div>
          </React.Fragment>
        )}

        {
          getValidate && renderMessageForUserComeFromDifferentState(getPrefetchData[STATE_FROM_SUBURB]?.value)
        }

        <ProgressButtonPositioningDiv>
          <ProgressButton
            widthSize="11.125rem"
            title="Continue"
            stepNumber={0}
            onClickExecFunc={handlePageSubmission}
            onValidationError={(field) => {
              setValidationField(field, false)
            }}
            buttonStates={proceedingButtonState}
            onClickCallbackProp={() => {
              let state
              if (typeof getPrefetchData[WORK_PLACE_POSTCODE]?.value !== 'undefined' && getPrefetchData[WORK_PLACE_POSTCODE]?.value.length) {
                // User has manually entered postcode
                state = handleStateByPostcode(getPrefetchData[WORK_PLACE_POSTCODE]?.value)
              } else {
                state = getPrefetchData[STATE_FROM_SUBURB]?.value
              }

              if (state && (state === 'VIC'
                || state === 'NSW' || state === 'Newcastle')) {
                if (typeof window !== 'undefined') {
                  window.location.href = getRedirectLink(state, getPrefetchData)
                }
              } else onClickOnContinueButtonProp()
            }}
          />

        </ProgressButtonPositioningDiv>
      </FormStep1Form>

    </FormStep1Container >
  )
}

export default FormStep1YourWork
