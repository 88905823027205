import styled from 'styled-components'
import color from '../color-css/color'

const Label = styled.label`
  display: block;
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  line-height: 1.75rem;
  font-size: 1.125rem;
  text-align: left;
  letter-spacing: -0.05px;
  color: ${color.blue_15_percent};
  @media screen and (max-width: 1024px) {
    font-size: 1.0625rem;
    line-height: 1.5rem;
  }
`

export default Label
