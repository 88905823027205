import React from "react";
import styled from 'styled-components'
import color from '../color-css/color'
export const DropdownFooter = styled.div`
    position:absolute;
    bottom:0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    height: 3.75rem;
    margin: 0 2.3125rem 0 1.75rem;
    border-right-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 0px;
    border-top: 1px;
    border-style: solid;
    border-color: rgba(201, 201, 201, 0.8);
    @media screen and (max-width: 690px) {
        margin: 0 1.1875rem;
        height: 2.5rem;
    }
    z-index: 999;
`

export const DropdownFooterContent = styled.p`
    font-size: 1rem;
    letter-spacing: -0.05px;
    font: italic normal normal 1rem museo-sans;
    line-height: 1.625rem;
    color: ${color.blue_darker};
    line-height: 1.625rem;
    margin-bottom: 0;
    margin-top: 0;
    cursor: pointer;
    outline: none;
    &:focus {
        color: ${color.blue_17_percent};
        transition: color 0.5s ease-in-out;
    }
    @media screen and (max-width: 690px) {
        font-size: 0.875rem;
        letter-spacing: -0.04px;
        line-height: 1.0625rem
    }
`

export const DropdownFooterContentHover = styled.div`
    margin: auto;
    margin-left: 0;
    margin-right: 0;
    &:hover ${DropdownFooterContent} {
        color: ${color.blue_17_percent};
        transition: color 0.5s ease-in-out;
    }
`

export const DropdownFooterImg = styled.span`
    margin: auto;
    margin-left: 0;
    margin-right: 0;
    @media screen and (max-width: 690px) {
        width: 5.4375rem;
        height: 0.9375rem;
        line-height: 15px;
      }
`
