import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { isMobile } from 'react-device-detect'

import styled from 'styled-components'
import { getMessages } from '../dataProcessing'

import SummarySection from '../SummarySection'
import colors from '../color-css/color'
import TextInput from '../TextInput/index'
import InputFieldContainer from '../Commons/InputFieldContainer'
import ReactRef from 'components/FormHighOrder/types/ReactRef'
import CheckboxField from '../CheckboxField/index'

import {
  PAY_DEDUCTION_PAYMENT_ELOTRONIC_SIGNATURE,
  PAY_DEDUCTION_PAYMENT_NUMBER,
  PAY_DEDUCTION_PAYMENT_TAB_DATA
} from '../FormHighOrder/types/YourContributionField'
import { PAYROLL_NUMBER_REQUIRED } from '../FormHighOrder/types/YourWorkField'

import {
  EMPLOYMENT_STATUS,
  EMPLOYMENT_STATUS_CASUAL
} from '../FormHighOrder/types/AboutYouField'

import { STATE_FROM_SUBURB } from '../FormHighOrder/types/YourWorkField'

import {
  writeForm4PayDeductionTabData
} from '../FormHighOrder/features/index'

import color from '../color-css/color'

import ESignature from '../esignature'

import branchSpecific from '../../utils/branchSpecificCopy'

export type Props = {
  expandableParagraphPayrollDescriptionTextProp?: string
}
const Container = styled.div`
  animation-name: flickity;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  @keyframes flickity {
      0% {
          opacity:0;
      }
      100% {
          opacity:1;
      }
  };
  display: inline;
  margin-top: 2.5rem;
  max-width: 38.75rem;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const SigningText = styled.div
  `
  text-size: 1rem;
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  color: ${color.blue_dark};
  width: 34.0625rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const LabelIcon = styled.span`
  color: ${color.blue_darker};
`

const SignButton = styled.button`
  font-size: 1rem;
  line-height: 1.625rem;
  letter-spacing: -0.06px;
  color: ${colors.grey_darker};
  padding: 0.9375rem 0;
  width: 18.875rem;
  border-radius: 10px;
  background: #EFF0F0;
`

const PayrollNumberRequired = styled.div`
  margin-bottom: 2.5rem;
`

export const PayDeductionTab: React.FC<Props> = ({
  expandableParagraphPayrollDescriptionTextProp
}) => {

  const dispatch = useDispatch()

  const [electricalSignature, setElectricalSignature] = useState(false)
  const [isPayrollNumberRequired, setIsPayrollNumberRequired] = useState(false)
  const [payrollNumberType, setPayrollNumberType] = useState('text')
  const [hasInputValueChange, setInputValueChange] = useState(false)
  const [payrollNumberLableRequired, setPayrollNumberLableRequired] = useState('')
  const [payrollNumberLableNotRequired, setPayrollNumberLableNotRequired] = useState('')
  const [weeklyRate, setWeeklyRate] = useState<string | undefined>(undefined)
  const [isHideValidate, setHideValidate] = useState(false)

  const getPrefetchData = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[3].data[PAY_DEDUCTION_PAYMENT_TAB_DATA]
  )

  const getStateFromForm1 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

  const checkFetchData = (nameParam: string) => {
    return getPrefetchData !== undefined && getPrefetchData[nameParam]?.value !== ""
  }

  const getPrefetchDataForm2 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[1].data
  )

  const dispatchPayDeductionTabData = (nameParams: string, data: string, dataValidated: boolean) => {
    dispatch(writeForm4PayDeductionTabData({
      key: nameParams,
      value: data ? data : "",
      isValidated: dataValidated
    }
    ))
  }

  useEffect(() => {
    if (['QLD', 'SA', 'TAS', 'NT', 'Broken Hill', 'WA'].includes(getStateFromForm1)) {
      if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Full Time'
        || getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Salaried'
        || getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '20+ hours'
      ) {
        setWeeklyRate('11.30')
      } else if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '10-19 hours'
      ) {
        setWeeklyRate('8.05')
      } else if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '0-9 hours'
      ) {
        setWeeklyRate('4.50')
      } 
    }
    else {
      if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Full Time'
        || getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Salaried'
        || getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '20+ hours'
      ) {
        setWeeklyRate('10.20')
      } else if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '10-19 hours'
      ) {
        setWeeklyRate('7.30')
      } else if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '0-9 hours'
      ) {
        setWeeklyRate('4.10')
      }
    }
  }), [getPrefetchDataForm2[EMPLOYMENT_STATUS].value, getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value]


  const Refs: ReactRef = {
    [PAY_DEDUCTION_PAYMENT_NUMBER]: useRef<HTMLInputElement>(null)
  }

  const getPayrollNumberRequire = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[PAYROLL_NUMBER_REQUIRED].value)

  useEffect(() => {
    setIsPayrollNumberRequired(getPayrollNumberRequire)
    if (getPayrollNumberRequire === 'true') {
      setPayrollNumberLableNotRequired('')
      setPayrollNumberLableRequired('Payroll number')
      setHideValidate(false)
    } else {
      setPayrollNumberLableRequired('')
      setPayrollNumberLableNotRequired('Payroll number')
      setHideValidate(true)
    }
  }, [getPayrollNumberRequire])

  useEffect(() => {
    electricalSignature && typeof electricalSignature === 'string' &&
      dispatchPayDeductionTabData(PAY_DEDUCTION_PAYMENT_ELOTRONIC_SIGNATURE, electricalSignature ? 'signed' : '', electricalSignature)
  }, [electricalSignature])

  useEffect(() => {
    setElectricalSignature(getPrefetchData?.value?.[PAY_DEDUCTION_PAYMENT_ELOTRONIC_SIGNATURE]?.value === 'true')
  }, [getPrefetchData?.value?.[PAY_DEDUCTION_PAYMENT_ELOTRONIC_SIGNATURE]?.value])

  useEffect(() => {
    isMobile && setPayrollNumberType('tel')
  }, [isMobile])

  const inputFieldFactory = (children: React.ReactNode) => {
    return (
      <InputFieldContainer>
        {children}
      </InputFieldContainer>)
  }

  const createInputField = (
    label: string,
    placeHolderParam: string,
    nameParam: string,
    maxLength?: number,
    labelNotRequire?: string,
    aRef?: React.RefObject<HTMLInputElement>,
    type?: string,
    hideValidate?: any): React.ReactNode => {
    return (
      <TextInput
        placeholder={placeHolderParam}
        name={nameParam}
        type={type}
        maxLength={maxLength}
        {...{ label, message: getMessages, labelNotRequire }}
        onInputValueCallback={(value, isValidated) => {
          dispatchPayDeductionTabData(nameParam, value, isValidated)
        }}
        ref={aRef}
        inputFromProps={checkFetchData(nameParam) ?
          getPrefetchData.value[nameParam]?.value : undefined}
        isValidated={getPrefetchData.value[nameParam]?.isValidated}
        hasValueChange={(isChange) => {
          setInputValueChange(isChange)
        }}
        hideValidate={hideValidate}
      />
    )
  }

  return (
    <Container>
      <PayrollNumberRequired>
        {inputFieldFactory(
          createInputField(payrollNumberLableRequired, "Type payroll number", PAY_DEDUCTION_PAYMENT_NUMBER,
            undefined, payrollNumberLableNotRequired, Refs[PAY_DEDUCTION_PAYMENT_NUMBER], payrollNumberType, isHideValidate)
        )}
      </PayrollNumberRequired>
      {expandableParagraphPayrollDescriptionTextProp && (
        <CheckboxField
          variant_2={false}
          longtext={true}
          longtext_2={false}
          title='Payroll deduction agreement'
          description={expandableParagraphPayrollDescriptionTextProp}
        />
      )
      }
      <ESignature
        terms={`By signing this agreement, you agree to the Terms and Conditions of the Payroll Deduction Request and Service Agreement`}
        onComplete={setElectricalSignature}
      />

      <SummarySection
        summaryTableDataProp={[{
          title: "Weekly debit amount:",
          value: `$${weeklyRate}`
        }]}
        noteOnPaymentProp={branchSpecific(getStateFromForm1, 'notesOnPaymentPayroll')}
        hasTermCheckbox={false}
      />

    </Container>
  )
}

export default PayDeductionTab
