import React, { useState, useEffect, forwardRef } from 'react'
import styled from 'styled-components'

import ValidationIndicator from '../ValidationIndicator'
import Label from '../Label'
import LabelIcon from '../Label/LabelIcon'
import Cleave from 'cleave.js/react'
import { isMobile } from 'react-device-detect'
require('cleave.js/dist/addons/cleave-phone.au.js')
import color from '../color-css/color'
import Input from '../TextInput/types/Input'
import { HoveringWrapper } from '../TextInput/styles/InputField'
import { getMessageByName } from '../dataProcessing'
import { PHONE_NUMBER, CARD_ACCOUNT_NUMBER, CARD_BSB, onSubmitStrategy }
  from '../TextInput/utils/submissionStrategy'
import { isValidPhoneNumber } from 'libphonenumber-js'
import Tooltip from '../Tooltip'

export type Props = {
  onInputSuccess?: (wasAnInputSuccess: boolean) => void,
  name: string,
  label?: string,
  placeholder?: string,
  isValidated?: boolean,
  inputFromProps?: string,
  handleData?: (value: string, isSuccess: boolean) => void,
  option: {},
  validatorMsgFromProps?: string,
}

const StyledCleave = styled(Cleave) <Input>`
  font-family: museo-sans, sans-serif;
  @media screen and (max-width: 690px) {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 0.9375rem;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15.563rem;
  height: 1.063rem;
  background-color: ${props => props.inputSuccessAsBackgroundColor === true ? color.blue : color.grey};
  transition: background 0.5s ease-in-out;
  border: 1px solid ${color.white};
  outline: none;
  color: ${props => props.inputSuccessAsBackgroundColor === true ? color.blue_17_percent : color.grey_29_percent};
  font-size: 1rem;
  padding: 0.8125rem 1.625rem 0.75rem 1.5625rem;
  border-radius: 0.625rem;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${color.grey_darker};
    font-family: museo-sans, sans-serif;
    font-size: 1rem;
  }
  &:focus {
    ::-webkit-input-placeholder { color:transparent; }
    ::-moz-placeholder { color:transparent; }
    border: 1px solid ${color.blue_darker};
  }
  ::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  &:hover {
    transition: border 0.5s ease-in-out;
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
    cursor: pointer;
  }
`

type Ref = HTMLInputElement

export const CleaveField = forwardRef<Ref, Props>((props, aRef) => {

  const { name, onInputSuccess, inputFromProps,
    isValidated, handleData, placeholder, label, option, validatorMsgFromProps } = props

  const [isSuccess, setIsSuccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [validatorMsg, setValidatorMsg] = useState("")
  const [labelIconState, setLabelIconStateState] = useState(true)
  const [value, setValue] = useState('');
  const [inputType, setInputTye] = useState('text')

  useEffect(() => {
    inputFromProps && setValue(inputFromProps)
    isValidated && setIsSuccess(isValidated)
    isValidated === true && setIsVisible(true)
  }, [inputFromProps, isValidated])

  useEffect(() => {
    onInputSuccess ? onInputSuccess(isSuccess) : {}
  }, [isSuccess])

  useEffect(() => {
    if (isVisible === true && isSuccess === false) setLabelIconStateState(false)
    else setLabelIconStateState(true)
  }, [isSuccess, isVisible])

  useEffect(() => {
    isMobile && setInputTye('tel')
  }, [isMobile])

  useEffect(() => {
    setValidatorMsg(validatorMsgFromProps ?? '')
  }, [validatorMsgFromProps])

  const onSubmit = (event: any) => {
    let dateValue: string = event.target.value
    const { name } = props
    switch (onSubmitStrategy(name)) {
      case PHONE_NUMBER:
        const rawDigits = dateValue?.replace(/\s+/g, '')
        const isValid = rawDigits.length >= 10 && isValidPhoneNumber(rawDigits, 'AU')
        handleData ? handleData(dateValue, isValid) : {}
        setIsSuccess(isValid)
        break;
      case CARD_BSB:
        handleData ? handleData(dateValue, dateValue.length === 9) : {}
        setIsSuccess(dateValue.length === 9)
        break;
      case CARD_ACCOUNT_NUMBER:
        handleData ? handleData(dateValue, dateValue.length === 9) : {}
        setIsSuccess(dateValue.length === 9)
        break;
    }
    setIsVisible(true)
  }

  return (
    <div>
      {label &&
        <Label>{label}
          <LabelIcon iconStates={labelIconState}>{'  '}*</LabelIcon>
        </Label>}
      <HoveringWrapper>
        <StyledCleave
          name={name}
          type={inputType}
          inputSuccessAsBackgroundColor={isSuccess}
          placeholder={placeholder}
          options={option}
          onBlur={event => {
            onSubmit(event)
          }}
          value={value}
        />
      </HoveringWrapper>

      <ValidationIndicator
        visibility={isVisible}
        isCorrect={isSuccess}
        message={!isSuccess ? validatorMsg : ''}
      />
    </div>
  )
})

export default CleaveField;
