import React from 'react'
import styled from 'styled-components'
import ItemMember from './ItemMember'
import data from '../../assets/data/membership.json'

export type Props = {
  containerWidth?: string
}

interface Container {
  containerWidth?: string
}

export const Container = styled.div<Container>`
    width: ${props => props.containerWidth ? props.containerWidth : '32.0625rem'};
    margin-top: 2.8rem;
    @media screen and (max-width: 1024px) {
      display: none;
  }
`
const StyledText = styled.div`
  text-align: left;
  font-weight: 700;
  font-size: 1.5rem;
  font-family: museo-sans, sans-serif;
  letter-spacing: -0.07px;
  color: #012F57;
  opacity: 1;
`
const ContainerContent = styled.div`
  background: var(--unnamed-color-f3f3f3) 0% 0% no-repeat padding-box;
  background: #F3F3F3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 3.25rem 3.75rem 3.1875rem 2.5rem;
  margin-top: 1.875rem;
  margin-bottom: 10rem;
`
const Membership: React.FC<Props> = ({ containerWidth }) => {
  return (
    <Container containerWidth={containerWidth}>
      <StyledText>
        {data.sectionTitle}
      </StyledText>
      <ContainerContent>
        {data.benefits.map((benefit, index) => {
          return (
            <ItemMember
              key={`benefit-item-${index}`}
              title={benefit.title}
              des={benefit.bodytext}
              first={index === 0}
            />
          )
        })}
      </ContainerContent>
    </Container>
  )
}

export default Membership
