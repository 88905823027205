import React from 'react'
import Truncate from 'react-truncate'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { ScrollBar } from '../ScrollBarComponent'
import color from '../color-css/color'
import parse from 'html-react-parser'
import useWindowDimensions from '../handleMobile'

import './index.css'

interface StyledDesProps {
  lines: any
  width: number
}
const StyledDes = styled(Truncate) <StyledDesProps>`
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  font-family: museo-sans, sans-serif;
  line-height: 1.75rem;
  letter-spacing: -0.05px;
  color: ${color.grey_29_percent};
  opacity: 1;
  line-height: 1.75rem;
  @media screen and (max-width: 690px) {
    font-size: 0.9375rem;
    line-height: 1.375rem;
  }
`
// the white-space below is the reason why we have this bug
//  https://atomixdesign.atlassian.net/browse/SDA-2544
// so please remove it when you merge the code
const StyledDoesNoExpand = styled.div`
  text-align: left;
  font-size: 1rem;
  font-weight: 500; 
  font-family: museo-sans, sans-serif;
  letter-spacing: -0.05px;
  color: ${color.grey_29_percent};
  opacity: 1;
  line-height: 1.75rem;
  // white-space: pre-wrap;
  @media screen and (max-width: 690px) {
    font-size: 0.9375rem;
    line-height: 1.375rem;
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${color.tooltip_icon};
  width: 12px !important;
  text-align: center;
  font-size: 12px;
  @media screen and (max-width: 690px) {
    width: 0.625rem !important;
  }
`
const StyledExpand = styled.a`
  text-align: right;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 500;
  font-family: museo-sans, sans-serif;
  letter-spacing: 0px;
  color: #012F57;
  opacity: 0.7;
  margin-right: 0.625rem;
  line-height: 1.875rem;
  @media screen and (max-width: 690px) {
    font-size: 0.875rem;
  }
`
const ExpandButton = styled.button`
  display: block;
  border: none;
  outline: none;
  background: transparent;    
  width: max-content;
  padding: 0;
  flex-direction: row;
  margin-top: 0.625rem;
  cursor: pointer;
  &:hover ${StyledIcon} {
    color: #00afd9;
  }
  &:hover ${StyledExpand} {
    opacity: 1;
  }
  &:focus ${StyledIcon} {
    color: #00afd9;
  }
  &:focus ${StyledExpand} {
    opacity: 1;
  }
`

const StyledGradient = styled.div`
  width: 100%;
  height: 4.7rem;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  background: transparent linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.996) 21%, rgba(255, 255, 255, 0.937) 48%, rgba(255, 255, 255, 0.647) 76%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  bottom: 1.5rem;
  left: 0;
  position: absolute;
  @media screen and (max-width: 690px) {
    height: 2.7rem;
  }
`
const StyledGradientEx = styled.div`
  width: 100%;
  height: 1.875rem;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.647) 20%, rgba(255, 255, 255, 0.937) 48% , rgba(255, 255, 255, 0.996) 76%, #FFFFFF 100%) no-repeat;
  background-size: 97%;
  opacity: 1;
  bottom: 1.5rem;
  left: 0;
  position: absolute
`
const ContainerScroll = styled.div`
  ${ScrollBar}
  div > span {
    font-size: 1rem;
    @media screen and (max-width: 690px) {
      font-size: 0.9375rem;
      line-height: 1.375rem;
    }
  }
`

export type Props = {
  longtext: boolean,
  text: String
}
export const ExpandableParagraph: React.FC<Props> = (props) => {
  const { children } = props
  const { height, width } = useWindowDimensions()

  const [expanded, changeExpanded] = React.useState(false)
  const [truncated, changeTruncated] = React.useState(false)
  const [widthTruncate, setWidthTruncate] = React.useState(width - 70)

  React.useEffect(() => {

    if (width < 1345 && width > 1099) {
      setWidthTruncate((width / 2) - 170)
    }
    else if (width < 1100 && width > 1024) {
      setWidthTruncate((width / 2) - 190)
    }
    else setWidthTruncate(width - 70)
  }, [width])

  const handleTruncate = (data: boolean) => {
    if (data !== truncated) {
      changeTruncated(truncated)
    }
  }

  const longtext = props.longtext && props.text.length >= 200

  if (longtext) {
    return (
      <div className='expandable-paragraph' style={{ position: 'relative' }}>
        {!expanded && (
          <StyledGradient />
        )}
        {expanded && (
          <StyledGradientEx style={{ display: 'none' }} />
        )}
        <StyledDes
          lines={!expanded && 5}
          width={widthTruncate > 580 ? 580 : widthTruncate}
          ellipsis={(
            <ExpandButton
              onClick={() => { changeExpanded(true) }}
            >
              <StyledExpand>
                Expand
              </StyledExpand>
              <StyledIcon icon={faPlus} />
            </ExpandButton>
          )}
          onTruncate={handleTruncate}
        >
          <div style={{ display: 'flex', height: '11.25rem', width: '100%' }}>
            <ContainerScroll style={{ flex: 1, minWidth: 0, minHeight: 0, overflowY: 'auto', overflowX: 'hidden' }}>
              <div style={{ marginRight: '1.25rem' }}>
                {
                  // This code preserves line breaks in text argument to react-truncate
                  props.text.split('\n').map((line, i, arr) => {
                    const newline = <span key={i}>{parse(line.toString())}</span>;
                    if (i === arr.length - 1) {
                      return newline;
                    } else {
                      return [newline, <br key={i + 'br'} />]
                    }
                  })
                }
              </div>
            </ContainerScroll>
          </div>
        </StyledDes>
        {!truncated && expanded && (
          <ExpandButton
            onClick={() => { changeExpanded(false) }}
          >
            <StyledExpand>
              Minimise
            </StyledExpand>
            <StyledIcon icon={faMinus} />
          </ExpandButton>
        )}
      </div>
    )
  } else {
    return (
      <div className='expandable-paragraph' style={{ position: 'relative' }}>
        <StyledDoesNoExpand>
          {parse(props.text.toString())}
        </StyledDoesNoExpand>
      </div>
    )
  }
}

export default ExpandableParagraph