import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, store } from 'store'
import { uuid } from '../../../utils/submitData'
import { STATE_FROM_SUBURB } from '../../FormHighOrder/types/YourWorkField'
import { Spinner } from '../../AddressAutosuggest/SearchLocationInput'
import styled from 'styled-components';
import {
    CREDITCARD_PAYWAY_TOKEN
} from '../../FormHighOrder/types/YourContributionField'
import {
    writeForm4CreditCardTabData
} from '../../FormHighOrder/features/index'
import { CreditCardPaymentForm } from '../'
import InputFieldContainer from '../../Commons/InputFieldContainer'
import TextInput from '../../TextInput/index'
import { getMessages } from '../../dataProcessing'
import ReactRef from 'components/FormHighOrder/types/ReactRef'

declare global {
    interface Window {
        payway: any,
    }
}

const StyledLoadingDiv = styled.div`
    text-align: center;
    margin: auto;
    position: relative;

    & > p {
        padding-top: 8rem;
    }
`

const iFrameCSSObject = {
    ".payway-card": {
        "background": "#fff",
        "border": "none",
        "transform": "translateX(-2.4em)"
    },
    ".payway-expiration": {
        "bottom": "-0.8em !important",
        "left": "1.5em",
        "right": "auto"
    },
    ".payway-creditcard-expirationseparator": {
        "display": "none !important"
    },
    "input": {
        "height": "1.75rem",
        "background-color": "#F3F3F3",
        "transition": "background 0.5s ease-in-out",
        "border": "1px solid #FFF",
        "outline": "none",
        "color": "#4A4A4A !important",
        "font-size": "1rem",
        "padding": "0.8125rem 1.625rem 0.75rem 1.5625rem !important",
        "border-radius": "0.625rem",
        "overflow": "visible",
        "margin": "0",
        "max-width": "100%",
    },
    "select": {
        "height": "1.75rem",
        "transition": "background 0.5s ease-in-out",
        "border": "1px solid #FFF",
        "outline": "none",
        "color": "#4A4A4A !important",
        "font-size": "1rem",
        "padding": "0.8125rem 1.625rem 0.75rem 1.5625rem !important",
        "border-radius": "0.625rem",
        "overflow": "visible",
        "margin": "0",
        "max-width": "100%",
    },
    ".payway-year": {
        "right": "-7.9rem !important"
    },
    ".payway-card label.payway-name": {
        "bottom": "3.75em !important"
    },
    "label": {
        "font-weight": "500 !important",
        "line-height": "1.75rem !important",
        "font-size": "1.125rem !important",
        "text-align": "left",
        "letter-spacing": "-0.05px",
        "color": "#052948 !important",
    },
    "legend": {
        "font-weight": "500 !important",
        "line-height": "1.75rem !important",
        "font-size": "1.125rem !important",
        "text-align": "left",
        "letter-spacing": "-0.05px",
        "color": "#052948 !important",
    },
    ".payway-type-read-only": {
        "display": "none !important"
    },
    "div.payway-creditcard-testfacility": {
        "top": "0.5rem !important"
    }
}

const StyledTestCards = styled.div`
    background: #f3f3f3;
    border-radius: 10px;
    padding: 1.5rem;
    margin-top: 1.5rem;
`

const testMode = Boolean(process.env.GATSBY_TEST_MODE) ?? true

let frameobject: any = undefined

const PayWayIframe: React.FC = ({}) => {

    const dispatch = useDispatch()

    const paywayPublishableKey = process.env.GATSBY_PAYWAY_PUBLISHABLE_KEY
    if (typeof paywayPublishableKey === 'undefined') {
        throw "PayWay credentials not set, cannot connect to payment processor"
    }

    const [hasLoadedSDK, setHasLoadedSDK] = useState(false)
    const [paywayError, setPaywayError] = useState(false)

    const dispatchCreditCardData = (nameParams: string, data: string | undefined, dataValidated: boolean) => {
        dispatch(writeForm4CreditCardTabData({
            key: nameParams,
            value: data ? data : "",
            isValidated: dataValidated
        }
        ))
    }

    useEffect(() => {
        // Load in the PayWay SDK
        const scriptId = 'payway'
        const scriptExists = document.getElementById(scriptId)

        const insertScript = () => {
            const script = document.createElement('script')
            script.src = "https://api.payway.com.au/rest/v1/payway.js"
            script.id = scriptId
            script.addEventListener('load', () => {
                setHasLoadedSDK(true)
            })
            document.body.appendChild(script)
        }

        if (!scriptExists) {
            insertScript()
        } else {
            setHasLoadedSDK(true)
        }
    }, [])

    const iframeLoadCallback = (err: any, frame: any) => {
        if (!err) {
            frameobject = frame
        } else {
            console.log(err)
            setPaywayError(true)
        }
    }

    const onValid = (event: any) => {
        frameobject.getToken((err: any, data: any) => {
            if (err) {
                console.log(`Error getting token: ${err.message}`)
            } else {
                dispatchCreditCardData(CREDITCARD_PAYWAY_TOKEN, data.singleUseTokenId, true)
            }
        })
    }

    const onInvalid = (event: any) => {
        dispatchCreditCardData(CREDITCARD_PAYWAY_TOKEN, undefined, false)
    }

    useEffect(() => {
        if (hasLoadedSDK) {
            if (typeof window.payway !== 'undefined') {
                window.payway.createCreditCardFrame({
                    publishableApiKey: paywayPublishableKey,
                    cvnRequired: false,
                    style: iFrameCSSObject,
                    height: 300,
                    width: 400,
                    tokenMode: 'callback',
                    onValid: onValid,
                    onInvalid: onInvalid,
                }, iframeLoadCallback)
            }
        }
    }, [hasLoadedSDK])

    if (paywayError) {
        return (
            <CreditCardPaymentForm>
            <StyledLoadingDiv>
                <p>Payment method is temporarily unavailable, please select a different payment method or contact the SDA directly</p>
            </StyledLoadingDiv>
            </CreditCardPaymentForm>
        )
    }

    return (
        <CreditCardPaymentForm>
            {!hasLoadedSDK && <StyledLoadingDiv>
                <Spinner isShow={true} />
                <p>Loading payment form</p>
            </StyledLoadingDiv>}
            {hasLoadedSDK && <div id="payway-credit-card" style={{
                marginBottom: "-1.45rem"
            }} />}
            {testMode && <StyledTestCards>
                <p>PayWay Test Cards:</p>
                <p>card #: 4564710000000004	csv: 847 expiry: 02/29 - Visa Approved</p>
                <p>card #: 5163200000000008	csv: 070 expiry: 08/30 - Mastercard Approved</p>
            </StyledTestCards>
            }
        </CreditCardPaymentForm>
    )
}

export default PayWayIframe
