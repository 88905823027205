import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import ExpandableParagraph from '../ExpandableParagraph/'
import Tooltip from '../Tooltip'
import ValidationIndicator from '../ValidationIndicator'
import color from '../color-css/color'

export type Props = {
  variant_2?: boolean
  description?: string
  description_2?: string
  longtext: boolean
  longtext_2?: boolean
  widthSize?: string
  padding?: string
  title?: string
  title_2?: string
  tooltip?: boolean
  validateMessage?: string
  checkboxContent?: string
  onCheckedValue?: (isChecked: boolean) => void
  inputFromProps?: boolean
}

interface ContainerCheckboxProps {
  isCheck?: boolean
  check: boolean
  widthSize?: string
  padding?: string
}

const ContainerCheckbox = styled.div<ContainerCheckboxProps>`
  width: ${props => props.widthSize ? props.widthSize : '8.3125rem'};
  background:${props => props.check ? '#E2F1F8' : '#EFF0F0'} ;
  border-radius: 10px;
  transition: background 0.5s ease-in-out;
  margin-top: 2.5rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: row;
  padding: ${props => props.padding ? props.padding : '0.875rem 9.1875rem 0.875rem 1.25rem'};
  border: 1px solid ${color.white};
  cursor: pointer;
  outline: none;
  @media screen and (max-width: 576px) {
    width: 100%;
    padding: 0.65625rem 0 0.65625rem 0;
  }
`
const ContainerCheckboxHover = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  &:hover ${ContainerCheckbox} {
    transition: border 0.5s ease-in-out;
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
  }
  &:focus ${ContainerCheckbox} {
    transition: border 0.5s ease-in-out;
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`

const StyledIcon = styled(({ isCheck, check, ...rest }) => <FontAwesomeIcon {...rest} />) <ContainerCheckboxProps>`
  font-size: 1.25rem;
  margin-top: 0.1875rem;
  width: 1.25rem !important;
  animation-name: flickity;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  @keyframes flickity {
      0% {
          opacity:0;
      }
      100% {
          opacity:1;
      }
  };

  display: ${props => props.isCheck ? 'block' : 'none'};
  color: ${props => props.check ? '#ff6820' : '#757575'};
  content: '\f0c8';
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    width: 1 !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    width: 0.875rem !important;
  }
`

const StyledTitle = styled.span<ContainerCheckboxProps>`
  text-align: left;
  font: 500 1rem museo-sans, sans-serif;
  letter-spacing: -0.05px;
  line-height: 1.625rem;
  color: ${props => props.check ? color.blue_17_percent : '#757575'};
  opacity: 1;
  margin-left: 1.125rem;
  @media screen and (max-width: 768px) {
    font-size: 0.9375rem;
    line-height: 1.375rem;
    margin-left: 0.875rem;
  }
`
const Container = styled.div`
  flex: 1;
  max-width: 38.75rem;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`
const StyledTitleMain = styled.div`
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: museo-sans, sans-serif;
  letter-spacing: -0.05px;
  color: #012f57;
  opacity: 1;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 768px) {
    font-size: 1.0625rem;
    line-height: 1.75rem;
  }
`
const ContainerIcon = styled.div`
  display: inline-block;
  @media screen and (max-width: 768px) {
    margin-left: 0.9375rem;
  }
`

export const CheckboxField: React.FC<Props> = (props: any) => {
  const { title, title_2, tooltip, widthSize, padding,
    validateMessage, checkboxContent,
    onCheckedValue, inputFromProps
  } = props

  const [check, changeCheck] = useState(inputFromProps ? true : false)

  React.useEffect(() => {
    onCheckedValue ? onCheckedValue(check) : {}
  }), [check]

  return (
    <Container className='checkbox'>
      <div>
        <StyledTitleMain>
          {title}
          {' '}
          <span style={{ color: '#00AFD9', fontSize: '1.25rem' }}>*</span>
        </StyledTitleMain>
        <ExpandableParagraph longtext={props.longtext} text={props.description} />
      </div>
      {props.variant_2 === true && props?.description_2 ? (
        <div style={{ marginTop: '2.125rem' }}>
          <StyledTitleMain>
            {title_2}
            {' '}
            <span style={{ color: '#00AFD9', fontSize: '1.25rem' }}>*</span>
          </StyledTitleMain>
          <ExpandableParagraph longtext={props.longtext_2} text={props.description_2} />
        </div>
      ) : <></>
      }
      { checkboxContent &&
        <ContainerCheckboxHover
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              const checked = !check
              changeCheck(checked)
              onCheckedValue ? onCheckedValue(checked) : {}
            }
          }}
          onClick={(event) => {
            const checked = !check
            changeCheck(checked)
            onCheckedValue ? onCheckedValue(checked) : {}
          }}>
          <ContainerCheckbox
            widthSize={widthSize}
            padding={padding}
            check={check}
            onClick={(event) => {
              const checked = !check
              changeCheck(checked)
              onCheckedValue ? onCheckedValue(checked) : {}
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                const checked = !check
                changeCheck(checked)
                onCheckedValue ? onCheckedValue(checked) : {}
              }
            }}
          >
            <ContainerIcon>
              <StyledIcon
                isCheck={check ? true : false}
                check={check}
                icon={faCheck}
              />
              <StyledIcon
                isCheck={check ? false : true}
                check={check}
                icon={faSquare}
              />
            </ContainerIcon>
            <StyledTitle check={check}>
              {checkboxContent}
            </StyledTitle>
          </ContainerCheckbox>
        </ContainerCheckboxHover>
      }
      {tooltip === true && (
        <Tooltip
          visibility={check}
          widthSize="26.125rem"
          tooltipName="preExistingIssues"
          tooltipTitle="What does this mean?"
          tooltip_message="For example, if you acquired an injury at work
        <span style='font-weight: bold; font-style: italic'>before</span> signing up with the SDA, we aren’t able to provide specific
        assistance with that issue beyond general advice. However, if you acquired
        an injury at work after signing up with the SDA, we’re here to help in any way that we can. "
        />
      )}
      {validateMessage && (
        <ValidationIndicator
          isCorrect={check}
          message={validateMessage}
          visibility={check} />
      )}
    </Container>
  )
}
export default CheckboxField
