import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/'
import { goTo, backTo } from '../components/FormHighOrder/features/index'
import { animateScroll as scroll } from 'react-scroll'
import color from '../components/color-css/color'
import { isMobileOnly, isTablet } from 'react-device-detect'
import FormStep1YourWork from '../components/FormStep1YourWork'

import FormStepIndicator from '../components/FormStep'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FormHighOrder from '../components/FormHighOrder'
import { logingObject } from '../components/logging'
import Membership from '../components/Membership'
import FormStep2AboutYou from '../components/FormStep2AboutYou'
import FormStep4YourContribution from '../components/FormStep4YourContribution'
import FormStep3MembershipAgreement from '../components/FormStep3MembershipAgreement'
import { FormPage } from '../components/FormHighOrder/types/FormPage'

import pageTransition from '../utils/pageTransition'

const SkipContent = styled.a`
  position: absolute;
  top: -30.3125rem;
  right: 0;
  left: 0;
  z-index: 99999;
  display: block;
  padding: .6em;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background: #00205b;
  box-shadow: inset 0 -5px 15px 0 rgba(0,0,0,.2);
  transform-origin: top;
  transform: scaleY(0);
  transition: transform .2s cubic-bezier(.4,0,.2,1),-webkit-transform .2s cubic-bezier(.4,0,.2,1),-o-transform .2s cubic-bezier(.4,0,.2,1);
  :focus {
    color: #fff;
    -webkit-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
    outline: none;
  }
`


const FormStepIndicatorPositioningDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 5.9375rem;
  border-radius: 0.625rem;
  box-shadow: 0 1.25rem 3.125rem rgba(1,47,87,0.1);
  -ms-box-shadow: 0px 1.25rem 3.125rem rgba(1,47,87,0.1);
  box-sizing: border-box;
  background-color: ${color.white};
  @media screen and (max-width: 870px) {
    height: 2.8125rem;
    width: 10.8125rem;
    box-shadow: 0 1.25rem 3.125rem rgba(1,47,87,0.1);
    box-sizing: border-box;
  }
`
const ContainerFormStep = styled.div`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 21.5625rem;
  align-items:'center';
  width: 52.3125rem;
  top: -10.5rem;
  transform: translateY(-50%);
  @media screen and (max-width: 1024px) {
    top: -8.5rem;
  }
  @media screen and (max-width: 870px) {
    width: 10.8125rem;
    top: -3.9375rem;
  }
`
const ContainerMain = styled.div`
  max-width: 142.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5.75rem;
  margin-top: 10.5rem;
  min-height: 52rem;
  @media screen and (min-width: 1440px) {
    max-width: 78.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 1024px) {
    margin-top: 8.5rem;
  }
  @media screen and (max-width: 870px) {
    padding: 0.9375rem;
    margin-top: 3.9375rem;
  }
`

const SpacingBetweenFormAndMemberShip = styled.div`
  flex: 1 0 0.625rem;
  @media screen and (min-width: 1024px) {
    min-width: 5.5rem;
  };
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const IndexPage = () => {
  const dispatch = useDispatch()

  const formPageDataset: FormPage[] = useSelector((state: RootState) => state.FormHighOrder.pageData)

  const onPage = useSelector((state: RootState) => state.FormHighOrder.onPage)
  const [isSkipToContentPressed, setIsSkipToContentPressed] = useState(false)

  const scrollToTop = () => {
    if (isMobileOnly) scroll.scrollTo(100)
    else if (isTablet) scroll.scrollTo(100)
    else scroll.scrollTo(250)
  }

  const scrollToFocus = () => {
    scroll.scrollTo(300)
    setIsSkipToContentPressed(true)
  }

  useEffect(() => {
    logingObject(formPageDataset[0].data.formData)
  }, [formPageDataset])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      pageTransition(onPage)
    }
  }, [onPage])

  const noIndex = process.env.GATSBY_NO_INDEX === 'true'

  return (
    <Layout
      title="Join the SDA"
      titleSpan="today"
    >
      <SEO
        description={'Join the SDA for better wages & conditions, legal advice, protection & support in retail, fast food & warehousing industries.'}
        lang={'en'}
        noindex={noIndex}
        title={'SDA Union | Join the SDA'}
        meta={[{
          name: 'SDA Union | Join the SDA',
          content: 'Join the SDA for better wages & conditions, legal advice, protection & support in retail, fast food & warehousing industries.'
        }]}
      />
      {
        onPage === 0 && (
          <SkipContent
            onClick={scrollToFocus}
            onKeyPress={scrollToFocus}
            className="skip-to-content-link"
            href="#main"
          >
            Skip to content
          </SkipContent>
        )
      }

      <FormHighOrder >
        <ContainerFormStep>
          <FormStepIndicatorPositioningDiv>
            <FormStepIndicator
              onItemStepClickedListenerProp={(itemNumber) => {
                dispatch(backTo(itemNumber))
              }}
              isFormComplete={false}
            />
          </FormStepIndicatorPositioningDiv>
        </ContainerFormStep>
        <ContainerMain>
          {
            onPage === 0 && (
              <FormStep1YourWork
                isSkipToContentPressed={isSkipToContentPressed}
                onClickOnContinueButtonProp={() => {
                  dispatch(goTo(1))
                  scrollToTop()
                }}
              />
            )
          }

          {
            onPage === 1 && (
              <FormStep2AboutYou
                onClickOnContinueButtonProp={() => {
                  dispatch(goTo(2))
                  scrollToTop()
                }}
              />
            )
          }
          {
            onPage === 2 && (
              <FormStep3MembershipAgreement
                onClickOnContinueButtonProp={() => {
                  dispatch(goTo(3))
                  scrollToTop()
                }}
              />
            )
          }
          {
            onPage === 3 &&
            (
              <FormStep4YourContribution />
            )
          }
          <SpacingBetweenFormAndMemberShip></SpacingBetweenFormAndMemberShip>
          <Membership />
        </ContainerMain>

      </FormHighOrder>

    </Layout>

  )
}
export default IndexPage
