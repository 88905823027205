export const checkAgeUnder18 = (input: string) => {
    let check = false;
    const unformatDate = input.split('/');
    let day = parseInt(unformatDate[0], 10);
    let month = parseInt(unformatDate[1], 10);
    let year = parseInt(unformatDate[2], 10);
    let today = new Date();
    let nowyear = parseInt(today.getFullYear().toString().substr(-2));
    let nowmonth = today.getMonth() + 1;
    let nowday = today.getDate();
    let age;
    if (year > nowyear) {
        age = 100 + (nowyear - year);
    } else {
        age = nowyear - year
    }
    let age_month = nowmonth - month;
    let age_day = nowday - day;
    if(age === 18 && age_month < 0) return check
    else if(age === 18 && age_month === 0 && age_day < 0) return check
    else if(age === 18 && age_month === 0 && age_day >= 0) return check = true
    else if(age === 18 && age_month > 0) return check = true
    else if (age > 18) return check = true
    else return check
}