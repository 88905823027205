import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { animateScroll as scroll } from 'react-scroll'
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect'

import { getMessages } from '../dataProcessing'
import TextInput from '../TextInput/index'
import AddressAutosuggest from '../AddressAutosuggest/SearchLocationInput'
import DateInput from '../DateInput/index'
import SelectDropdown from '../SelectDropdown/index'
import ProgressButton, { ButtonStates } from '../ProgressButton'
import gender from '../../assets/data/gender.json'
import state from '../../assets/data/state.json'
import employmentStatus from '../../assets/data/employmentStatus.json'
import H3 from '../H3'
import ReactRef from 'components/FormHighOrder/types/ReactRef'
import ProgressButtonPositioningDiv from '../ProgressButton/ProgressButtonPositioningDiv'
import InputFieldContainer from '../Commons/InputFieldContainer'
import NewcastleUserCondition from '../NewcastleUserCondition/index'
import { handlePageSubmission } from '../../utils/submitData'

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/'
import {
  writeAboutYouData, initialState, writeNextOfKinData,
  writeForm2ForNewcastleValidated, writeValidateData, setUnsuccessfullyPosted
} from '../FormHighOrder/features/index'

import {
  ADDRESS, UNIT_NUMBER, CITY, STATE_OR_TERITORY, POSTCODE, GENDER, EMPLOYMENT_STATUS, DATE_OF_BIRTH,
  EMPLOYMENT_STATUS_CASUAL, YOUR_NEXT_OF_KIN_DATA
} from '../FormHighOrder/types/AboutYouField'

import { STATE_FROM_SUBURB } from '../FormHighOrder/types/YourWorkField'

export type Props = {
  onClickOnContinueButtonProp: () => void
  onHandleFormValidated?: (isValidate: boolean) => void
}

const FormStep2Container = styled.div`
  width: 40.9375rem;
  position: relative;
  animation-name: flickity;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  @keyframes flickity {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  };
  @media (max-width: 1024px) {
    margin:auto;
  };
  @media (max-width: 690px) {
    display: block;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  };
`

const FormStep2Form = styled.form`
  max-width: 40.9375rem;
  @media (max-width: 690px) {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  margin-bottom: 2.1875rem;
`

const FormStep2FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 690px) {
    display: block;
  };
`

const Wrapped = styled.div`
  width: 40.9375rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 2.1875rem;
  margin-bottom: 2.1875rem;
  @media (max-width: 690px) {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 2.1875rem;
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
`

const StateWrapper = styled.div`
  margin-top: 2.1875rem;
  @media (max-width: 690px) {
    margin-top: 2.1875rem;
  }
`

const FormStep2AboutYou: React.FC<Props> = (props: any) => {
  const { onClickOnContinueButtonProp, onHandleFormValidated } = props

  const [addressValidation, setAddressValidation] = useState(false)
  const [unitNumberValidation, setUnitNumberValidation] = useState(false)
  const [cityValidation, setCityValidation] = useState(false)
  const [stateOrTerritoryValidation, setStateOrTerritoryValidation] = useState(false)
  const [postcodeValidation, setPostcodeValidation] = useState(false)
  const [dateOfBirthValidation, setDateOfBirthValidation] = useState(false)
  const [genderValidation, setGenderValidation] = useState(false)
  const [employmentStatusValidation, setEmploymentStatusValidation] = useState(false)
  const [onAverageValidation, setAverageValidation] = useState(false)
  const [employmentStatusValue, setEmploymentStatusValue] = useState('')
  const [genderValue, setGenderStatusValue] = useState('')
  const [averageData, setAverageData] = useState<any>(undefined)
  const [cityValue, setCityValue] = useState('')
  const [stateOrTerritoryValue, setStateOrTerritoryValue] = useState('')
  const [postcodeValue, setPostcodeValue] = useState('')
  const [hasInputValueChange, setInputValueChange] = useState(false)

  const [nextOfKinValidation, setNextOfKinValidation] = useState(false)
  const [nextOfKinData, setNextOfKinData] = useState<any>(undefined)
  const [isAddressScrolled, setIsAddressScrolled] = useState(false)

  const dispatch = useDispatch()

  const scrollToAddressField = () => {
    if (isMobileOnly && isAddressScrolled === false) {
      setIsAddressScrolled(true)
      scroll.scrollTo(260);
    }
    else if (isTablet && isAddressScrolled === false) {
      setIsAddressScrolled(true)
      scroll.scrollTo(280);
    }
    else if (isAddressScrolled === false) {
      setIsAddressScrolled(true)
      scroll.scrollTo(400);
    }
  };

  const [proceedingButtonState, setProceedingButtonState] = useState(ButtonStates.disabled)

  const Refs: ReactRef = {
    [ADDRESS]: useRef<HTMLInputElement>(null),
    [UNIT_NUMBER]: useRef<HTMLInputElement>(null),
    [CITY]: useRef<HTMLInputElement>(null),
    [STATE_OR_TERITORY]: useRef<HTMLInputElement>(null),
    [POSTCODE]: useRef<HTMLInputElement>(null),
    [GENDER]: useRef<HTMLInputElement>(null),
    [EMPLOYMENT_STATUS]: useRef<HTMLInputElement>(null),
    [DATE_OF_BIRTH]: useRef<HTMLInputElement>(null),
  }

  const getValidate =
    useSelector((state: RootState) => {
      const { data } = state.FormHighOrder.pageData[1]
      if (employmentStatusValue?.toLowerCase() === 'casual' || employmentStatusValue?.toLowerCase() === 'part time') {
        return data[ADDRESS].isValidated
          && data[CITY].isValidated
          && data[STATE_OR_TERITORY].isValidated
          && data[POSTCODE].isValidated
          && data[GENDER].isValidated
          && data[EMPLOYMENT_STATUS].isValidated
          && data[EMPLOYMENT_STATUS_CASUAL].isValidated
          && data[DATE_OF_BIRTH].isValidated
      } else {
        return data[ADDRESS].isValidated
          && data[CITY].isValidated
          && data[STATE_OR_TERITORY].isValidated
          && data[POSTCODE].isValidated
          && data[GENDER].isValidated
          && data[EMPLOYMENT_STATUS].isValidated
          && data[DATE_OF_BIRTH].isValidated
      }
    })

  const getPrefetchData =
    useSelector((state: RootState) => state.FormHighOrder.pageData[1].data)

  const getStateFromForm1 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

  useEffect(() => {
    addressValidation           && setValidationField(ADDRESS, getPrefetchData[ADDRESS].isValidated)
    cityValidation              && setValidationField(CITY, getPrefetchData[CITY].isValidated)
    stateOrTerritoryValidation  && setValidationField(STATE_OR_TERITORY, getPrefetchData[STATE_OR_TERITORY].isValidated)
    postcodeValidation          && setValidationField(POSTCODE, getPrefetchData[POSTCODE].isValidated)
    genderValidation            && setValidationField(GENDER, getPrefetchData[GENDER].isValidated)
    employmentStatusValidation  && setValidationField(EMPLOYMENT_STATUS, getPrefetchData[EMPLOYMENT_STATUS].isValidated)
    dateOfBirthValidation       && setValidationField(DATE_OF_BIRTH, getPrefetchData[DATE_OF_BIRTH].isValidated)
    unitNumberValidation        && setValidationField(UNIT_NUMBER, getPrefetchData[UNIT_NUMBER].isValidated)

    if (getStateFromForm1 === 'Newcastle') {
      if (employmentStatusValue?.toLowerCase() === 'casual' || employmentStatusValue?.toLowerCase() === 'part time') {
        getValidate && (addressValidation && cityValidation && stateOrTerritoryValidation
          && postcodeValidation && genderValidation
          && dateOfBirthValidation && onAverageValidation && nextOfKinValidation) ?
          setProceedingButtonState(ButtonStates.enabled) :
          setProceedingButtonState(ButtonStates.disabled)
      } else {
        getValidate && (addressValidation && cityValidation && stateOrTerritoryValidation
          && postcodeValidation && genderValidation && employmentStatusValidation
          && dateOfBirthValidation && nextOfKinValidation) ?
          setProceedingButtonState(ButtonStates.enabled) :
          setProceedingButtonState(ButtonStates.disabled)
      }
    }
    else {
      if (employmentStatusValue?.toLowerCase() === 'casual' || employmentStatusValue?.toLowerCase() === 'part time') {
        getValidate && (addressValidation && cityValidation && stateOrTerritoryValidation
          && postcodeValidation && genderValidation
          && dateOfBirthValidation && onAverageValidation) ?
          setProceedingButtonState(ButtonStates.enabled) :
          setProceedingButtonState(ButtonStates.disabled)
      } else {
        getValidate && (addressValidation && cityValidation && stateOrTerritoryValidation
          && postcodeValidation && genderValidation && employmentStatusValidation
          && dateOfBirthValidation) ?
          setProceedingButtonState(ButtonStates.enabled) :
          setProceedingButtonState(ButtonStates.disabled)
      }
    }
  }, [getValidate, addressValidation,
    cityValidation, stateOrTerritoryValidation,
    postcodeValidation, genderValidation,
    employmentStatusValidation, dateOfBirthValidation,
    unitNumberValidation, onAverageValidation, getStateFromForm1,
    nextOfKinValidation, employmentStatusValue
  ])

  useEffect(() => {
    const cond = proceedingButtonState !== "disabled"
    setPageValidation(1, cond)
    onHandleFormValidated ? onHandleFormValidated(cond) : {}
  }, [proceedingButtonState])

  useEffect(() => {
    if (cityValue !== ''
      && stateOrTerritoryValue !== ''
      && postcodeValue !== ''
      && getPrefetchData[STATE_OR_TERITORY].value === undefined
      && getPrefetchData[CITY].value === undefined
      && getPrefetchData[POSTCODE].value === undefined
    ) {
      setField(CITY, cityValue)
      setField(STATE_OR_TERITORY, stateOrTerritoryValue)
      setField(POSTCODE, postcodeValue)
      setCityValidation(true)
      setStateOrTerritoryValidation(true)
      setPostcodeValidation(true)
    }
    if (hasInputValueChange === false) {
      setField(ADDRESS, Refs[ADDRESS].current?.value, addressValidation)
    }
    if (postcodeValidation === true && postcodeValue === '') {
      setField(POSTCODE, Refs[POSTCODE].current?.value)
    }
    if (postcodeValue !== '' && postcodeValue !== getPrefetchData[POSTCODE].value
      && (getPrefetchData[POSTCODE].value === undefined || getPrefetchData[POSTCODE].value !== undefined)) {
      setField(POSTCODE, postcodeValue)
    }
    if (cityValidation === true && cityValue === '') {
      setField(CITY, Refs[CITY].current?.value)
    }
    if (cityValue !== '' && cityValue !== getPrefetchData[CITY].value
      && (getPrefetchData[CITY].value === undefined || getPrefetchData[CITY].value !== undefined)) {
      setField(CITY, cityValue)
    }
    if (stateOrTerritoryValidation === true && stateOrTerritoryValue === '') {
      setField(STATE_OR_TERITORY, Refs[STATE_OR_TERITORY].current?.value)
    }
    if (stateOrTerritoryValue !== '' && stateOrTerritoryValue !== getPrefetchData[STATE_OR_TERITORY].value
      && (getPrefetchData[STATE_OR_TERITORY].value === undefined || getPrefetchData[STATE_OR_TERITORY].value !== undefined)) {
      setField(STATE_OR_TERITORY, stateOrTerritoryValue)
    }
    if (genderValidation === true && genderValue !== '') {
      setField(GENDER, genderValue)
    }
    if (employmentStatusValidation === true && employmentStatusValue !== '') {
      setField(EMPLOYMENT_STATUS, employmentStatusValue)
    }
    // if (dateOfBirthValidation === true && hasInputValueChange === false) {
    //   setField(DATE_OF_BIRTH, Refs[DATE_OF_BIRTH].current?.value)
    // }
    if (unitNumberValidation === true) {
      setField(UNIT_NUMBER, Refs[UNIT_NUMBER].current?.value)
    }
  }, [cityValue, stateOrTerritoryValue, averageData, addressValidation,
    postcodeValidation, postcodeValue, genderValidation, employmentStatusValidation,
    unitNumberValidation, genderValue, employmentStatusValue,
    hasInputValueChange
  ])

  useEffect(() => {
    if (nextOfKinValidation !== false) {
      dispatchNextOfKinValidated(YOUR_NEXT_OF_KIN_DATA)
    }

    if (nextOfKinData !== undefined && nextOfKinValidation === true) {
      const valueData = initialState.pageData[1].data[YOUR_NEXT_OF_KIN_DATA].value
      for (let nameParams in valueData) {
        dispatchNextOfKinData(nameParams, nextOfKinData[nameParams].value)
      }
    }
  }, [nextOfKinValidation, nextOfKinData])

  useEffect(() => {
    if ((employmentStatusValue === 'Full Time' || employmentStatusValue === 'Salaried')
      && getPrefetchData[EMPLOYMENT_STATUS_CASUAL].isValidated === true) {
      resetField(EMPLOYMENT_STATUS_CASUAL)
    }
  }, [employmentStatusValue, getPrefetchData[EMPLOYMENT_STATUS_CASUAL].isValidated])

  const dispatchNextOfKinData = (nameParams: string, data: string) => {
    dispatch(writeNextOfKinData({
      key: nameParams,
      value: data ? data : "",
      isValidated: true
    }
    ))
  }

  const dispatchNextOfKinValidated = (nameParams: string, data?: string) => {
    dispatch(writeForm2ForNewcastleValidated({
      key: nameParams,
      value: data ? data : "",
      isValidated: nextOfKinValidation
    }
    ))
  }

  const setValidationField = (nameParam: string, wasAnInputSuccess: boolean) => {
    if (nameParam!==ADDRESS && !wasAnInputSuccess) {
      dispatch(setUnsuccessfullyPosted(1))
      dispatch(setUnsuccessfullyPosted(2))
    }

    dispatch(writeAboutYouData({
      key: nameParam,
      value: checkFetchData(nameParam) ? getPrefetchData[nameParam]?.value : undefined,
      isValidated: wasAnInputSuccess
    }
    ))
  }

  const setField = (nameParam: string, inputValue?: string, isValidated = true) => {
    dispatch(writeAboutYouData({
      key: nameParam,
      value: inputValue ? inputValue : "",
      isValidated,
    }
    ))
  }
  const resetField = (nameParam: string) => {
    dispatch(writeAboutYouData({
      key: nameParam,
      value: "",
      isValidated: false
    }
    ))
  }

  const checkFetchData = (nameParam: string) => {
    return getPrefetchData !== undefined && getPrefetchData[nameParam]?.value !== ""
  }

  const setValidation = (nameParam: string, wasAnInputSuccess: boolean) => {
    switch (nameParam) {
      case ADDRESS:
        setAddressValidation(wasAnInputSuccess)
        return;
      case UNIT_NUMBER:
        setUnitNumberValidation(wasAnInputSuccess)
        return;
      case CITY:
        setCityValidation(wasAnInputSuccess)
        return;
      case STATE_OR_TERITORY:
        setStateOrTerritoryValidation(wasAnInputSuccess)
        return;
      case POSTCODE:
        setPostcodeValidation(wasAnInputSuccess)
        return;
      case GENDER:
        setGenderValidation(wasAnInputSuccess)
        return;
      case EMPLOYMENT_STATUS:
        setGenderValidation(wasAnInputSuccess)
        return;
      case DATE_OF_BIRTH:
        setDateOfBirthValidation(wasAnInputSuccess)
        return;
    }
  }

  const setPageValidation = (pageNum: number, isValid: boolean) => {
    dispatch(writeValidateData({
      pageId: pageNum,
      isValidated: isValid,
      data: undefined
    }))
  }

  const inputFieldFactory = (children: React.ReactNode) => {
    return (
      <InputFieldContainer>
        {children}
      </InputFieldContainer>)
  }

  const createInputField = (
    label: string,
    placeHolderParam: string,
    nameParam: string,
    maxLength?: number,
    labelNotRequire?: string,
    type?: string,
    aRef?: React.RefObject<HTMLInputElement>): React.ReactNode => {
    return (
      <TextInput
        placeholder={placeHolderParam}
        name={nameParam}
        type={type || "text"}
        maxLength={maxLength}
        {...{ label, message: getMessages, labelNotRequire }}
        onInputSucessCallback={wasAnInputSuccess => {
          setValidation(nameParam, wasAnInputSuccess)
        }}
        onInputValueCallback={(value, isValidated) => {
          setField(nameParam, value, isValidated)
        }}
        ref={aRef}
        inputFromProps={checkFetchData(nameParam) ?
          getPrefetchData[nameParam]?.value : undefined}
        isValidated={getPrefetchData[nameParam]?.isValidated}
        hasValueChange={(isChange) => {
          setInputValueChange(isChange)
        }}
      />
    )
  }

  return (
    <FormStep2Container>
      <H3>
        Tell us about yourself
      </H3>
      <FormStep2Form
        onKeyPress={(e: any) => {
          const code = e.which || e.keyCode
          if (code === 13) {
            e.preventDefault()
            return false
          }
        }}
      >
        <FormStep2FormContainer>
          <div onClick={() => {
            scrollToAddressField()
          }}>
            <AddressAutosuggest
              label='Home address'
              placeholder=''
              ref={Refs[ADDRESS]}
              inputFromProps={checkFetchData(ADDRESS) ?
                getPrefetchData[ADDRESS]?.value : undefined}
              isValidated={getPrefetchData[ADDRESS]?.isValidated}
              hasAddressValueChange={(hasChange) => {
                if (hasChange) {
                  dispatch(setUnsuccessfullyPosted(1))
                  dispatch(setUnsuccessfullyPosted(2))
                }
                setInputValueChange(hasChange)
              }}
              handleAddressValue={(value, isValid) => {
                setValidation(ADDRESS, isValid)
              }}
              handleAddressInfo={(city, state, postcode) => {
                setCityValue(city)
                setStateOrTerritoryValue(state)
                setPostcodeValue(postcode)
              }}
            />
          </div>

          {inputFieldFactory(
            createInputField("", '', UNIT_NUMBER,
              undefined, "Unit number (if applicable)", 'text', Refs[UNIT_NUMBER])
          )}

          {inputFieldFactory(
            createInputField("City", '', CITY,
              undefined, undefined, 'text', Refs[CITY])
          )}

          <StateWrapper>
            <SelectDropdown
              label="State / territory"
              name={STATE_OR_TERITORY}
              contentDefault="Select a state"
              data={state}
              onSelectedItemProp={wasSelected => {
                setStateOrTerritoryValidation(wasSelected)
              }}
              ref={Refs[STATE_OR_TERITORY]}
              inputFromProps={checkFetchData(STATE_OR_TERITORY) ?
                getPrefetchData[STATE_OR_TERITORY]?.value : undefined}
              isValidated={getPrefetchData[STATE_OR_TERITORY]?.isValidated}
              onSelectedValueProp={data => {
                setStateOrTerritoryValue(data)
              }}
            />
          </StateWrapper>

          {inputFieldFactory(
            createInputField("Postcode", '', POSTCODE,
              4, undefined, 'tel', Refs[POSTCODE])
          )}

          <Wrapped>
            <DateInput
              nameProp={DATE_OF_BIRTH}
              onInputSuccess={(wasAnInputSuccess: boolean) => {
                setValidation(DATE_OF_BIRTH, wasAnInputSuccess)
              }}
              ref={Refs[DATE_OF_BIRTH]}
              handleDateValue={(dateValue) => {
                dateValue !== undefined && setField(DATE_OF_BIRTH, dateValue)
              }}
              inputFromProps={checkFetchData(DATE_OF_BIRTH) ?
                getPrefetchData[DATE_OF_BIRTH]?.value : undefined}
              isValidated={getPrefetchData[DATE_OF_BIRTH]?.isValidated}
            />
            <SelectDropdown
              label="Gender"
              name={GENDER}
              contentDefault="Select gender"
              data={gender}
              onSelectedItemProp={wasSelected => {
                setGenderValidation(wasSelected)
              }}
              ref={Refs[GENDER]}
              inputFromProps={checkFetchData(GENDER) ?
                getPrefetchData[GENDER]?.value : undefined}
              isValidated={getPrefetchData[GENDER]?.isValidated}
              onSelectedValueProp={data => {
                setGenderStatusValue(data)
              }}
            />
          </Wrapped>

          <SelectDropdown
            label="Employment status"
            name={EMPLOYMENT_STATUS}
            contentDefault="Select employment status"
            data={employmentStatus}
            onSelectedItemProp={wasSelected => {
              setEmploymentStatusValidation(wasSelected)
            }}
            ref={Refs[EMPLOYMENT_STATUS]}
            inputFromProps={checkFetchData(EMPLOYMENT_STATUS) ?
              getPrefetchData[EMPLOYMENT_STATUS]?.value : undefined}
            isValidated={getPrefetchData[EMPLOYMENT_STATUS]?.isValidated}
            inputOnAverageFromProps={checkFetchData(EMPLOYMENT_STATUS_CASUAL) ?
              getPrefetchData[EMPLOYMENT_STATUS_CASUAL]?.value : undefined}
            onAverageData={data => {
              setField(EMPLOYMENT_STATUS_CASUAL, data[EMPLOYMENT_STATUS_CASUAL].value)
              setAverageData(data)
            }}
            onSelectedValueProp={data => {
              setEmploymentStatusValue(data)
            }}
            onAverageSelected={data => {
              setAverageValidation(data)
            }}
          />
        </FormStep2FormContainer>

        <NewcastleUserCondition
          onNextOfKinValidated={wasSuccessful => {
            setNextOfKinValidation(wasSuccessful)
          }}
          onNextOfKintData={data => {
            setNextOfKinData(data)
          }}
          newcastleUserAge={checkFetchData(DATE_OF_BIRTH) ?
            getPrefetchData[DATE_OF_BIRTH]?.value : undefined}
        />

        <ProgressButtonPositioningDiv>
          <ProgressButton
            widthSize="11.125rem"
            title="Continue"
            stepNumber={1}
            onClickExecFunc={handlePageSubmission}
            buttonStates={proceedingButtonState}
            onClickCallbackProp={() => {
              onClickOnContinueButtonProp()
            }}
          />
        </ProgressButtonPositioningDiv>
      </FormStep2Form>

    </FormStep2Container >
  )
}

export default FormStep2AboutYou
