import data from './employer-suggestion.json'

// const PayrollDeduction = Payroll_deduction

interface Employer {
  Name: string,
  ["Payroll deduction"]: boolean,
  PayrollNumberRequired: boolean
}

interface EmployerList {
  branchName: string,
  employers: Array<Employer>
}

export interface EmployerSuggestion {
  branches: Array<EmployerList>
}

const suggestionDto: EmployerSuggestion = JSON.parse(JSON.stringify(data))

export const getSuggestions = (value: string, params: string): string[] => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  const { branches } = suggestionDto
  let listEmployer: string[] = [];
  branches.forEach(element => {
    if (element.branchName.includes(params?.toUpperCase())) {
      listEmployer = element.employers.flatMap(obj => obj.Name)
    }
  })
  return inputLength === 0 ? [] : listEmployer.filter(each =>
    each.toLowerCase().slice(0, inputLength) === inputValue
  );
}

export const getIsPayrollNumberRequired = (input: string, params: string) => {
  const { branches } = suggestionDto
  let isPayrollNumberRequired: any
  branches.forEach(element => {
    if (element.branchName.includes(params?.toUpperCase())) {
      let test = element.employers.find(o => o.Name === input)
      isPayrollNumberRequired = test?.PayrollNumberRequired
    }
  })
  return isPayrollNumberRequired
}

export const getIsPayrollDeductionRequired = (input: string, params: string) => {
  const { branches } = suggestionDto
  let isPayrollDeductionRequired: any
  branches.forEach(element => {
    if (element.branchName.includes(params?.toUpperCase())) {
      let test = element.employers.find(o => o.Name === input)
      isPayrollDeductionRequired = test ? test["Payroll deduction"] : ""
    }
  })
  return isPayrollDeductionRequired
}

export const suggestionRule = (value: string) => value.trim().length > 1;
