import styled from 'styled-components'
import Input from '../types/Input'
import color from '../../color-css/color'

export const InputField = styled.input<Input>`
  font-family: museo-sans, sans-serif;
  @media screen and (max-width: 690px) {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 0.9375rem;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: ${props => props.widthSize ? props.widthSize : "15.563rem"};
  height: 1.063rem;
  background-color: ${props => props.inputSuccessAsBackgroundColor === true ? color.blue : color.grey};
  transition: background 0.5s ease-in-out;
  border: 1px solid ${color.white};
  outline: none;
  color: ${props => props.inputSuccessAsBackgroundColor === true ? color.blue_17_percent : color.grey_29_percent};
  font-size: 1rem;
  padding: ${props => props.paddingSize ? props.paddingSize : "0.8125rem 1.625rem 0.75rem 1.5625rem"};
  border-radius: 0.625rem;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${color.grey_darker};
    font-family: museo-sans, sans-serif;
    font-size: 1rem;
  }
  &:focus {
    ::-webkit-input-placeholder { color:transparent; }
    ::-moz-placeholder { color:transparent; }
    border: 1px solid ${color.blue_darker};
  }
  ::-webkit-calendar-picker-indicator {
    opacity: 0;
}
`

export const HoveringWrapper = styled.div`
  display: flex;
  margin-top: 0.875rem;
  @media screen and (max-width: 1024px) {
    margin-top: 0.9375rem;
  }
  &:hover ${InputField} {
    transition: border 0.5s ease-in-out;
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
    cursor: pointer;
  }
`

export default InputField