import React from 'react'
import styled from 'styled-components'

export type Props = {
  className: string
}

const SpinnerWrapper = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  animation-name: spin;
  animation-duration:2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    border-radius: 50%;
    background:
      linear-gradient(0deg,   rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.6) 100%)   0%   0%,
      linear-gradient(90deg,  rgba(255, 255, 255, 0.6)  0%, rgba(255, 255, 255, 0.8) 100%) 100%   0%,
      linear-gradient(180deg, rgba(255, 255, 255, 0.8)  0%, rgba(255, 255, 255, 1) 100%) 100% 100%,
      linear-gradient(360deg, rgba(255, 255, 255, 1)  0%, rgba(255, 255, 255, 1) 100%)   0% 100%
    ;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    width: 1.25rem;
    height: 1.25rem;
  }

  &:after {
    background: #FF6820;
    border-radius: 50%;
    width: 0.875rem;
    height: 0.875rem;
    margin: 0.1875rem;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

export const Spinner: React.FC<Props> = ({ className }) => (
  <SpinnerWrapper className={className} role='progressbar' />
)
