import React, { useEffect } from 'react'
import styled from 'styled-components'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import colors from '../color-css/color'

export type Props = {
  isCorrect?: boolean;
  message?: React.ReactNode;
  visibility?: boolean;
}

interface Container {
  isVisible: boolean
}

const Container = styled.div<Container>`
  width: 100%;
  margin-top: 0.625rem;
  display: ${props => props.isVisible === true ? 'block' : 'none'};
  animation-name: marginAnimate;
  animation-duration: .3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  @keyframes marginAnimate {
  0% {
      margin-top: 0.1rem;
    }
  25% {
    margin-top: 0.25rem;

    }
  50% {
    margin-top: 0.4rem;
    }
  75% {
      margin-top: 0.5rem;
    }
  100% {
    margin-top: 0.625rem;
    }

`

interface ValidationIndicatorIconWrapper {
  correct: boolean
}

const GreenTick = styled.div<ValidationIndicatorIconWrapper>`
  display: ${props => props.correct === true ? 'flex' : 'none'};
  width: 100%;
`

const RedTick = styled.div<ValidationIndicatorIconWrapper>`
  display: ${props => props.correct === true ? 'none' : 'flex'};
  width: 100%;
`

const ValidationIndicatorIconWrapper = styled.div<ValidationIndicatorIconWrapper>`
  height: 1.25rem;
  border-radius: 50%;
  background-color: ${props => props.correct === true ? colors.green : colors.red};
  animation-name: bounceSmall;
  animation-duration: 0.75s;
  animation-timing-function: ease-in-out;
  @keyframes bounceSmall {
  0% {
    transform: scale(1.2);
    }
  25% {
    transform: scale(0.8);
    }
  50% {
    transform: scale(0.5);
    }
  75% {
    transform: scale(1.2);
    }
  100% {
    transform: scale(1);
    }
`

const StyledCheckIcon = styled(FontAwesomeIcon)`
  color: #fff;
  height: 0.625rem !important;
  width: 0.625rem !important;
  padding: 0.3125rem;
  animation-name: bounceLarge;
  animation-duration: 0.75s;
  animation-timing-function: ease-in-out;
  @keyframes bounce {
  0% {
    transform: scale(0.1);
    }
  25% {
    transform: scale(0.5);
    }
  50% {
    transform: scale(0.9);
    }
  75% {
    transform: scale(1.2);
    }
  100% {
    transform: scale(1);
    }
`

interface ValidationIndicatorMessage {
  correct: boolean
}
const ValidationIndicatorMessage = styled.p<ValidationIndicatorMessage>`
  @media only screen and (max-width: 1024px) {
    max-width: 90%;
  }
  font-family: museo-sans, sans-serif;
  color: ${props => props.correct === true ? colors.green : colors.red};
  font-size: 1rem;
  font-style: Italic;
  font-weight: 500;
  line-height: 1.25rem;
  text-color: ${props => props.correct === true ? colors.green : colors.red};
  margin: 0 0 0 0.625rem;
  animation-name: fadeIn;
  animation-duration: 0.7s;
  animation-timing-function: linear;
  @keyframes fadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  };
`

const ValidationIndicator: React.FC<Props> = ({ visibility = false, isCorrect = false, message }) => {
  return (
    <Container isVisible={visibility}>
      <GreenTick correct={isCorrect}>
        <ValidationIndicatorIconWrapper correct={isCorrect}>
          <StyledCheckIcon icon={faCheck} />
        </ValidationIndicatorIconWrapper>
        <ValidationIndicatorMessage correct={isCorrect}>
          {message}
        </ValidationIndicatorMessage>
      </GreenTick>
      <RedTick correct={isCorrect}>
        <ValidationIndicatorIconWrapper correct={isCorrect}>
          <StyledCheckIcon icon={faTimes} />
        </ValidationIndicatorIconWrapper>
        <ValidationIndicatorMessage correct={isCorrect}>
          {message}
        </ValidationIndicatorMessage>
      </RedTick>
    </Container >
  );
}

export default ValidationIndicator;
