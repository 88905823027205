import React from 'react'
import styled from 'styled-components'

export type Props = {
  className: string
}

const Wrapper = styled.div`
  margin-top: 0.875rem;
  @media screen and (max-width: 1024px) {
    margin-top: 0.9375rem;
  } 
`

export const MarginWrapper: React.FC<Props> = ({ className, children }) => (
  <Wrapper className={className} >
    {children}
  </Wrapper>
)

export default MarginWrapper