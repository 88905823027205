import React from 'react'
import { css } from 'styled-components'

export const ScrollBar = css`
  scrollbar-color: #EFEFEF #EFEFEF50;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 0.8125rem;
    cursor: pointer;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #EFEFEF; 
    border-radius: 0.625rem;
    margin-bottom: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #EFEFEF; 
    border-radius: 0.625rem;
  }
  // ::-webkit-scrollbar-track-piece:end {
  //   background: transparent;
  //   margin-bottom: 10px; 
  // }
`