import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { uuid } from '../../../utils/submitData'
import { STATE_FROM_SUBURB } from '../../FormHighOrder/types/YourWorkField'
import { Spinner } from '../../AddressAutosuggest/SearchLocationInput'
import styled from 'styled-components';
import {
    CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME,
    CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME,
    CREDITCARD_PAYMENT_TAB_DATA,
    CREDITCARD_FATZEBRA_AUTHKEY,
    CREDITCARD_FATZEBRA_TOKEN,
    CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION
} from '../../FormHighOrder/types/YourContributionField'
import {
    writeForm4CreditCardTabData
} from '../../FormHighOrder/features/index'
import { CreditCardPaymentForm } from '../'
import ReactRef from 'components/FormHighOrder/types/ReactRef'
import * as Sentry from "@sentry/react";

declare global {
    interface Window {
        FatZebra: any,
        fz: any,
    }
}

export const iframeHeight = 300
export const iframeErrorHeight = 370
export const iframeId = 'fz-paynow'

const StyledLoadingDiv = styled.div`
    text-align: center;
    margin: auto;
    position: relative;

    & > p {
        padding-top: 8rem;
    }
`

const StyledIframeWrapper = styled.div`
    width: 100%;
    height: ${iframeHeight}px;

    & > iframe {
        border: none;
        height: 100%;
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: -3rem;
    }
`

const StyledTestCards = styled.div`
    background: #f3f3f3;
    border-radius: 10px;
    padding: 1.5rem;
    margin-top: 3rem;
`

const StyledFormNotice = styled(StyledTestCards)`
    display: flex;
    align-items: center;
    justify-content: center;

    & > p {
        margin: 0;
    }
`

let fatZebraMerchantUsername: string | undefined;

type Props = {
    // amount?: string,
    // frequency?: string
    hasError?: boolean
}

export const tokenizeCard = (): boolean => {
    if (typeof window.fz !== 'undefined') {
        window.fz.checkout()
        return true
    }
    return false
}

const FatZebraHosted: React.FC<Props> = (props) => {
    const testModeSetting: 'true' | 'false' | string | undefined = process.env.GATSBY_TEST_MODE
    let fatZebraTestMode = false

    switch (testModeSetting) {
      case 'true':
        fatZebraTestMode = true
        break
      case 'false':
        fatZebraTestMode = false
        break
    }

    const dispatch = useDispatch()

    const subUnitAmount = 1

    const [fatzebraJWT, setFatzebraJWT] = useState(undefined)
    const [paymentAmountKnown, setPaymentAmountKnown] = useState(false)
    const [fatzebraVerificationCode, setFatzebraVerificationCode] = useState(undefined)
    const [hasLoadedSDK, setHasLoadedSDK] = useState(false)

    const Refs: ReactRef = {
        [CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME]: useRef<HTMLInputElement>(null),
        [CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME]: useRef<HTMLInputElement>(null),
    }

    let getStateFromForm1 = useSelector((state: RootState) =>
        state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

    if (getStateFromForm1 === 'NT' || getStateFromForm1 === 'Broken Hill') {
        getStateFromForm1 = 'SA'
    }

    if (getStateFromForm1 === 'WA') {
        fatZebraMerchantUsername = process.env.GATSBY_FATZEBRA_WA_USERNAME
    }
    if (getStateFromForm1 === 'SA') {
        fatZebraMerchantUsername = process.env.GATSBY_FATZEBRA_SA_USERNAME
    }
    if (getStateFromForm1 === 'QLD') {
        fatZebraMerchantUsername = process.env.GATSBY_FATZEBRA_QLD_USERNAME
    }
    if (getStateFromForm1 === 'TAS') {
        fatZebraMerchantUsername = process.env.GATSBY_FATZEBRA_TAS_USERNAME
    }

    if (fatZebraTestMode) {
        if (getStateFromForm1 === 'QLD') {
            fatZebraMerchantUsername = process.env.GATSBY_FATZEBRA_QLD_USERNAME
        } else if (getStateFromForm1 === 'TAS') {
            fatZebraMerchantUsername = process.env.GATSBY_FATZEBRA_TAS_USERNAME
        } else if (getStateFromForm1 === 'WA') {
            fatZebraMerchantUsername = process.env.GATSBY_FATZEBRA_WA_USERNAME
        } else {
            getStateFromForm1 = 'SA'
            fatZebraMerchantUsername = process.env.GATSBY_FATZEBRA_SA_USERNAME
        }
    }

    const getPrefetchData = useSelector((state: RootState) =>
        state.FormHighOrder.pageData[3].data[CREDITCARD_PAYMENT_TAB_DATA])

    const usesFrequencyOfDeduction = getPrefetchData?.value?.[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]
    const frequenceOfDeduction = getPrefetchData?.value?.[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value

    useEffect(() => {
        // reset any saved token from a previous failed transaction
        dispatchCreditCardData(CREDITCARD_FATZEBRA_TOKEN, '', false)
    }, [])

    const fatZebraAuthkeyURL = process.env.GATSBY_FATZEBRA_AUTHKEY_URL
    const fatZebraVerificationUrl = process.env.GATSBY_FATZEBRA_VERIFICATIONCODE_URL
    if (typeof fatZebraAuthkeyURL === 'undefined' ||
        typeof fatZebraMerchantUsername === 'undefined' || typeof fatZebraVerificationUrl === 'undefined') {
        throw "Fat Zebra credentials not set, cannot connect to payment processor"
    }

    const dispatchCreditCardData = (nameParams: string, data: string, dataValidated: boolean) => {
        dispatch(writeForm4CreditCardTabData({
            key: nameParams,
            value: data ? data : "",
            isValidated: dataValidated
        }
        ))
    }

    const [serverTestMode, setServerTestMode] = useState('testing')

    useEffect(() => {
        setFatzebraVerificationCode(undefined)
        if (!usesFrequencyOfDeduction || (usesFrequencyOfDeduction && typeof frequenceOfDeduction !== 'undefined')) {
            setPaymentAmountKnown(true)
        }
    }, [usesFrequencyOfDeduction, frequenceOfDeduction])

    // Request a one time use authkey used by the iframe
    useEffect(() => {
        if (paymentAmountKnown) {
            const jsonBody = JSON.stringify({
                testMode: fatZebraTestMode,
                branch: getStateFromForm1
            })

            const request = new Request(fatZebraAuthkeyURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: jsonBody,
            })
            fetch(request).then((response) => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw "Invalid response from payment provider"
                }
            }).then((response) => {
                if (response?.data?.token) {
                    dispatchCreditCardData(CREDITCARD_FATZEBRA_AUTHKEY, response.data.token, true)
                    window.localStorage.removeItem('fz-access-token')
                    window.localStorage.setItem('fz-access-token', response.data.token)
                    setFatzebraJWT(response.data.token)
                } else {
                    throw "Unable to retrieve authkey for payment"
                }
            }).catch(error => {
                throw error
            })
        }
    }, [paymentAmountKnown])

    useEffect(() => {
        if (fatzebraJWT) {
            // Load in the Fat Zebra SDK
            const scriptId = 'fatzebra'
            const scriptExists = document.getElementById(scriptId)

            const insertScript = () => {
                const script = document.createElement('script')
                script.src = fatZebraTestMode ? "https://cdn.pmnts-sandbox.io/sdk/v1/fatzebra.js" : "https://cdn.pmnts.io/sdk/v1/fatzebra.js"
                script.id = scriptId
                script.addEventListener('load', () => {
                    setHasLoadedSDK(true)
                })
                document.body.appendChild(script)
            }

            if (!scriptExists) {
                insertScript()
            } else {
                window.fz = undefined
                setHasLoadedSDK(true)
            }
        }
    }, [fatzebraJWT])

    useEffect(() => {
        if (hasLoadedSDK && paymentAmountKnown) {

            const jsonBody = JSON.stringify({
                testMode: fatZebraTestMode,
                branch: getStateFromForm1,
                invoiceNo: uuid,
                amount: subUnitAmount
            })

            const request = new Request(fatZebraVerificationUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: jsonBody,
            })
            fetch(request).then((response) => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw "Invalid response from payment provider"
                }
            }).then((response) => {
                if (response?.result) {
                    const verificationCode = response?.result
                    const existingFrame = document.getElementById('fz-paynow')
                    if (existingFrame) {
                        if (existingFrame.firstChild) {
                            existingFrame.removeChild(existingFrame.firstChild)
                        }
                    }

                    window.fz = new window.FatZebra({
                        username: fatZebraMerchantUsername,
                        test: fatZebraTestMode
                    })

                    // Receive a FZ card token
                    window.fz.on('fz.tokenization.success', function(event: any) {
                        if (event?.detail?.data?.token) {
                            dispatchCreditCardData(CREDITCARD_FATZEBRA_TOKEN, event.detail.data.token, true)
                        }
                    })

                    window.fz.on('fz.validation.error', function (event: any) {
                        Sentry.captureException(
                            new Error('Fatzebra validation error'),
                            { contexts: event },
                        )
                    })

                    window.fz.on('fz.form_validation.error', function (event: any) {
                        Sentry.captureException(
                            new Error('Fatzebra form validation error'),
                            { contexts: event },
                        )
                    })
                    
                    window.fz.on('fz.payment.error', function (event: any) {
                        Sentry.captureException(
                          new Error('Fatzebra payment error'),
                          { contexts: event },
                        )
                    })

                    window.fz.on('fz.sca.error', function (event: any) {
                        Sentry.captureException(
                          new Error('Fatzebra SCA error'),
                          { contexts: event },
                        )
                    })

                    // Tokeination error - doesn't seem to ever fire (including on invalid card details)
                    window.fz.on('fz.tokenization.error', function(event: any) {
                        Sentry.captureException(
                            new Error('Fatzebra tokenization error'),
                            { contexts: event },
                          )
                    })

                    const fatZebraOptions: any = {
                        containerId: iframeId,
                        paymentIntent: {
                            payment: {
                            amount: subUnitAmount,
                            currency: "AUD",
                            reference: uuid
                            },
                            verification: verificationCode
                        },
                        options: { // Hpp display options
                            hideButton: true,
                            hideLogos: true,
                            tokenizeOnly: true,
                            cards: "VISA,MasterCard",

                        }
                    }

                    // Fetch key encoded stylesheet for branch (gatsby doesn't allow dynamic resolution of env var names hence the verbose lookup below)
                    switch(getStateFromForm1) {
                        case 'SA':
                            if (typeof process.env.GATSBY_FATZEBRA_STYLESHEET_URL !== 'undefined' &&
                                typeof process.env.GATSBY_FATZEBRA_SA_ENCODED_STYLESHEET_URL !== 'undefined') {
                                    fatZebraOptions.options['css'] = process.env.GATSBY_FATZEBRA_STYLESHEET_URL
                                    fatZebraOptions.options['cssSignature'] = process.env.GATSBY_FATZEBRA_SA_ENCODED_STYLESHEET_URL
                            }
                            break
                        case 'WA':
                            if (typeof process.env.GATSBY_FATZEBRA_STYLESHEET_URL !== 'undefined' &&
                                typeof process.env.GATSBY_FATZEBRA_WA_ENCODED_STYLESHEET_URL !== 'undefined') {
                                    fatZebraOptions.options['css'] = process.env.GATSBY_FATZEBRA_STYLESHEET_URL
                                    fatZebraOptions.options['cssSignature'] = process.env.GATSBY_FATZEBRA_WA_ENCODED_STYLESHEET_URL
                            }
                            break
                        case 'TAS':
                            if (typeof process.env.GATSBY_FATZEBRA_STYLESHEET_URL !== 'undefined' &&
                                typeof process.env.GATSBY_FATZEBRA_TAS_ENCODED_STYLESHEET_URL !== 'undefined') {
                                    fatZebraOptions.options['css'] = process.env.GATSBY_FATZEBRA_STYLESHEET_URL
                                    fatZebraOptions.options['cssSignature'] = process.env.GATSBY_FATZEBRA_TAS_ENCODED_STYLESHEET_URL
                            }
                            break
                        case 'QLD':
                            if (typeof process.env.GATSBY_FATZEBRA_STYLESHEET_URL !== 'undefined' &&
                                typeof process.env.GATSBY_FATZEBRA_QLD_ENCODED_STYLESHEET_URL !== 'undefined') {
                                    fatZebraOptions.options['css'] = process.env.GATSBY_FATZEBRA_STYLESHEET_URL
                                    fatZebraOptions.options['cssSignature'] = process.env.GATSBY_FATZEBRA_QLD_ENCODED_STYLESHEET_URL
                            }
                            break
                    }

                    window.fz.renderPaymentsPage(fatZebraOptions)
                } else {
                    throw "Unable to retrieve verification code for payment"
                }
                if (response?.serverTestMode) {
                    setServerTestMode(response?.serverTestMode)
                }
            }).catch(error => {
                throw error
            })
        }
    }, [hasLoadedSDK, fatzebraVerificationCode, subUnitAmount])

    // Don't render anything if frequencyOfDeduction has not been selected yet
    // as we can't get a fat zebra verification code which requires the amount
    if (!paymentAmountKnown) {
        return (
            <StyledFormNotice>
                <p>Please select your payment frequency to display the payment form</p>
            </StyledFormNotice>
        )
    }

    if (hasLoadedSDK) {
        return (
            <>
                <StyledIframeWrapper id={iframeId} />
                {fatZebraTestMode && <StyledTestCards>
                    <p>Fat Zebra account username:</p>
                    <p><strong>{fatZebraMerchantUsername}</strong></p>
                    <p>Fat Zebra Test Cards:</p>
                    <p>card #: 4000000000001000	- Visa Successful</p>
                    <p>card #: 5200000000001005 - Mastercard Successful</p>
                </StyledTestCards>}
            </>
        )
    } else {
        return (
            <CreditCardPaymentForm>
                <StyledLoadingDiv>
                    <Spinner isShow={true} />
                    <p>Loading payment form</p>
                </StyledLoadingDiv>
            </CreditCardPaymentForm>
        )
    }
}

export default FatZebraHosted
