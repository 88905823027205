import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import store from '../store'
import {
    resetAllState
  } from '../components/FormHighOrder/features/index'
import { styleSheetSerializer } from 'jest-styled-components'

const pageTitles: Map<number, string> = new Map([
    [0, 'your-work'],
    [1, 'about-you'],
    [2, 'membership-agreement'],
    [3, 'your-contribution']
])

const prettyTitles: Map<number, string> = new Map([
    [1, 'Step 2'],
    [2, 'Step 3'],
    [3, 'Step 4']
])

declare global {
    interface Window {
        _hsq: any,
    }
}

export default (formStep: number): void => {

    // reset all form data if form page is navigated to after successful submit (via back button)
    if (store.getState().FormHighOrder?.formSubmitted) {
        store.dispatch(resetAllState())
    }

    const { pageData } = store.getState().FormHighOrder
    const email = pageData?.[0]?.data?.email?.value

    if (typeof window !== 'undefined') {
        const title = formStep !== 0 ? `${prettyTitles.get(formStep)} - SDA Union | Join the SDA` : `SDA Union | Join the SDA`
        document.title = title
        const pageTitle = formStep !== 0 ? pageTitles.get(formStep) : ''
        const regex = /\//g
        const currentPath = window.location.pathname.replace(regex, '')
        let pagePath = pageTitle !== '' ? `/${pageTitle}/` : undefined

        // Send custom tracking event to analytics
        trackCustomEvent({
            category: 'Page Change',
            action: 'PageView',
            label: 'page ' + pageTitles.get(formStep) + ' view',
        })

        // Send hubspot pageview event
        window._hsq = window._hsq || [];

        // associated tracking identity with email
        if (email &&! /angus\+[1-9]+@atomix.com.au/.test(email)) {
            window?._hsq.push(['identify', {
                email: email
            }]);
        }

        window?._hsq.push(['setPath', pagePath ? pagePath : '/']);
        window?._hsq.push(['trackPageView']);

        // Determine whether to update window history depending on a change from form steps
        if (currentPath !== pageTitle) {
            if (pageTitle !== '') {
                window.history.pushState({}, title, `${window.location.protocol}//${window.location.host}/${pageTitle}/`)
            } else {
                window.history.pushState({}, title, `${window.location.protocol}//${window.location.host}/`)
            }
        }
    }
}
