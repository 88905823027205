import styled from 'styled-components'
import color from '../color-css/color'
import React from 'react'

export type Props = {
    className?: string
}

const Component = styled.h3`
    font-family: museo-sans, sans-serif;
    font-weight: 900;
    font-size: 1.625rem;
    text-align: left;
    letter-spacing: -0.08px;
    color: ${color.blue_17_percent};
    margin-bottom: 2.1875rem;
    @media screen and (max-width: 768px) {
        font-size: 1.25rem;
        letter-spacing: 0;
        line-height: 1.5rem;
        margin-bottom: 1.6875rem;
    }
`

const H3: React.FC<Props> = ({ className, children }) => {
    return <Component className={className} >{children}</Component>
}

export default H3