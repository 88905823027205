import tooltipData from './TextInput/tooltip.json'
import {
  BANKACCOUNT_PAYMENT_ACCOUNT_NAME,
  BANKACCOUNT_PAYMENT_BSB,
  BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER,
  CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER,
  CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME,
  CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME,
  CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE,
  CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV,
  BILLING_FIRST_NAME,
  BILLING_LAST_NAME,
  BILLING_UNIT_NUMBER,
  BILLING_CITY,
  BILLING_STATE_OR_TERITORY,
  BILLING_POSTCODE,
  BILLING_ADDRESS,
  PAY_DEDUCTION_PAYMENT_NUMBER
} from './FormHighOrder/types/YourContributionField'

export const getMessages = {
  firstName: { 0: 'Please enter your first name', 1: 'Thanks' },
  kinFirstName: { 0: 'Please enter your first name', 1: ' ' },
  guardianFirstName: { 0: 'Please enter your first name', 1: ' ' },
  [CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME]: { 0: 'Please enter cardholder first name', 1: ' ' },
  [BILLING_FIRST_NAME]: { 0: 'Please enter your first name', 1: ' ' },

  lastName: { 0: 'Please enter your last name', 1: ' ' },
  kinLastName: { 0: 'Please enter your last name', 1: ' ' },
  guardianLastName: { 0: 'Please enter your last name', 1: ' ' },
  [CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME]: { 0: 'Please enter cardholder last name', 1: ' ' },
  [BILLING_LAST_NAME]: { 0: 'Please enter your last name', 1: ' ' },

  phoneNumber: { 0: 'Please enter a valid phone number', 1: ' ' },
  kinPhoneNumber: { 0: 'Please enter a valid phone number', 1: ' ' },
  guardianPhoneNumber: { 0: 'Please enter a valid phone number', 1: ' ' },
  kinRelation: { 0: 'Please enter your relation', 1: ' ' },
  email: { 0: 'Please enter a valid email address', 1: ' ' },
  guardianEmail: { 0: 'Please enter a valid email address', 1: ' ' },
  suburbWork: { 0: 'failMessage', 1: ' ' },

  creditCard: { 0: 'Credit card number invalid or type not supported', 1: ' ' },
  [CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER]: { 0: 'Credit card number invalid or type not supported', 1: ' ' },

  expiryDate: { 0: 'Enter a valid expiry date', 1: ' ' },
  [CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE]: { 0: 'Enter a valid expiry date', 1: ' ' },

  cvc: { 0: 'Enter a valid CVC/CVV', 1: ' ' },
  [CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV]: { 0: 'Enter a valid CVC/CVV', 1: ' ' },

  [BANKACCOUNT_PAYMENT_ACCOUNT_NAME]: { 0: 'Enter your account name', 1: ' ' },
  [BANKACCOUNT_PAYMENT_BSB]: { 0: 'Enter a valid BSB', 1: ' ' },
  [BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER]: { 0: 'Enter a valid account number', 1: ' ' },
  
  unitNumber: { 0: '', 1: ' ' },
  kinUnitNumber: { 0: ' ', 1: ' ' },
  [BILLING_UNIT_NUMBER]: { 0: ' ', 1: ' ' },

  city: { 0: ' ', 1: ' ' },
  kinCity: { 0: ' ', 1: ' ' },
  [BILLING_CITY]: { 0: ' ', 1: ' ' },

  stateOrTerritory: { 0: ' ', 1: ' ' },
  kinStateOrTerritory: { 0: ' ', 1: ' ' },
  [BILLING_STATE_OR_TERITORY]: { 0: ' ', 1: ' ' },
  
  postcode: { 0: ' ', 1: ' ' },
  kinPostcode: { 0: ' ', 1: ' ' },
  [BILLING_POSTCODE]: { 0: ' ', 1: ' ' },

  [BILLING_ADDRESS]: { 0: 'Please enter billing address', 1: ' ' },

  [PAY_DEDUCTION_PAYMENT_NUMBER]: { 0: 'Please enter payroll number', 1: ' ' },
}

export const getMessageByName = (name: string, booleanValue: boolean) => {
  const messageByName = name
  let message = ''
  if (messageByName && typeof messageByName === 'object') {
    message = messageByName[+booleanValue]
  }
  return message
}