import React, { useState, forwardRef, useEffect, useRef } from "react";
import AutoSuggest, { RenderSuggestionsContainerParams } from "react-autosuggest";
import Tooltip from '../Tooltip'
import ValidationIndicator from '../ValidationIndicator'
import styled from 'styled-components'
var match = require('autosuggest-highlight/match')
var parse = require('autosuggest-highlight/parse');

import { getSuggestions, suggestionRule, getPostcodeFromSuburb } from './SuggestSource'
import color from '../color-css/color'
import { DropdownFooter, DropdownFooterContent, DropdownFooterContentHover } from './Footer'
import Label from '../Label'
import LabelIcon from '../Label/LabelIcon'
import MarginWrapper from '../Commons/MarginWrapper'
import { ScrollBar } from '../ScrollBarComponent'
import { isIE } from 'react-device-detect'

export type Props = {
  handleCantFindSuburbProp?: (selectedCantFindSuburb: boolean) => void
  onSelectedItemProp?: (wasSelected: boolean) => void
  inputFromProps?: string
  isValidatedFromProp?: boolean
  handleSuburbData?: (selectValue: string | {
    suburb: string,
    postcode: string | undefined
  }) => void
  hasSuburbValueChange?: (isVisible: boolean) => void
  getStateValueFromSuburb?: (state: string) => void
}

declare global {
  interface Document {
      documentMode?: any;
  }
}
interface AutosuggestComponent {
  widthSize?: string,
  onHoverInputSuccess?: boolean,
  onSelectCantFindSuburb?: boolean,
}

interface Wrapper {
  widthSize?: string,
  onHoverInputSuccess?: boolean,
  onSelectCantFindSuburb?: boolean,
  pointerEvent?: boolean
}
const Input = styled.input`
  -webkit-appearance: none;
`
const StyledAutosuggestWrapper = styled(MarginWrapper) <Wrapper>`
  border: 1px solid ${color.white};
  outline: none;
  font-family: museo-sans, sans-serif;

& .react-autosuggest__container {
  position: relative;
 }

& .react-autosuggest__input {
  font-family: museo-sans, sans-serif;
  @media screen and (max-width: 690px) {
    padding: 0.8125rem 1.625rem 0.75rem 0.9375rem;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  width: ${props => props.widthSize ? props.widthSize : "34rem"};
  height: 1.1875rem;
  background-color: ${props => props.onHoverInputSuccess === true ? color.blue : color.grey};
  transition: background 0.5s ease-in-out;
  border: 1px solid ${color.white};
  outline : none;
  color: ${props => props.onHoverInputSuccess === true ? color.blue_17_percent : color.grey_29_percent};
  font-size: 1rem;
  padding-left: 1.5625rem;
  border-radius: 0.625rem;
  padding: 0.75rem 5.3125rem 0.6875rem 1.5rem;
  cursor: pointer;
  & ::placeholder,
  & ::-webkit-input-placeholder {
   color: ${color.grey_darker};
   font-family: museo-sans, sans-serif;
   font-size: 1rem;
  }
  &:focus {
    ::-webkit-input-placeholder { color:transparent; }
    ::-moz-placeholder { color:transparent; }
    border: 1px solid ${color.blue_darker};
  }
 }

 & .react-autosuggest__input:hover{
  border: 1px solid ${color.blue_darker};
  transition: border 0.5s ease-in-out;
 }

& .react-autosuggest__suggestions-container--open {
  display: ${props => props.onSelectCantFindSuburb === true ? 'block' : 'none'};
  position: absolute;
  margin-top: 1.125rem;
  width: ${props => props.widthSize ? props.widthSize : "40.9375rem"};
  color: ${color.grey_29_percent};
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: -0.05px;
  z-index: 1;
  animation-name: fade;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  };
  @media screen and (max-width: 690px) {
    width: 100%;
    height: 12.875rem;
  }
  height: 14.0625rem;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3.125rem rgba(1,47,87,0.1);
  -ms-box-shadow: 0px 0px 3.125rem rgba(1,47,87,0.1);
  border-radius: 0.625rem;
  & ::before {
    content: "";
    position: absolute;
    left: 20rem;
    @media screen and (max-width: 690px) {
      left: 45vw;
    }
    top: -1.25rem;
    width: 0;
    height: 0;
    border-top: 0.9375rem solid transparent;
    border-right: 0.9375rem solid #fff;
    border-bottom: 0.875rem solid transparent;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

& .react-autosuggest__suggestions-list {
  margin: 0;
  width: calc(100% - 1.75rem - 0.625rem);
  height: 8.3125rem;
  padding: 1.125rem 0 0 1.75rem;
  list-style-type: none;
  font-weight: 300;
  font-size: 1rem;
  line-height: 0.625rem;
  overflow: auto;
  margin-top: 0.875rem;
  ${ScrollBar}
  @media screen and (max-width: 690px) {
    width: auto;
    margin-right: 1.1875rem;
    padding-left: 1.1875rem;
    margin-top: 1.375rem;
    height: 8.125rem;
    padding-top: 0.8125rem;
  }
}

& .react-autosuggest__suggestion {
  display: block;
  cursor: pointer;
  pointer-events: ${props => props.pointerEvent ? 'auto' : 'none'};
  margin-bottom: 1.6rem;
}

& .react-autosuggest__suggestion:hover {
  color: ${color.blue_17_percent};
  font-weight: bold;
}

& .react-autosuggest__suggestion-match {
  font-weight: bold;
}
`

const HoveringWrapper = styled.div<AutosuggestComponent>`
cursor: 'pointer';
`

const StyledItalic = styled.i`
  font-size: 1rem;
  color: ${color.grey_darker};
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: normal;
`

const SuggestionContents = styled.span`
  &:focus {
    color: ${color.blue_17_percent};
    font-weight: bold;
  }
`

type Ref = HTMLInputElement

const SuburbAutosuggestField = ((props: Props) => {
  const { inputFromProps, isValidatedFromProp, handleSuburbData,
    onSelectedItemProp, getStateValueFromSuburb, hasSuburbValueChange } = props

  const [inputValue, setValue] = useState<string>('')
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [validSelection, setValidSelection] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [validatiisVisible, setValidatiisVisible] = useState(false)
  const [disableClickOnSuggestion, setDisableClickOnSuggestion] = useState(true)
  const [labelIconState, setLabelIconStateState] = useState(true)
  const [isChange, setIsChange] = useState(false)
  const [menuOpenForTab, setMenuOpenForTab] = useState(false)
  const [onNext, setOnNext] = useState(0)

  const isIE = /*@cc_on!@*/false || (typeof document !== 'undefined' && !!document.documentMode)
  const noResultString = "No results found."
  const suggestString = `Try modifying your search term${isIE ? '.' : ', or select \'Can\'t find your suburb?\''}`
  const CANT_FIND_SUBURB = `Can't find suburb`
  const CANT_FIND_YOUR_SUBURB = `Can't find your suburb?`

  const handleOnCantFindSubburb = (event: any) => {
    event.preventDefault()
    setValue(CANT_FIND_SUBURB)
    setIsMenuOpen(false)
    setValidSelection(true)
    setValidatiisVisible(true)
    handleSuburbData ? handleSuburbData(CANT_FIND_SUBURB) : {}
  }

  const handleSuburbValue = (suggestString: string) => {
    const isValid = suggestString !== undefined
      && suggestString.length > 0
      && suggestString !== noResultString
    setValidSelection(isValid)
    onSelectedItemProp ? onSelectedItemProp(isValid) : {}
    setLabelIconStateState(isValid)
    const stateRegex = /^[^,]+,\s{1}([A-Z\s]+),.*$/ig
    let stateMatch = stateRegex.exec(suggestString)
    let state = stateMatch?.[1] ? stateMatch?.[1] : ''
    getStateValueFromSuburb ? getStateValueFromSuburb(state) : {}
    const suburb = suggestString.substring(0, suggestString.indexOf(','))
    let result = {
      suburb: suburb,
      postcode: suggestString.slice(-4)
    }
    handleSuburbData ? handleSuburbData(result) : {}
    setValue(result.suburb)
  }

  const footerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeExpand = (event: any) => {
      if (event.target.innerText !== CANT_FIND_YOUR_SUBURB)
        setIsMenuOpen(false);
    };

    document.body.addEventListener('click', closeExpand);
    return () => { document.body.removeEventListener('click', closeExpand) }

  }, []);

  const renderSuggestionsContainer = (params: RenderSuggestionsContainerParams) => {

    return (
      <div {...params.containerProps}>
        {params.children}
        {
          !isIE && isMenuOpen && suggestions.length >= 1 &&
          <DropdownFooter
            ref={footerRef}
          >
            <DropdownFooterContentHover>
              <DropdownFooterContent
                tabIndex={0}
                onKeyPress={(e: any) => {
                  if (e.key == 'Enter') {
                    handleOnCantFindSubburb(e)
                  }
                }}
                onBlur={(e: any) => {
                  setIsMenuOpen(false)
                }}
                onClick={(e: React.MouseEvent) => {
                  handleOnCantFindSubburb(e)
                }}
              >
                {CANT_FIND_YOUR_SUBURB}
              </DropdownFooterContent>
            </DropdownFooterContentHover>
          </DropdownFooter>
        }
      </div>
    )
  }

  const arrayRefs: HTMLDivElement[] = []

  const renderFoundResults = (suggestion: string): React.ReactElement => {
    const matches = match(suggestion, inputValue);
    const parts = parse(suggestion, matches);

    return (
      <SuggestionContents
        tabIndex={0}
        style={{ lineHeight: '1' }}
        onKeyDown={(e: any) => {
          if (e.key === 'ArrowDown') {
            setOnNext((onNext + 1))
            arrayRefs[(onNext + 1)]?.focus()
          }
          if (onNext > 0 && e.key === 'ArrowUp') {
            setOnNext((onNext - 1))
            arrayRefs[(onNext - 1)]?.focus()
          }
          if (e.key === 'Enter') {
            e.preventDefault()
            handleSuburbValue(e.target.textContent);
          }
        }}
        ref={(ref: any) => { arrayRefs.push(ref) }}
      >
        {parts.map((part: { text: string, highlight: boolean }, index: number) => {
          const className = part?.highlight ? 'react-autosuggest__suggestion-match' : '';
          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </SuggestionContents>
    );
  }

  useEffect(() => {
    if (inputFromProps !== undefined) {
      inputFromProps ? setValue(inputFromProps) : {}
    }
    if (isValidatedFromProp !== false) {
      isValidatedFromProp ? setValidSelection(isValidatedFromProp) : {}
      isValidatedFromProp ? setValidatiisVisible(isValidatedFromProp) : {}
    }
    onSelectedItemProp ? onSelectedItemProp(isValidatedFromProp ? isValidatedFromProp : false) : {}
  }, [inputFromProps, isValidatedFromProp])

  useEffect(() => {
    hasSuburbValueChange ? hasSuburbValueChange(isChange) : {}
  }, [isChange])

  useEffect(() => {
    if (validSelection === false && inputValue.length > 1) {
      setMenuOpenForTab(true)
    }
    else {
      setMenuOpenForTab(false)
      setIsMenuOpen(false)
    }
  }, [validSelection, inputValue])

  const inputProps = {
    placeholder: "Type your workplace suburb here",
    name: "workSuburb",
    value: inputValue,
    // @ts-ignore
    onChange: (event, { newValue }) => {
      setValue(newValue)
      setValidSelection(newValue === undefined || newValue === null)
      if (inputFromProps !== undefined) {
        setIsChange(true)
      }
      onSelectedItemProp ? onSelectedItemProp(false) : {}
    },
    onFocus: (event: any) => {
      if (event.target !== null) {
        if (event.target.value === '') {
          onSelectedItemProp ? onSelectedItemProp(false) : {}
        }
      }
    },
    onKeyPress: (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        const getSuggestion = getSuggestions(inputValue)
        var suburbArray: any = [];
        getSuggestion.forEach(element => {
          let suburb = element.substring(0, element.indexOf(','))
          suburbArray.push(suburb)
        });
        if (suburbArray.indexOf(inputValue) == -1) {
          setValidSelection(false)
          setValidatiisVisible(true)
          setIsMenuOpen(false)
        }
        if (validSelection === true && isMenuOpen) {
          setIsMenuOpen(false)
        }
      }
    },
    id: "suburb-search",
    autoComplete: "none",
    autoCorrect: "off",
  };

  return (
    <div>
      <Label>What suburb do you work in?
        <LabelIcon iconStates={labelIconState}>{'  '}*</LabelIcon>
      </Label>
      <HoveringWrapper>
        <StyledAutosuggestWrapper
          onHoverInputSuccess={validSelection}
          onSelectCantFindSuburb={isMenuOpen}
          className=""
          pointerEvent={disableClickOnSuggestion}
        >
          <AutoSuggest
            suggestions={suggestions}
            focusInputOnSuggestionClick={false}
            alwaysRenderSuggestions={menuOpenForTab}
            onSuggestionsClearRequested={() => {
              if (suggestions.length === 1 && validSelection === true) setIsMenuOpen(false)
              if (validSelection === true) setIsMenuOpen(false)
            }}
            onSuggestionsFetchRequested={({ value }) => {
              const valueGetter = getSuggestions(value)
              valueGetter.length > 0 ? setSuggestions(valueGetter) : setSuggestions([value])
              setIsMenuOpen(true)
            }}
            onSuggestionSelected={(_, { suggestionValue }) => {
              handleSuburbValue(suggestionValue)
            }}
            shouldRenderSuggestions={(value) => {
              return suggestionRule(value)
            }}
            getSuggestionValue={suggestion => suggestion}
            renderSuggestion={suggestion => {
              let postcode = suggestion.slice(-4)
              if (suggestion.includes(',') && !isNaN(Number(postcode))) {
                setDisableClickOnSuggestion(true)
                return renderFoundResults(suggestion)
              }
              else {
                setDisableClickOnSuggestion(false)
                return (
                  <span>
                    <StyledItalic>
                      {noResultString}
                      <br />
                      {suggestString}
                    </StyledItalic>
                  </span>
                )
              }
            }}
            inputProps={inputProps}
            renderSuggestionsContainer={renderSuggestionsContainer}
            //@ts-ignore
            renderInputComponent={(inputProps) => <Input
              onClick={() => {
                if (inputValue.length > 1) {
                  setIsMenuOpen(true)
                }
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  setIsMenuOpen(false)
                }
                if (event.key === 'Tab') {
                  setIsMenuOpen(false)
                }
              }}
              {...inputProps}
            />}
          />
          <input
            type="text"
            autoComplete="on"
            value=""
            style={{ opacity: 0, position: 'absolute', left: '-100000px' }}
            readOnly={true}

          />
        </StyledAutosuggestWrapper>
      </HoveringWrapper>

      <Tooltip visibility={validatiisVisible} inputName="suburbWork"
        widthSize="14.5rem"
        tooltipTitle="Why do we need this?"
        tooltip_message="We need to know your workplace suburb to ensure
        you’re signing up to the correct SDA branch."
      />

      <ValidationIndicator
        isCorrect={validSelection}
        {...{
          message: validSelection ? '' : "Please select a valid suburb from the suggested options, or select 'Can't find your suburb?'. ",
          visibility: validatiisVisible
        }}
      />

    </div>
  )
})

export default SuburbAutosuggestField
