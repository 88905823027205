import { getPostcodeFromSuburb } from '../components/SuburbAutosuggestField/SuggestSource'

export default (state: string, formState: any): string => {
  let { firstName: fname, lastName: lname, email: email,
    phoneNumber: mob, phoneNumber: phoneNumber, suburbWork: workSuburb,
    workPostcode: postcode,  employer: employer, employerSelectedOrTyped: employerSelectedOrTyped } = formState

  // Fetch postcode from postcode json store
  if (postcode.value === '') {
    postcode = {
      value: getPostcodeFromSuburb(workSuburb.value, state)
    }
  }

  if (state === 'VIC' && typeof process.env.GATSBY_VIC_REDIRECT_URL !== 'undefined') {
    return `
      ${process.env.GATSBY_VIC_REDIRECT_URL}?` +
      encodeURI(
        `fname=${fname.value}&lname=${lname.value}&email=${email.value}&mob=${mob.value}&workSuburb=${workSuburb.value}&postcode=${postcode.value}&src=natjoin`
      )
  }
  if (state === 'NSW' && typeof process.env.GATSBY_NSW_REDIRECT_URL !== 'undefined') {
    return `
      ${process.env.GATSBY_NSW_REDIRECT_URL}?` +
      encodeURI(
        `firstName=${fname.value}&lastName=${lname.value}&email=${email.value}&phoneNumber=${phoneNumber.value}&workSuburb=${workSuburb.value}&postcode=${postcode.value}&employer=${employer.value}&employerSelectedOrTyped=${employerSelectedOrTyped.value}`
      )
  }
  if (state === 'Newcastle' && typeof process.env.GATSBY_NEWCASTLE_REDIRECT_URL !== 'undefined') {
    return `
      ${process.env.GATSBY_NEWCASTLE_REDIRECT_URL}?` +
      encodeURI(
        `fname=${fname.value}&lname=${lname.value}&email=${email.value}&mob=${mob.value}`
      )
  }
  return '#'
}
