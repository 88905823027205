const spaceChar = ' '
const dashChar = '-'

export const injectSpacetAt = (input: string, pos: number) => {
    let array = input.split('')
    let result = ''
    array.splice(pos, 0, spaceChar);
    result = array.join('')
    return result
}

export const injectDashAt = (input: string, pos: number) => {
    let array = input.split('')
    let result = ''
    array.splice(pos, 0, dashChar);
    result = array.join('')
    return result
}

export const renderCreditCard = (input: string) => {
    let array = input.split('');
    let result = '';
    if (input.length === 5) {
        if (input[0] !== spaceChar) {
            array.splice(4, 0, ' ');
            result = array.join('');
            return result;
        } else {
            result = array.join('');
            return result;
        }
    }
    if (input.length === 10) {
        if (input[0] !== spaceChar && input[5] !== spaceChar) {
            array.splice(9, 0, ' ');
            result = array.join('');
            return result;
        } else {
            result = array.join('');
            return result;
        }
    }
    if (input.length === 15) {
        if (input[0] !== spaceChar && input[5] !== spaceChar && input[10] !== spaceChar) {
            array.splice(14, 0, ' ');
            result = array.join('');
            return result;
        } else {
            result = array.join('');
            return result;
        }
    }
    if (input.length === 18) {
        const isSpaceAtPos4 = input[4] !== spaceChar
        const isSpaceAtPos9 = input[9] !== spaceChar
        const isSpaceAtPos14 = input[14] !== spaceChar
        if (isSpaceAtPos4 && input[3] !== spaceChar) {
            return injectSpacetAt(input, 4)
        } else if (isSpaceAtPos9 && input[8] !== spaceChar) {
            return injectSpacetAt(input, 9)
        } else if (isSpaceAtPos14 && input[13] !== spaceChar) {
            return injectSpacetAt(input, 14)
        }
        return input;
    }
}

export const renderExpiryDate = (input: string) => {
    let array = input.split('');
    let result = '';
    if (input.length === 3) {
        array.splice(2, 0, ' / ');
        result = array.join('');
        return result;

    }
    if (input.length === 4) {
        array.splice(3, 0, '/ ');
        result = array.join('');
        return result;
    }
    if (input.length === 5) {
        if (input[0] !== spaceChar) {
            array.splice(4, 0, ' ');
            result = array.join('');
            return result;
        } else {
            result = array.join('');
            return result;
        }
    }
    if (input.length === 6) {
        const isSpaceAtPos2 = input[2] !== spaceChar
        const isSpaceAtPos4 = input[4] !== spaceChar
        if (isSpaceAtPos2 && input[1] !== spaceChar) {
            return injectSpacetAt(input, 2)
        } else if (isSpaceAtPos4 && input[3] !== spaceChar) {
            return injectSpacetAt(input, 4)
        }

        const isSourceAtPos3 = input[3] !== '/'
        if (isSourceAtPos3 && input[2] !== '/') {
            let array = input.split('')
            let result = ''
            array.splice(3, 0, '/');
            result = array.join('')
            return result
        }
        return input
    }
    else return input
}

export const renderBSB = (input: string) => {
    let array = input.split('');
    let result = '';
    if (input.length === 4) {
        array.splice(3, 0, ' - ');
        result = array.join('');
        return result;
    }
    if (input.length === 5) {
        array.splice(4, 0, '- ');
        result = array.join('');
        return result;
    }
    if (input.length === 6) {
        result = array.join('');
        return result;
    }

    if (input.length === 8) {
        const isSpaceAtPos3 = input[3] !== spaceChar
        const isSpaceAtPos5 = input[5] !== spaceChar
        if (isSpaceAtPos3 && input[2] !== spaceChar) {
            return injectSpacetAt(input, 3)
        } else if (isSpaceAtPos5 && input[4] !== spaceChar) {
            return injectSpacetAt(input, 5)
        }

        const isSourceAtPos4 = input[4] !== dashChar
        if (isSourceAtPos4 && input[3] !== dashChar) {
            return injectDashAt(input, 4)
        }
        return input;
    }
}

export const renderAccountNumber = (input: string) => {
    let array = input.split('');
    let result = '';
    if (input.length === 5) {
        if (input[0] !== spaceChar) {
            return injectSpacetAt(input, 4);
        } else {
            result = array.join('');
            return result;
        }
    }

    if (input.length === 8) {
        const isSpaceAtPos4 = input[4] !== spaceChar
        if (isSpaceAtPos4 && input[3] !== spaceChar) {
            return injectSpacetAt(input, 4)
        }
        return input;
    }
}