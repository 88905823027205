import data from './suburb.json'

export interface Suburb {
  name: string
  postcode: number
}
export interface SuburbSuggestion {
  suburbs: Array<Suburb>
}

const suggestionDto: SuburbSuggestion = JSON.parse(JSON.stringify(data))

export const handleStateByPostcode = (postcode: any) => {

  postcode = parseInt(postcode)
  if ((1000 <= postcode && postcode <= 2249)
    || (2253 <= postcode && postcode <= 2255)
    || postcode === 2266 || postcode === 2279 || postcode === 2288 || postcode === 2301
    || (2268 <= postcode && postcode <= 2277)
    || (2312 <= postcode && postcode <= 2314)
    || (2331 <= postcode && postcode <= 2332)
    || (2340 <= postcode && postcode <= 2342)
    || (2344 <= postcode && postcode <= 2419)
    || (2422 <= postcode && postcode <= 2842)
    || (2844 <= postcode && postcode <= 2879)
    || (2881 <= postcode && postcode <= 2899)
    || (2921 <= postcode && postcode <= 2999)
  ) {
    return 'NSW'
  }
  else if ((2250 <= postcode && postcode <= 2252)
    || (2256 <= postcode && postcode <= 2265)
    || postcode === 2267 || postcode === 2278 || postcode === 2343 || postcode === 2843
    || (2280 <= postcode && postcode <= 2287)
    || (2289 <= postcode && postcode <= 2300)
    || (2302 <= postcode && postcode <= 2311)
    || (2315 <= postcode && postcode <= 2330)
    || (2333 <= postcode && postcode <= 2339)
    || (2420 <= postcode && postcode <= 2421)
  ) {
    return 'Newcastle'
  }
  else if (2880 === postcode) {
    return 'Broken Hill'
  }
  else if ((200 <= postcode && postcode <= 299)
    || (2600 <= postcode && postcode <= 2618)
    || (2900 <= postcode && postcode <= 2920)
  ) {
    return 'NSW'
  }
  else if (3000 <= postcode && postcode <= 3999) {
    return 'VIC'
  }
  else if (4000 <= postcode && postcode <= 4999) {
    return 'QLD'
  }
  else if (5000 <= postcode && postcode <= 5999) {
    return 'SA'
  }
  else if (6000 <= postcode && postcode <= 6999) {
    return 'WA'
  }
  else if (7000 <= postcode && postcode <= 7999) {
    return 'TAS'
  }
  else if (800 <= postcode && postcode <= 999) {
    return 'NT'
  }
  else return 'error'
}

export const getPostcodeFromSuburb = (suburbName: string, state: string): number | undefined => {
  const { suburbs } = suggestionDto
  return suburbs.find((suburb) => {
    return suburbName === suburb.name && handleStateByPostcode(suburb.postcode) === state
  })?.postcode
}

export const getSuggestions = (value: string): string[] => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  const { suburbs } = suggestionDto
  const suburbNamesSuggestion: any = []
  suburbs.forEach(each => {
    const { name, postcode } = each
    const state = handleStateByPostcode(postcode)
    const nameMatch = each.name.toLowerCase().slice(0, inputLength) === inputValue
    const postcodeMatch = `${postcode}`.slice(0, inputLength) === inputValue
    if (nameMatch || postcodeMatch) {
      suburbNamesSuggestion.push(name + ', ' + state + ', ' + postcode)
    }
  })
  return inputLength === 0 ? [] : suburbNamesSuggestion
}

export const suggestionRule = (value: string) => value.trim().length > 1;
