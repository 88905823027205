import React from 'react'
import styled from 'styled-components'
import color from '../color-css/color'

const getBackgroundColor = (active = false, isSignatureGenerator = false) => {
  if (active) {
    return "#E2F1F8"
  }
  if (isSignatureGenerator) {
    return "#FFFFFF"
  }
  return "#F3F3F3"
}

export const Container = styled.a<PropsActive>`
  background: ${props => getBackgroundColor(props.active, props?.signatureGenerator)};
  border-radius: 0.625rem;
  width: 11.125rem;
  height: 3.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s ease-in-out;
  transition: border 0.5s ease-in-out;
  border: 1px solid ${color.white};
  margin-right: 0.625rem;
  cursor: pointer;
  outline: none;
  &:focus {
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
    cursor: pointer;
  }
  @media screen and (max-width: 690px) {
    width: 100%;
    height: 2.625rem;
  };
`
export const RadioButtonContainerWrapper = styled.div`
  @media screen and (max-width: 690px) {
    width: 100%;
  };
  &:hover ${Container} {
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
    cursor: pointer;
  }
`
export const StyledTitle = styled.div<PropsActive>`
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -0.15px;
  text-align: center;
  color:${props => props.active ? "#052948" : "#757575"};
`
interface Props {
  active: boolean,
  onPress: any,
  title: string,
  signatureGenerator?: boolean,
}
interface PropsActive {
  active: boolean,
  signatureGenerator?: boolean,
  tabIndex?: any
}
export const ButtonItem: React.FC<Props> = (props) => {
  return (
    <RadioButtonContainerWrapper>
      <Container
        tabIndex="0"
        onKeyDown={(e)=>{
          if (e.key === 'Enter') {
            props.onPress()
          }
        }}
        active={props.active}
        signatureGenerator={props?.signatureGenerator}
        onClick={() => { props.onPress() }}>
        <StyledTitle
          active={props.active}
        >
          {props.title}
        </StyledTitle>
      </Container>
    </RadioButtonContainerWrapper>
  )
}
export default ButtonItem
