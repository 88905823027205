import React, { useState, useEffect, useRef } from 'react'
import { RootState } from 'store'
import { useSelector, useDispatch } from 'react-redux'

import styled from 'styled-components'

import TextInput from '../TextInput/index'
import CleaveField from '../CleaveFields'
import { getMessages } from '../dataProcessing'
import SelectDropdown from '../SelectDropdown/index'
import SummarySection from '../SummarySection'
import dayOfWeek from '../../assets/data/dayOfWeek.json'
import paymentDeducted from '../../assets/data/paymentDeducted.json'
import InputFieldContainer from '../Commons/InputFieldContainer'

import { getStartDate, getDebitAmount } from '../summarySectionProcessing'

import {
  BANKACCOUNT_PAYMENT_ACCOUNT_NAME,
  BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER,
  BANKACCOUNT_PAYMENT_BSB,
  BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION,
  BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION,
  BANKACCOUNT_AGREE_TERM_AND_CONDITION,
  BANKACCOUNT_PAYMENT_TAB_DATA
} from '../FormHighOrder/types/YourContributionField'

import {
  writeForm4BankAccountTabData
} from '../FormHighOrder/features/index'

import {
  EMPLOYMENT_STATUS,
  EMPLOYMENT_STATUS_CASUAL
} from '../FormHighOrder/types/AboutYouField'

import { STATE_FROM_SUBURB } from '../FormHighOrder/types/YourWorkField'

import ReactRef from 'components/FormHighOrder/types/ReactRef'

import branchSpecific from '../../utils/branchSpecificCopy'

export type Props = {
  onBankAccountTabValidated?: (wasSuccessful: boolean) => void
  onBankAccountData?: (data: any) => void
  inputFromProps?: string
  isValidated?: boolean
  isBankAccountDataChange?: (isChange: boolean) => void
}

const BankAccountContainer = styled.div`
  animation-name: flickity;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  @keyframes flickity {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  };
  @media (max-width: 690px) {
    margin-left: 0;
  }
`

const BankAccountForm = styled.div`
  width: 39.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 690px) {
    display: block;
    width: 100%;
  }
`

const Wrapper2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2.1875rem;
  width: 100%;
  @media (max-width: 690px) {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 2.1875rem;
  }
`

interface FrequencyAvailable {
  isFrequencyAvailable?: boolean
}

interface DeductionDayAvailable {
  isDeductionDayAvailable?: boolean
}

const FrequencyAvailable = styled.div<FrequencyAvailable>`
  display: ${props => props.isFrequencyAvailable ? "" : "none"};
  width: 18.875rem;
  @media (max-width: 690px) {
    width: 100%;
  }
`

const DeductionDayAvailable = styled.div<DeductionDayAvailable>`
  display: ${props => props.isDeductionDayAvailable ? "" : "none"};
  width: 19.375rem;
  @media (max-width: 690px) {
    width: 100%;
  }
`

const BankAccount: React.FC<Props> = () => {

  const dispatch = useDispatch()
  const getPrefetchData = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[3].data[BANKACCOUNT_PAYMENT_TAB_DATA]
  )

  const getPrefetchDataForm2 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[1].data
  )

  const getStateFromForm1 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

  const checkFetchData = (nameParam: string) => {
    return getPrefetchData !== undefined && getPrefetchData[nameParam]?.value !== ""
  }

  const [summaryDebitAmount, setSummaryDebitAmount] = useState('')
  const [summaryStartDate, setSummaryStartDate] = useState('')
  const [summaryFrequency, setSummaryFrequency] = useState('')
  const [isFrequencyAvailable, setIsFrequencyAvailable] = React.useState<any>(true)
  const [isDeductionDayAvailable, setIsDeductionDayAvailable] = React.useState<any>(true)

  const Refs: ReactRef = {
    [BANKACCOUNT_PAYMENT_ACCOUNT_NAME]: useRef<HTMLInputElement>(null),
    [BANKACCOUNT_PAYMENT_BSB]: useRef<HTMLInputElement>(null),
    [BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER]: useRef<HTMLInputElement>(null),
    [BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]: useRef<HTMLInputElement>(null),
    [BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]: useRef<HTMLInputElement>(null),
  }

  useEffect(() => {
    if (['QLD', 'SA', 'TAS', 'NT', 'Broken Hill', 'WA'].includes(getStateFromForm1)) {
      if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Full Time'
        || getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Salaried'
        || getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '20+ hours'
      ) {
        setSummaryDebitAmount(getDebitAmount(summaryFrequency, 22.60, getStateFromForm1))
      } else if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '10-19 hours'
      ) {
        setSummaryDebitAmount(getDebitAmount(summaryFrequency, 16.10, getStateFromForm1))
      } else if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '0-9 hours'
      ) {
        setSummaryDebitAmount(getDebitAmount(summaryFrequency, 9.00, getStateFromForm1))
      }
    } else {
      if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Full Time'
        || getPrefetchDataForm2[EMPLOYMENT_STATUS].value === 'Salaried'
        || getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '20+ hours'
      ) {
        setSummaryDebitAmount(getDebitAmount(summaryFrequency, 20.40, getStateFromForm1))
      } else if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '10-19 hours'
      ) {
        setSummaryDebitAmount(getDebitAmount(summaryFrequency, 14.60, getStateFromForm1))
      } else if (
        getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value === '0-9 hours'
      ) {
        setSummaryDebitAmount(getDebitAmount(summaryFrequency, 8.20, getStateFromForm1))
      }
    }
  }), [getPrefetchDataForm2[EMPLOYMENT_STATUS].value, getPrefetchDataForm2[EMPLOYMENT_STATUS_CASUAL].value, summaryFrequency]


  useEffect(() => {
    if (getStateFromForm1 === 'SA' || getStateFromForm1 === 'NT'
      || getStateFromForm1 === 'Broken Hill') {
      setIsFrequencyAvailable(false)
      setSummaryFrequency('Fortnightly')
      setSummaryStartDate(getPrefetchData.value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]?.value)
    }
    else if (getStateFromForm1 === 'WA') {
      setIsDeductionDayAvailable(false)
      setIsFrequencyAvailable(false)
      setSummaryFrequency(getPrefetchData.value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value)
      setSummaryStartDate('firstOfNextMonth')
    }
    else if (getStateFromForm1 === 'QLD') {
      setIsDeductionDayAvailable(false)
      setSummaryFrequency(getPrefetchData.value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value)
      setSummaryStartDate('noStartDate')
    }
    else if (getStateFromForm1 === 'TAS') {
      setIsDeductionDayAvailable(getPrefetchData.value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value?.toLowerCase() !== 'monthly')
      setSummaryFrequency(getPrefetchData.value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value)
      setSummaryStartDate(getPrefetchData.value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value?.toLowerCase() === 'monthly' ? 'firstOfNextMonth' : getPrefetchData.value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]?.value)
    }
    else {
      setIsFrequencyAvailable(true)
      setIsDeductionDayAvailable(true)
      setSummaryStartDate(getPrefetchData.value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]?.value)
      setSummaryFrequency(getPrefetchData.value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value)
    }
  }, [getStateFromForm1,
    getPrefetchData.value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value,
    getPrefetchData.value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]?.value
  ])

  const dispatchBankAccountData = (nameParams: string, data: string, dataValidated: boolean) => {
    dispatch(writeForm4BankAccountTabData({
      key: nameParams,
      value: data ? data : "",
      isValidated: dataValidated
    }
    ))
  }

  const fetchSummaryStartDate = getStartDate(summaryStartDate)


  const inputFieldFactory = (children: React.ReactNode) => {
    return (
      <InputFieldContainer>
        {children}
      </InputFieldContainer>)
  }

  const createInputField = (
    label: string,
    placeHolderParam: string,
    nameParam: string,
    maxLength?: number,
    widthSize?: string,
    tooltipName?: string,
    type?: string,
    aRef?: React.RefObject<HTMLInputElement>,
  ): React.ReactNode => {
    return (
      <TextInput
        placeholder={placeHolderParam}
        name={nameParam}
        type={type}
        maxLength={maxLength}
        widthSize={widthSize}
        {...{ label, message: getMessages, tooltipName }}
        onInputValueCallback={(value, isValidated) => {
          dispatchBankAccountData(nameParam, value, isValidated)
        }}
        ref={aRef}
        inputFromProps={
          // checkFetchData(nameParam) ?
          getPrefetchData.value[nameParam]?.value
          // : ""
        }
        isValidated={getPrefetchData.value[nameParam]?.isValidated}
      />
    )
  }

  return (
    <BankAccountContainer>
      <BankAccountForm>
        {inputFieldFactory(
          createInputField("Account name",
            "",
            BANKACCOUNT_PAYMENT_ACCOUNT_NAME, 100,
            "36.4375rem",
            undefined, 'text',
            Refs[BANKACCOUNT_PAYMENT_ACCOUNT_NAME]
          ),

        )}

        {inputFieldFactory(
          <CleaveField
            name={BANKACCOUNT_PAYMENT_BSB}
            placeholder='_ _ _ - _ _ _'
            label='BSB'
            option={{
              blocks: [3, 3],
              delimiter: ' - ',
              numericOnly: true
            }}
            handleData={(value, validated) => {
              dispatchBankAccountData(BANKACCOUNT_PAYMENT_BSB, value, validated)
            }}
            inputFromProps={getPrefetchData.value[BANKACCOUNT_PAYMENT_BSB]?.value}
            isValidated={getPrefetchData.value[BANKACCOUNT_PAYMENT_BSB]?.isValidated}
          />
        )}

        {inputFieldFactory(<TextInput
          placeholder="_ _ _ _ _ _ _ _ _"
          name={BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER}
          type="text"
          maxLength={9}
          minLength={6}
          {...{ label : 'Account number', message: getMessages }}
          onInputValueCallback={(value, validated) => {
            dispatchBankAccountData(BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER, value, validated)
          }}
          ref={Refs[BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER]}
          inputFromProps={getPrefetchData.value[BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER]?.value}
          isValidated={getPrefetchData.value[BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER]?.isValidated}
        />)}

        <Wrapper2>
          <FrequencyAvailable isFrequencyAvailable={isFrequencyAvailable}>
            <SelectDropdown
              label="How often would you like your payments to be deducted?"
              name={BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION}
              contentDefault="Select frequency"
              data={paymentDeducted}
              onSelectedValueProp={value => {
                dispatchBankAccountData(BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION, value, true)
              }}
              ref={Refs[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]}
              inputFromProps={checkFetchData(BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION) ?
                getPrefetchData.value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value : undefined}
              isValidated={getPrefetchData.value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]?.isValidated}
            />
          </FrequencyAvailable>
          {isDeductionDayAvailable && (
            <DeductionDayAvailable isDeductionDayAvailable={isDeductionDayAvailable}>
              <SelectDropdown
                label="Which day of the week would you like your payments to be deducted?"
                name={BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION}
                contentDefault="Select day"
                data={dayOfWeek}
                onSelectedValueProp={value => {
                  dispatchBankAccountData(BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION, value, true)
                }}
                ref={Refs[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]}
                inputFromProps={checkFetchData(BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION) ?
                                getPrefetchData.value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]?.value : undefined}
                isValidated={getPrefetchData.value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]?.isValidated}
              />
            </DeductionDayAvailable>
          )}
        </Wrapper2>

      </BankAccountForm>

      <SummarySection
        paymentAuthorisationContent={branchSpecific(getStateFromForm1, 'paymentAuthority', 'directDebit')}
        paymentAgreementProp={branchSpecific(getStateFromForm1, 'paymentAgreement', 'directDebit')}
        summaryTableDataProp={[
          {
            title: "Debit amount:",
            value: summaryDebitAmount
          },

          ...(fetchSummaryStartDate !== null ? [{
            title: "Start date:",
            value: fetchSummaryStartDate ? fetchSummaryStartDate : '-- / -- / --'
          }] : []),

          ...(getStateFromForm1 === "WA" ? [{
            title: "End Date:",
            value: "When Cancelled"
        }] : []),

          {
            title: "Frequency:",
            value: summaryFrequency
          },

          ...(getStateFromForm1 !== "WA" ? [{
            title: "Duration:",
            value: "Ongoing"
          }] : []),

        ]}
        noteOnPaymentProp={branchSpecific(getStateFromForm1, 'notesOnPayment')}
        onSelectAgreeTermAndCondition={wasSelected => {
          dispatchBankAccountData(BANKACCOUNT_AGREE_TERM_AND_CONDITION, wasSelected ? 'true' : '', wasSelected ? true : false)
        }}
        hasTermCheckbox={true}
        inputFromProps={getPrefetchData.value[BANKACCOUNT_AGREE_TERM_AND_CONDITION]?.value}
        isValidated={getPrefetchData.value[BANKACCOUNT_AGREE_TERM_AND_CONDITION]?.isValidated}
      />

    </BankAccountContainer>
  )
}

export default BankAccount
