import React from 'react'
import styled from 'styled-components'

import color from '../color-css/color'
import Tooltip from '../Tooltip'
import TD from './TD'

export type Props = {
    tableData: TableData[]
}

const Table = styled.table`
    border: 1px solid ${color.grey_92_percent};
    border-collapse: collapse;
    width: 40.9375rem;
    margin-bottom: 0.625rem;
    @media (max-width: 1024px) {
        width: 100%;
        box-sizing: border-box;
      }
`

const Content = styled(TD)`
    color: ${color.blue_17_percent};
    font-weight: 500;
`

const Title = styled(TD)`
    border: 1px solid ${color.grey_92_percent};
    padding-left: 1.25rem !important;
    font-size: 0.9375rem;
    @media (max-width: 1024px) {
        width: 50%;
        padding-right: 0 !important;
    }
    
    background-color: ${color.grey_95_percent};
    font-weight: 700;
    width: 9.6875rem;
    padding-right: 9.375rem !important;
    color: ${color.grey_29_percent};
`

export const SummaryTable: React.FC<Props> = ({ tableData }) => {

    const tableItems: React.ReactNode[] = tableData.map((data: TableData) =>
        (<tr
            key={data.title}
        >
            <Title>{data.title}</Title>
            <Content>{data.value}</Content>
        </tr>)
    )

    return (
        <div>
            <Table>
                <tbody>
                    {tableItems}
                </tbody>
            </Table>
            <Tooltip
                tooltipName="summary"
                widthSize="15.5625rem"
                tooltipTitle="How is this calculated?"
                tooltip_message="Your SDA membership payments are calculated based on 
                the average number of hours that you work each week."
            />
        </div>
    )
}