import React, { useState, forwardRef, useEffect, useRef } from 'react'
import styled from 'styled-components'

import TextInput from '../TextInput/index'
import ValidationIndicator from '../ValidationIndicator'
import Tooltip from '../Tooltip'
import Label from '../Label'
import LabelIcon from '../Label/LabelIcon'
import { isMobile, isTablet } from 'react-device-detect'
import Cleave from 'cleave.js/react'
import color from '../color-css/color'
import Input from '../TextInput/types/Input'
import { HoveringWrapper } from '../TextInput/styles/InputField'
import moment from 'moment'
import DateDropdown from './DateDropdown'
import calendar from '../../assets/data/daysInCalendar.json'

export type Props = {
  onInputSuccess?: (wasAnInputSuccess: boolean) => void,
  nameProp: string,
  isValidated?: boolean,
  inputFromProps?: string,
  hasDateInputValueChange?: (hasChange: boolean) => void,
  handleDateValue?: (dateValue: string) => void
}

interface MobileDetech {
  isMobile: boolean
}

const DateInputOnDesktop = styled.div<MobileDetech>`
  display: ${props => props.isMobile ? 'block' : 'none'};
  z-index: ${props => props.isMobile ? '-111111111111' : '1'};
`

const StyledCleave = styled(Cleave) <Input>`
  display: ${props => props.isMobile ? 'none' : 'block'};
  font-family: museo-sans, sans-serif;
  margin-top: 0.875rem;
  @media screen and (max-width: 690px) {
    width: 100%;
    box-sizing: border-box;
    padding-left: 0.9375rem;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15.563rem;
  background-color: ${props => props.inputSuccessAsBackgroundColor === true ? color.blue : color.grey};
  transition: background 0.5s ease-in-out;
  border: 1px solid ${color.white};
  outline: none;
  color: ${props => props.inputSuccessAsBackgroundColor === true ? color.blue_17_percent : color.grey_29_percent};
  font-size: 1rem;
  padding: 0.8125rem 1.625rem 0.75rem 1.5625rem;
  border-radius: 0.625rem;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${color.grey_darker};
    font-family: museo-sans, sans-serif;
    font-size: 1rem;
  }
  &:focus {
    ::-webkit-input-placeholder { color:transparent; }
    ::-moz-placeholder { color:transparent; }
    border: 1px solid ${color.blue_darker};
  }
  ::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  &:hover {
    transition: border 0.5s ease-in-out;
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
    cursor: pointer;
  }
`

const StyledMobileDOBContainer = styled.div<MobileDetech>`
  display: ${props => props.isMobile ? 'flex' : 'none'};
  flex-wrap: nowrap;

  & > * {
    flex-basis: 30%;
    &:not(:last-of-type) {
      margin-right: 5%;
    }
  }
`

type Ref = HTMLInputElement

const checkDateWithinMonth = (dayNumber: number, month: string):boolean => {
  switch(month) {
    case 'Feb':
      if (dayNumber > 29) {
        return false
      }
      break
    case 'Apr':
      if (dayNumber > 30) {
        return false
      }
      break
    case 'Jun':
      if (dayNumber > 30) {
        return false
      }
      break
    case 'Sep':
      if (dayNumber > 30) {
        return false
      }
      break
    case 'Nov':
      if (dayNumber > 30) {
        return false
      }
      break
  }
  return true
}

export const DateInput = forwardRef<Ref, Props>((props, aRef) => {

  const { nameProp, onInputSuccess, inputFromProps,
    isValidated, hasDateInputValueChange, handleDateValue } = props

  const [isSuccess, setIsSuccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [validatorMsg, setValidatorMsg] = useState("")
  const [labelIconState, setLabelIconStateState] = useState(true)
  const [hasInputChange, setInputChange] = useState(false)
  const [dateOutput, setDateOutput] = useState('');

  const [mobileDay, setMobileDay] = useState("")
  const [mobileMonth, setMobileMonth] = useState("")
  const [mobileYear, setMobileYear] = useState("")

  useEffect(() => {
    if (isMobile) {
      let valid = true
      const dayNumber = Number(mobileDay) > 0 ? Number(mobileDay) : 1
      if (!isNaN(dayNumber) && mobileMonth !== "") {
        // check if day exists in month
        if (!checkDateWithinMonth(dayNumber, mobileMonth)) {
          valid = false
        }
      }
      if (valid && !isNaN(dayNumber) && mobileMonth !== "" && mobileYear !== "") {
        // Parse values to single date string
        const monthNumber = calendar.months.indexOf(mobileMonth) + 1
        const yearNumber = Number(mobileYear.substr(-2)).toString().padStart(2, '0')
        const formatDate = `${dayNumber >= 10 ? dayNumber : '0' + dayNumber} / ${monthNumber >= 10 ? monthNumber : '0' + monthNumber} / ${yearNumber}`
        setIsSuccess(() => !!formatDate);
        handleDateValue ? handleDateValue(formatDate) : {}
      } else {
        setIsSuccess(false);
      }
    }
  }, [mobileDay, mobileMonth, mobileYear])

  useEffect(() => {
    inputFromProps && setDateOutput(inputFromProps)
    isValidated && setIsSuccess(isValidated)
    isValidated === true && setIsVisible(true)
  }, [inputFromProps, isValidated])

  useEffect(() => {
    onInputSuccess ? onInputSuccess(isSuccess) : {}
    if (isSuccess) {
      setValidatorMsg("")
    } else {
      setValidatorMsg(isMobile ? "Invalid date entered" : "Enter in the format DD/MM/YY")
    }
  }, [isSuccess])

  useEffect(() => {
    if (isVisible === true && isSuccess === false) setLabelIconStateState(false)
    else setLabelIconStateState(true)
  }, [isSuccess, isVisible])

  useEffect(() => {
    hasDateInputValueChange ? hasDateInputValueChange(hasInputChange) : {}
  }, [hasInputChange])

  const onSubmit = (event: any) => {
    let dateValue: string = event.target.value
    dateValue.length === 12 && handleDateValue ? handleDateValue(dateValue) : {}
    setIsSuccess(dateValue.length === 12)
    setIsVisible(true)
  }

  const last100Years = (): number[] => {
    const current = new Date().getFullYear()
    const start = current - 100
    return [...Array(100).keys()].map(i => i + start).reverse()
  }

  const selectedMonth = inputFromProps?.split(' / ').length === 3 ? calendar.months[parseInt(inputFromProps.split(' / ')[1]) - 1] : false

  return (
    <div style={{ flex: 1, marginRight: isTablet ? '2.825rem' : '0' }}>
      <Label>Date of birth <LabelIcon iconStates={labelIconState}>{'  '}*</LabelIcon></Label>
      <StyledCleave
        isMobile={isMobile}
        inputSuccessAsBackgroundColor={isSuccess}
        placeholder="DD/MM/YY"
        options={{
          date: true,
          delimiter: ' / ',
          datePattern: ['d', 'm', 'y']
        }}
        onBlur={event => {
          onSubmit(event)
        }}
        value={dateOutput}
      />
      <StyledMobileDOBContainer
        isMobile={isMobile}
      >
        <DateDropdown
          label=""
          name={'birthday-day'}
          contentDefault="DD"
          data={{
            "options": calendar.days
          }}
          noValidationIndicator={true}
          ref={undefined}
          inputFromProps={inputFromProps ? inputFromProps?.split(' / ')[0] : undefined}
          isValidated={isValidated}
          onSelectedValueProp={data => {
            setMobileDay(data)
          }}
        />
        <DateDropdown
          label=""
          name={'birthday-month'}
          contentDefault="MM"
          data={{
            "options": calendar.months
          }}
          noValidationIndicator={true}
          ref={undefined}
          inputFromProps={typeof selectedMonth === 'string' ? selectedMonth : undefined}
          isValidated={isValidated}
          onSelectedValueProp={data => {
            setMobileMonth(data)
          }}
        />
        <DateDropdown
          label=""
          name={'birthday-year'}
          contentDefault="YYYY"
          data={{
            "options": last100Years()
          }}
          noValidationIndicator={true}
          ref={undefined}
          inputFromProps={inputFromProps ? inputFromProps?.split(' / ')[2] : undefined}
          isValidated={isValidated}
          onSelectedValueProp={data => {
            setMobileYear(data)
          }}
        />
      </StyledMobileDOBContainer>
      <ValidationIndicator
        visibility={isVisible}
        isCorrect={isSuccess}
        message={validatorMsg}
      />
      <Tooltip visibility={isVisible} tooltipName="date"
        tooltipTitle="Why do we need this?"
        tooltip_message="We need to know how old you are to ensure you are being paid correctly. This is particularly important for younger workers." />
    </div>
  )
})

export default DateInput;
