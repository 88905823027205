import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components'

import TextInput from '../TextInput/index'
import ValidationIndicator from '../ValidationIndicator'
import Label from '../Label'
import LabelIcon from '../Label/LabelIcon'
import { handleStateByPostcode } from '../SuburbAutosuggestField/SuggestSource'

export type Props = {
  isShow?: boolean
  onValidateWorkPostcode?: (success: boolean) => void
  inputFromProps?: string
  isValidatedFromProp?: boolean
  onHandlePostcodeValue?: (inputValue: string) => void
  onHandleStateByPostcode?: (inputValue: string) => void
  hasPostcodeValueChange?: (isChange: boolean) => void
  isFocused?: boolean
}

interface PostCode {
  isShow?: boolean
}
const WorkPostcodeContainer = styled.div<PostCode>`
  animation-name: flickity;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  @keyframes flickity {
      0% {
          opacity:0;
      }
      100% {
          opacity:1;
      }
  };
  margin-top: 2.5rem;
  display: ${props => props.isShow === true ? 'block' : 'none'};
`

export const WorkPostcode: React.FC<Props> = (props) => {
  const { isShow, onValidateWorkPostcode, inputFromProps,
    isValidatedFromProp, onHandlePostcodeValue, isFocused,
    onHandleStateByPostcode, hasPostcodeValueChange } = props
  const [inputSuccess, setIsSuccess] = useState(false);
  const [visibility, setIsVisible] = useState(false);
  const [validatorMsg, setValidatorMsg] = useState('')
  const [value, setValue] = useState<any>(undefined)
  const [labelIconState, setLabelIconState] = useState(true)

  useEffect(() => {
    if (inputSuccess === true) {
      setValidatorMsg(`Thanks! We've updated your workplace suburb`)
    }
    else {
      setValidatorMsg("Please enter postcode")
    }
  }, [inputSuccess, validatorMsg])

  useEffect(() => {
    inputFromProps?.length === 4 ? setValue(inputFromProps) : {}
    isValidatedFromProp ? setIsSuccess(isValidatedFromProp) : {}
    isValidatedFromProp ? setIsVisible(isValidatedFromProp) : {}

    if (inputFromProps === undefined) {
      // setValue('')
      hasPostcodeValueChange ? hasPostcodeValueChange(inputFromProps !== value) : {}
      setIsSuccess(false)
      setIsVisible(false)
    }
  }, [inputFromProps, isValidatedFromProp, isShow])

  useEffect(() => {
    if (visibility === true && inputSuccess === false) {
      onHandlePostcodeValue ? onHandlePostcodeValue(value) : {}
      setLabelIconState(false)
    } else {
      setLabelIconState(true)
      onHandlePostcodeValue ? onHandlePostcodeValue(value) : {}
    }
  }, [inputSuccess, visibility, value])

  useEffect(() => {
    if (inputSuccess === true) {
      let state = handleStateByPostcode(value)
      onHandleStateByPostcode && state !== 'error' ? onHandleStateByPostcode(state) : {}
    }
  }, [inputSuccess, value])

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isFocused === true) {
      //@ts-ignore
      inputRef && inputRef?.current && inputRef?.current?.focus()
    }
  }, [isFocused])

  const handleOnInputSucess = (isSuccess: boolean) => {
    setIsSuccess(isSuccess)
    onValidateWorkPostcode ? onValidateWorkPostcode(isSuccess) : {}
  }

  const handleisVisible = (isVisible: boolean) => {
    setIsVisible(isVisible)
  }

  return (
    <WorkPostcodeContainer isShow={isShow}>
      <Label>Workplace postcode <LabelIcon iconStates={labelIconState}>{'  '}*</LabelIcon></Label>
      <TextInput
        placeholder=''
        widthSize="37.625rem"
        name="postcode"
        type='tel'
        maxLength={4}
        onInputSucessCallback={(success: boolean) => { handleOnInputSucess(success) }}
        ref={inputRef}
        inputFromProps={value}
        isValidated={inputSuccess}
        onTypeText={inputValue => {
          setValue(inputValue)
        }}
        hasValueChange={isChange => {
          hasPostcodeValueChange ? hasPostcodeValueChange(isChange) : {}
        }}
      />
      <ValidationIndicator isCorrect={inputSuccess} {...{ message: validatorMsg, visibility }} />
    </WorkPostcodeContainer>
  )
}

export default WorkPostcode
