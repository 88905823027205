import React from "react";
import styled from 'styled-components';
import color from '../color-css/color'
import { ScrollBar } from '../ScrollBarComponent'

export type Props = {}
const Div = styled.div`
  position: absolute;
  width: 31.3125rem;
  height: 9.25rem;
  padding: 0 7.25rem 0 1.75rem;
  list-style-type: none;
  font-weight: 300;
  font-size: 1rem;
  line-height: .625rem;
  overflow: auto;
  ${ScrollBar}
  margin-top: 0.875rem;
  @media screen and (max-width: 768px) {
    width: calc(100% - 10rem); 
    padding-left: 1.8125rem;
    height: 9rem;
    margin-top: 1.0625rem;
  }
  @media screen and (max-width: 690px) {
    width: calc(100% - 1.1875rem);
    height: 9.3125rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0 1.25rem 0 1.5625rem;
  }
`

const Item = styled.div`
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5625rem;
  margin-top: 1.125rem;
  color: ${color.grey_darker};
  cursor: pointer;
  animation-name: flickity;
  animation-duration: 2s;  
  animation-fill-mode: forwards;
  @keyframes flickity {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  };
  @media screen and (max-width: 768px) {
    font-size: 0.9375rem;
    margin-top: 0.3125rem;
  }
`

const List: React.FC<Props> = (props) => {
  const { children } = props
  return (
    <Div>
      <Item>
        {children}
      </Item>
    </Div>
  )
}

export default List