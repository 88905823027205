import store from '../store'
import { gql } from '@apollo/client'
import { Promise } from 'es6-promise'
import {
  FIRST_NAME, LAST_NAME, PHONE_NUMBER, EMAIL, SUBURB_WORK, EMPLOYER, WORK_PLACE_POSTCODE,
  CANT_FIND_SUBURB_SELECTED, STATE_FROM_SUBURB, EMPLOYER_SELECTED_OR_TYPED
} from '../components/FormHighOrder/types/YourWorkField'
import { v4 as uuidv4 } from 'uuid'

import {
  ADDRESS, UNIT_NUMBER, CITY, STATE_OR_TERITORY, POSTCODE, GENDER, EMPLOYMENT_STATUS,
  DATE_OF_BIRTH, EMPLOYMENT_STATUS_CASUAL, YOUR_NEXT_OF_KIN_DATA, KIN_FIRST_NAME,
  KIN_LAST_NAME, KIN_PHONE_NUMBER, KIN_RELATION, KIN_ADDRESS, KIN_UNIT_NUMBER,
  KIN_CITY, KIN_STATE_OR_TERITORY, KIN_POSTCODE,
  GUARDIAN_FIRST_NAME, GUARDIAN_LAST_NAME, GUARDIAN_PHONE_NUMBER, GUARDIAN_EMAIL
} from '../components/FormHighOrder/types/AboutYouField'

import {
  BILLING_ADDRESS_OPTION,
  BILLING_FIRST_NAME,
  BILLING_LAST_NAME,
  BILLING_ADDRESS,
  BILLING_UNIT_NUMBER,
  BILLING_CITY,
  BILLING_STATE_OR_TERITORY,
  BILLING_POSTCODE,
  PAYMENT_METHOD_SELECTION,
  CREDITCARD_PAYMENT_TAB_DATA,
  BANKACCOUNT_PAYMENT_TAB_DATA,
  PAY_DEDUCTION_PAYMENT_TAB_DATA,
  BANKACCOUNT_PAYMENT_ACCOUNT_NAME,
  BANKACCOUNT_PAYMENT_BSB,
  BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER,
  BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION,
  BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION,
  CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME,
  CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME,
  CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER,
  CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE,
  CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV,
  CREDITCARD_PAYMENT_DATE_OF_DEDUCTION,
  CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION,
  CREDITCARD_FATZEBRA_TOKEN,
  CREDITCARD_BPOINT_AUTHKEY,
  CREDITCARD_PAYWAY_TOKEN,
  PAY_DEDUCTION_PAYMENT_ELOTRONIC_SIGNATURE,
  PAY_DEDUCTION_PAYMENT_NUMBER,
  DIRECT_DEBIT_AGREEMENT,
  BANKACCOUNT_AGREE_TERM_AND_CONDITION,
  CREDITCARD_PAYMENT_AGREE_TERM_AND_CONDITION,
  DIFFERENT_BILLING_ADDRESS_DATA
} from '../components/FormHighOrder/types/YourContributionField'

import { getDebitAmount, getStartDate } from '../components/summarySectionProcessing'
import { setSuccessfullyPosted, StepStateKeys, writeYourWorkData } from '../components/FormHighOrder/features/index'
import { getPostcodeFromSuburb } from '../components/SuburbAutosuggestField/SuggestSource'

import 'isomorphic-unfetch'

import { apolloClient as client } from './apolloClient'
import { setIsMultiBranch } from 'features/duplicates'
import { Action } from '@reduxjs/toolkit'

export const uuid = uuidv4()
let hubspotId: string

export enum JoinFormSubmissionInlineValidationTarget {
  INVALID_EMAIL = 'INVALID_EMAIL',
}

export type PromiseResult = {
  isComplete: boolean
  canProceed: boolean
  inlineErrors?: {type: JoinFormSubmissionInlineValidationTarget, message?: string}[];
}

const formatPostcode = (postcode: string | undefined | null): string | undefined | null => {
  if (postcode) {
    // convert to string first if int
    postcode = `${postcode}`
    if (postcode.length === 3) {
      postcode = `0${postcode}`
    }
    return postcode
  }

  // return undefined or null
  return postcode
}

// takes the day of the week and frequency and returns it as a numeric value
// e.g.  Weekly and Fortnightly payments this will be the day of the week (1 - Monday, 2 - Tuesday, 3 - Wednesday, 4 - Thursday, 5 - Friday) For Monthly this will be the day of the month.
export const dayOfWeekToInt = (dayOfWeek: string | undefined, frequency: string): number => {
  // return first of next month
  if (typeof dayOfWeek === 'undefined') {
    return 1
  }

  if (frequency === 'Monthly') {
    const day = new Date()
    const formatDate = day.toLocaleTimeString('en-us', { weekday: 'long' })
    const currentDay = formatDate.substring(0 , formatDate.indexOf(' '))
    if (currentDay === dayOfWeek) {
      // get the next day of the month next week
      day.setDate(day.getDate() + 7)
      return day.getDate()
    }
    let dateInNumber: number = 1
    const ret = new Date()
    switch(dayOfWeek) {
      case 'Monday':
        dateInNumber = 1
        break
      case 'Tuesday':
        dateInNumber = 2
        break
      case 'Wednesday':
        dateInNumber = 3
        break
      case 'Thursday':
        dateInNumber = 4
        break
      case 'Friday':
        dateInNumber = 5
        break
    }
    return ret.getDate() + (dateInNumber - 1 - ret.getDay() + 7) % 7 + 1
  } else {
    switch(dayOfWeek) {
      case 'Monday':
        return 1
      case 'Tuesday':
        return 2
      case 'Wednesday':
        return 3
      case 'Thursday':
        return 4
      case 'Friday':
        return 5
      default:
        return 1
    }
  }
}

export const checkDuplicates = async (): Promise<Record<string, number> | undefined> => {
  if (typeof process.env.GATSBY_CONNECT_TO_DW !== 'undefined' && process.env.GATSBY_CONNECT_TO_DW === "true") {
    return getDuplicateInfo(false, uuid, 0)
  }
}

export const handlePageSubmission = async (stepNumber: number): Promise<PromiseResult> => {
  const isComplete = stepNumber === 3

  if (typeof process.env.GATSBY_CONNECT_TO_DW !== 'undefined' && process.env.GATSBY_CONNECT_TO_DW === "true") {
    return submitDataToDataWarehouse(isComplete, uuid, stepNumber)
  }
  else {
    // do not connect to DW for development mode
    return new Promise<PromiseResult>((res) => {
      setTimeout(() => {
        store.dispatch(setSuccessfullyPosted(stepNumber as StepStateKeys))
        res({
          isComplete: isComplete,
          canProceed: true
        })
      }, Math.floor(Math.random() * (3000 - 1500) + 1500))
    })
  }
}

const prepareJoinData = (stepNumber, token, isComplete) => {
  const { pageData } = store.getState().FormHighOrder

  const graphqlQLArguments: any = {
    JoinFormInput: {
      token: token,
      complete: isComplete
    }
  }

  if (hubspotId) {
    graphqlQLArguments.JoinFormInput.hubspotId = hubspotId
  }

  const addStepOneData = (): void => {

    graphqlQLArguments.JoinFormInput.submissionStep = stepNumber + 1

    const hubspotcookie = document?.cookie?.split(';')?.filter((item) => item.trim().startsWith('hubspotutk='))?.[0]?.split('hubspotutk=')
    const cookieValue = hubspotcookie?.length === 2 ? hubspotcookie[1] : undefined

    if (cookieValue) {
      graphqlQLArguments.JoinFormInput.identityToken = cookieValue
    }

    if (typeof pageData[0] !== 'undefined' && pageData[0]?.isValidated) {
      const stepOneData = pageData[0]?.data

      if (stepOneData) {

        let postcode = stepOneData[WORK_PLACE_POSTCODE]?.value
        if (!Boolean(postcode) && stepOneData[SUBURB_WORK]?.value && stepOneData[STATE_FROM_SUBURB]?.value) {
          postcode = getPostcodeFromSuburb(stepOneData[SUBURB_WORK]?.value, stepOneData[STATE_FROM_SUBURB]?.value)
        }
        postcode = formatPostcode(postcode)

        const state = stepOneData[STATE_FROM_SUBURB]?.value
        const suburb = stepOneData[SUBURB_WORK]?.value != "Can't find suburb" ? stepOneData[SUBURB_WORK]?.value : postcode

        graphqlQLArguments.JoinFormInput.branch = ['SA', 'NT', 'Broken Hill'].includes(stepOneData[STATE_FROM_SUBURB]?.value) ? 'SA' : stepOneData[STATE_FROM_SUBURB]?.value,
          graphqlQLArguments.JoinFormInput.personal = {
            firstName: stepOneData[FIRST_NAME]?.value,
            lastName: stepOneData[LAST_NAME]?.value,
            email: stepOneData[EMAIL]?.value,
            phoneNumber: stepOneData[PHONE_NUMBER]?.value.replace(/\s/g, '').replace(/^04/, '+614'),
          }
        graphqlQLArguments.JoinFormInput.employment = {
          employer: stepOneData[EMPLOYER]?.value,
          address: {
            suburb: suburb,
            postcode: postcode,
            state: state,
          }
        }
      }
    }
  }

  const addStepTwoData = (): void => {
    addStepOneData()
    if (typeof pageData[1] !== 'undefined' && pageData[1]?.isValidated) {
      const stepOneData = pageData[0]?.data
      const stepTwoData = pageData[1]?.data

      if (stepTwoData) {

        const kinDataExists = stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_FIRST_NAME]?.value !== '' && stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_LAST_NAME]?.value !== ''
          && stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_PHONE_NUMBER]?.value !== '' && stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_RELATION]?.value !== ''

        if (stepOneData[STATE_FROM_SUBURB]?.value === 'Newcastle' && kinDataExists) {

          graphqlQLArguments.JoinFormInput.kinData = {
            firstName: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_FIRST_NAME]?.value,
            lastName: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_LAST_NAME]?.value,
            phoneNumber: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_PHONE_NUMBER]?.value,
            relation: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_RELATION]?.value,
            address: {
              street: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_ADDRESS]?.value,
              unitNumber: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_UNIT_NUMBER]?.value,
              suburb: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_CITY]?.value,
              postcode: formatPostcode(stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_POSTCODE]?.value),
              state: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[KIN_STATE_OR_TERITORY]?.value,
            }
          }

          if (stepTwoData?.[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_FIRST_NAME]?.isValidated && stepTwoData?.[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_FIRST_NAME]?.value !== '' &&
            stepTwoData?.[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_LAST_NAME]?.isValidated && stepTwoData?.[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_LAST_NAME]?.value !== '' &&
            stepTwoData?.[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_EMAIL]?.isValidated && stepTwoData?.[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_EMAIL]?.value !== '' &&
            stepTwoData?.[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_PHONE_NUMBER]?.isValidated && stepTwoData?.[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_PHONE_NUMBER]?.value !== '') {
            graphqlQLArguments.JoinFormInput.guardianData = {
              firstName: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_FIRST_NAME]?.value,
              lastName: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_LAST_NAME]?.value,
              email: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_EMAIL]?.value,
              phoneNumber: stepTwoData[YOUR_NEXT_OF_KIN_DATA]?.[GUARDIAN_PHONE_NUMBER]?.value
            }
          }
        }

        graphqlQLArguments.JoinFormInput.personal.address = {
          unitNumber: stepTwoData[UNIT_NUMBER].value,
          street: stepTwoData[ADDRESS]?.value,
          suburb: stepTwoData[CITY]?.value,
          postcode: formatPostcode(stepTwoData[POSTCODE]?.value),
          state: stepTwoData[STATE_OR_TERITORY]?.value,
        }

        let dateOfBirth = stepTwoData[DATE_OF_BIRTH]?.value?.replace(/\s/g, '')
        // If year of birth is 4 characters (like it is on mobile) reduce that year by 2 decimal places to strip out the millenium and century units
        const yearToTwoCharsRegex = /(\d{4})/g
        dateOfBirth = dateOfBirth.replace(yearToTwoCharsRegex, (match: any, p1: string) => {
          return p1.substring(2, 3)
        })

        graphqlQLArguments.JoinFormInput.personal.dateOfBirth = dateOfBirth
        graphqlQLArguments.JoinFormInput.personal.gender = stepTwoData[GENDER]?.value

        graphqlQLArguments.JoinFormInput.employment.permanence = stepTwoData[EMPLOYMENT_STATUS]?.value
        if (typeof stepTwoData[EMPLOYMENT_STATUS_CASUAL]?.value !== 'undefined' && stepTwoData[EMPLOYMENT_STATUS_CASUAL]?.value !== '') {
          graphqlQLArguments.JoinFormInput.employment.averageHours = stepTwoData[EMPLOYMENT_STATUS_CASUAL]?.value
        } else {
          graphqlQLArguments.JoinFormInput.employment.averageHours = "20+ hours"
        }
      }
    }
  }

  const addStepThreeData = (): void => {
    addStepOneData()
    addStepTwoData()
  }

  const addStepFourData = (): void => {
    addStepOneData()
    addStepTwoData()
    if (typeof pageData[3] !== 'undefined' && pageData[3]?.isValidated) {
      const stepFourData = pageData[3]?.data

      if (stepFourData) {
        // Calculate the debit amount for user
        let debitAmount: string | number = ''
        let paymentFrequency = ''
        let startDate: string | null | undefined = ''

        const stepOneData = pageData[0]?.data
        const stepTwoData = pageData[1]?.data


        if (stepOneData) {
          if (stepOneData[STATE_FROM_SUBURB]?.value === 'SA' || stepOneData[STATE_FROM_SUBURB]?.value === 'NT'
            || stepOneData[STATE_FROM_SUBURB]?.value === 'Broken Hill') {
            paymentFrequency = 'Fortnightly'
          }
          else {
            if (stepFourData[PAYMENT_METHOD_SELECTION]?.value === 'Credit card') {
              paymentFrequency = stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value
            } else if (stepFourData[PAYMENT_METHOD_SELECTION]?.value === 'Bank account') {
              paymentFrequency = stepFourData[BANKACCOUNT_PAYMENT_TAB_DATA]?.value[BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION]?.value
            } else {
              paymentFrequency = 'Weekly'
            }
          }
        }

        if (stepFourData[BILLING_ADDRESS_OPTION]?.value === 'differentBillingAddress' && stepFourData[DIFFERENT_BILLING_ADDRESS_DATA]?.isValidated) {

          graphqlQLArguments.JoinFormInput.billingAddress = {
            firstName: stepFourData[DIFFERENT_BILLING_ADDRESS_DATA]?.value?.[BILLING_FIRST_NAME]?.value,
            lastName: stepFourData[DIFFERENT_BILLING_ADDRESS_DATA]?.value?.[BILLING_LAST_NAME]?.value,
            address: {
              street: stepFourData[DIFFERENT_BILLING_ADDRESS_DATA]?.value?.[BILLING_ADDRESS]?.value,
              unitNumber: stepFourData[DIFFERENT_BILLING_ADDRESS_DATA]?.value?.[BILLING_UNIT_NUMBER]?.value,
              suburb: stepFourData[DIFFERENT_BILLING_ADDRESS_DATA]?.value?.[BILLING_CITY]?.value,
              postcode: formatPostcode(stepFourData[DIFFERENT_BILLING_ADDRESS_DATA]?.value?.[BILLING_POSTCODE]?.value),
              state: stepFourData[DIFFERENT_BILLING_ADDRESS_DATA]?.value?.[BILLING_STATE_OR_TERITORY]?.value,
            }
          }
        } else {

          graphqlQLArguments.JoinFormInput.billingAddress = {
            firstName: stepOneData[FIRST_NAME]?.value,
            lastName: stepOneData[LAST_NAME]?.value,
            address: {
              unitNumber: stepTwoData[UNIT_NUMBER].value,
              street: stepTwoData[ADDRESS]?.value,
              suburb: stepTwoData[CITY]?.value,
              postcode: formatPostcode(stepTwoData[POSTCODE]?.value),
              state: stepTwoData[STATE_OR_TERITORY]?.value,
            }
          }
        }

        const stateFromSuburb = stepOneData[STATE_FROM_SUBURB]?.value;

        if (stepOneData && ['QLD', 'SA', 'TAS', 'NT', 'Broken Hill', 'WA'].includes(stateFromSuburb)) {
          if (stepTwoData && paymentFrequency.length) {
            if (stepTwoData[EMPLOYMENT_STATUS].value === 'Full Time'
              || stepTwoData[EMPLOYMENT_STATUS].value === 'Salaried'
              || stepTwoData[EMPLOYMENT_STATUS_CASUAL].value === '20+ hours') {
              debitAmount = getDebitAmount(paymentFrequency, 22.60, stateFromSuburb)
            } else if (stepTwoData[EMPLOYMENT_STATUS_CASUAL].value === '10-19 hours') {
              debitAmount = getDebitAmount(paymentFrequency, 16.10, stateFromSuburb)
            } else if (stepTwoData[EMPLOYMENT_STATUS_CASUAL].value === '0-9 hours') {
              debitAmount = getDebitAmount(paymentFrequency, 9.00, stateFromSuburb)
            }
          }
        } else {
          if (stepTwoData && paymentFrequency.length) {
            if (stepTwoData[EMPLOYMENT_STATUS].value === 'Full Time'
              || stepTwoData[EMPLOYMENT_STATUS].value === 'Salaried'
              || stepTwoData[EMPLOYMENT_STATUS_CASUAL].value === '20+ hours') {
              debitAmount = getDebitAmount(paymentFrequency, 20.40, stateFromSuburb)
            } else if (stepTwoData[EMPLOYMENT_STATUS_CASUAL].value === '10-19 hours') {
              debitAmount = getDebitAmount(paymentFrequency, 14.60, stateFromSuburb)
            } else if (stepTwoData[EMPLOYMENT_STATUS_CASUAL].value === '0-9 hours') {
              debitAmount = getDebitAmount(paymentFrequency, 8.20, stateFromSuburb)
            }
          }
        }
        debitAmount = parseFloat(debitAmount.replace('$', ''))

        switch (stepFourData[PAYMENT_METHOD_SELECTION]?.value) {
          case 'Credit card':
            if (stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.isValidated) {
              let cardExpiryMonth = ''
              let cardExpiryYear = ''
              startDate = getStartDate(stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.value)?.replace(/\s/g, '')?.replace(/\//g, '-')

              if (typeof startDate === 'undefined') {
                startDate = getStartDate('firstOfNextMonth')
              }

              if (stepOneData[STATE_FROM_SUBURB]?.value === 'Newcastle') {
                graphqlQLArguments.JoinFormInput.payment = {
                  creditCard: {
                    paywayToken: stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYWAY_TOKEN]?.value,
                    paymentFrequency: paymentFrequency,
                    startDate: startDate,
                    anniversary: `${dayOfWeekToInt(
                      stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.value,
                      paymentFrequency
                    )}`,
                    amount: debitAmount
                  }
                }
              } else if (['SA', 'NT', 'Broken Hill', 'WA', 'QLD', 'TAS'].includes(stepOneData[STATE_FROM_SUBURB]?.value)) {
                graphqlQLArguments.JoinFormInput.payment = {
                  creditCard: {
                    fatZebraToken: stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_FATZEBRA_TOKEN]?.value,
                    paymentFrequency: paymentFrequency,
                    startDate: startDate,
                    anniversary: `${dayOfWeekToInt(
                      stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.value,
                      paymentFrequency
                    )}`,
                    amount: debitAmount
                  }
                }
              } else {
                const expiryDate = stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE]?.value
                if (expiryDate && typeof expiryDate === 'string') {
                  cardExpiryMonth = expiryDate.substring(0, 2)
                  cardExpiryYear = expiryDate.substring(expiryDate.length - 2, expiryDate.length)
                }

                graphqlQLArguments.JoinFormInput.payment = {
                  creditCard: {
                    number: stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER]?.value,
                    expiryMonth: cardExpiryMonth,
                    expiryYear: cardExpiryYear,
                    ccv: stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV]?.value,
                    cardHolderName: `${stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME]?.value} ${stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME]?.value}`,
                    paymentFrequency: paymentFrequency,
                    startDate: startDate,
                    dayOfWeek: stepFourData[CREDITCARD_PAYMENT_TAB_DATA]?.value[CREDITCARD_PAYMENT_DATE_OF_DEDUCTION]?.value,
                    amount: debitAmount
                  }
                }
              }
            }
            break
          case 'Bank account':
            if (stepFourData[BANKACCOUNT_PAYMENT_TAB_DATA]?.isValidated) {

              startDate = getStartDate(stepFourData[BANKACCOUNT_PAYMENT_TAB_DATA]?.value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]?.value)?.replace(/\s/g, '')?.replace(/\//g, '-')

              if (typeof startDate === 'undefined') {
                startDate = getStartDate('firstOfNextMonth')
              }

              graphqlQLArguments.JoinFormInput.payment = {
                bankAccount: {
                  name: stepFourData[BANKACCOUNT_PAYMENT_TAB_DATA]?.value[BANKACCOUNT_PAYMENT_ACCOUNT_NAME]?.value,
                  bsb: stepFourData[BANKACCOUNT_PAYMENT_TAB_DATA]?.value[BANKACCOUNT_PAYMENT_BSB]?.value,
                  acc: stepFourData[BANKACCOUNT_PAYMENT_TAB_DATA]?.value[BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER]?.value,
                  paymentFrequency: paymentFrequency,
                  startDate: startDate,
                  anniversary: `${dayOfWeekToInt(
                    stepFourData[BANKACCOUNT_PAYMENT_TAB_DATA]?.value[BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION]?.value,
                    paymentFrequency
                  )}`,
                  amount: debitAmount
                }
              }
            }
            break
          case 'Payroll deduction':
            if (stepFourData[PAY_DEDUCTION_PAYMENT_TAB_DATA]?.isValidated) {
              graphqlQLArguments.JoinFormInput.payment = {
                payrollDeduction: {
                  signature: stepFourData[PAY_DEDUCTION_PAYMENT_TAB_DATA]?.value[PAY_DEDUCTION_PAYMENT_ELOTRONIC_SIGNATURE]?.isValidated, //isValidated holds the signature image src for some reason
                  payrollId: stepFourData[PAY_DEDUCTION_PAYMENT_TAB_DATA]?.value[PAY_DEDUCTION_PAYMENT_NUMBER]?.value,
                  amount: debitAmount
                }
              }
            }
            break
          case 'Receive a call':
            graphqlQLArguments.JoinFormInput.payment = {
              contact: true
            }
          break
        }
      }
    }
  }

  switch (stepNumber) {
    case 0:
      addStepOneData()
      break
    case 1:
      addStepTwoData()
      break
    case 2:
      addStepThreeData()
      break
    default:
      addStepFourData()
  }

  return graphqlQLArguments
}

const getDuplicateInfo = async (isComplete: boolean, token: string, stepNumber: number): Promise<Record<string, number>> => {

  return new Promise<Record<string, number>>(async (resolve, reject) => {

    if (typeof process.env.GATSBY_DATAWAREHOUSE_HOST === 'undefined') {
      reject('required env vars not set for submit function')
    }

    const graphqlQLArguments = prepareJoinData(stepNumber, token, isComplete)

    const DUPES_GQL = gql`
      query getDuplicates ($JoinFormInput: JoinFormInput!) {
        getDuplicatesMap(input: $JoinFormInput) {
          data {
            branch
            count
          }
        }
      }
    `

    if (typeof client === 'undefined') {
      reject([{
        message: 'apollo client failed to initialise'
      }])
    }

    try {
      const result = await client?.query({
        query: DUPES_GQL,
        variables: graphqlQLArguments
      })

      const { data } = result?.data?.getDuplicatesMap

      const formattedSet = {}
      let existingUser = false
      let multiBranchUser = false

      for(const { branch, count } of data) {
        formattedSet[branch] = count

        if (existingUser && count > 0) multiBranchUser = true
        else if (count > 0) existingUser = true
      }

      store.dispatch(setIsMultiBranch(multiBranchUser as any))

      resolve(formattedSet)
    } catch (error) {
      reject(error)
    }
  })
}

const submitDataToDataWarehouse = async (isComplete: boolean, token: string, stepNumber: number): Promise<PromiseResult> => {

  return new Promise<PromiseResult>(async (resolve, reject) => {

    if (typeof process.env.GATSBY_DATAWAREHOUSE_HOST === 'undefined') {
      reject('required env vars not set for submit function')
    }

    const graphqlQLArguments = prepareJoinData(stepNumber, token, isComplete)

    const payrollJoinData = graphqlQLArguments?.JoinFormInput?.payment?.payrollDeduction

    if(payrollJoinData && !payrollJoinData?.signature) {
      reject([{
        message: "We're unable to process your payment at this time. Payroll deduction signature failed to validate. Please try again."
      }])
    }

    const JOIN_GQL = gql`
      mutation submitJoin ($JoinFormInput: JoinFormInput!) {
        join(input: $JoinFormInput) {
          ... on JoinFormSubmission {
            token
            valid
            errors
            inlineErrors {
              type
              message
            }
          }
          ... on Member {
            hubspotId
          }
        }
      }
    `

    if (typeof client === 'undefined') {
      reject([{
        message: 'apollo client failed to initialise'
      }])
    }

    await client?.mutate({
      mutation: JOIN_GQL,
      variables: graphqlQLArguments
    }).then((result) => {

      if (result?.data?.join?.hubspotId) {
        hubspotId = result.data.join.hubspotId
      }

      // DW doesn't send a valid attribute response on final submit
      if (stepNumber === 3 && !result?.data?.join?.errors?.length) {
        resolve({
          isComplete: isComplete,
          canProceed: true
        })
        store.dispatch(setSuccessfullyPosted(stepNumber as StepStateKeys))
        return
      }

      if (!result?.data?.join?.valid || result?.data?.join?.errors?.length) {
        // DW did not send back a valid response or an error returned
        if (result?.data?.join?.errors) {
          reject(result.data.join.errors)
        } else {
          reject([{
            message: 'Submission failed, but did not return an error message.'
          }])
        }
      }

      if(result.data.join.inlineErrors.length) {
        resolve({
          isComplete: false,
          canProceed: false,
          inlineErrors: result.data.join.inlineErrors
        })
      }

      resolve({
        isComplete: isComplete,
        canProceed: true
      })
      store.dispatch(setSuccessfullyPosted(stepNumber as StepStateKeys))
    }).catch((e) => {
      reject([e])
    })
  })
}

// This function submits all form data to a GCP cloud function which in turn emails the data collected to Atomix for testing
// Not to be used in any production environment
const submitDebugData = (): Promise<void> => {

  return new Promise<void>((resolve, reject) => {

    const { pageData } = store.getState().FormHighOrder

    const formData: { [key: string]: string } = {}

    pageData.forEach((page) => {
      for (const key in page.data) {
        const value = page.data[key]

        // handling differentBillingAddressData different object shape
        if (value.billing_city) {
          ['address', 'billing_city', 'billing_firstName', 'billing_lastName', 'billing_postcode', 'billing_stateOrTerritory', 'billing_unitNumber'].forEach((key) => {
            if (value[key] && value[key].isValidated) {
              formData[key] = value[key].value
            }
          })
        }

        // handling bankAccountTabData different object shape
        if (value.paymentBankAccount_AccountName) {
          ['paymentBankAccount_AccountName', 'paymentBankAccount_AccountNumber', 'paymentBankAccount_BSB', 'paymentBankAccount_DateOfDeduction', 'paymentBankAccount_FrequencyOfDeduction', 'paymentBankAccount_TermAndCondition'].forEach((key) => {
            if (value[key] && value[key].isValidated) {
              formData[key] = value[key].value
            }
          })
        }

        if (value.isValidated) {
          if (value.value) {
            if (typeof value.value === 'object') {
              Object.keys(value.value).forEach((key) => {
                const innervalue = value.value[key]
                if (innervalue.isValidated) {
                  if (innervalue.value) {
                    if (innervalue.value === 'signed') {
                      formData[key] = innervalue.isValidated
                    } else {
                      formData[key] = innervalue.value
                    }
                  }
                }
              })
            } else {
              formData[key] = value.value
            }
          }
        }
      }
    })

    const xhr = new XMLHttpRequest()

    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        resolve()
      }
    })
    xhr.onerror = reject
    xhr.open('POST', 'https://us-central1-sda-dev-277804.cloudfunctions.net/submitForm')
    xhr.setRequestHeader('Content-Type', 'application/json')

    xhr.send(JSON.stringify(formData))
  })
}
